/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TourAIGenerateMarkersSubscriptionContextAIProgressSubscriptionVariables = {
    tourId: string;
};
export type TourAIGenerateMarkersSubscriptionContextAIProgressSubscriptionResponse = {
    readonly tourGenerateAIMarkersUpdated: {
        readonly tourId: number;
        readonly marker: {
            readonly id: string;
            readonly isFastTravel: boolean;
            readonly frameIndex: number;
            readonly name: string;
        } | null;
        readonly progressPercentage: number;
        readonly action: string;
    };
};
export type TourAIGenerateMarkersSubscriptionContextAIProgressSubscription = {
    readonly response: TourAIGenerateMarkersSubscriptionContextAIProgressSubscriptionResponse;
    readonly variables: TourAIGenerateMarkersSubscriptionContextAIProgressSubscriptionVariables;
};



/*
subscription TourAIGenerateMarkersSubscriptionContextAIProgressSubscription(
  $tourId: ID!
) {
  tourGenerateAIMarkersUpdated(tourId: $tourId) {
    tourId
    marker {
      id
      isFastTravel
      frameIndex
      name
    }
    progressPercentage
    action
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tourId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tourId",
        "variableName": "tourId"
      }
    ],
    "concreteType": "TourAIProgress",
    "kind": "LinkedField",
    "name": "tourGenerateAIMarkersUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tourId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Marker",
        "kind": "LinkedField",
        "name": "marker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFastTravel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frameIndex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progressPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "action",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourAIGenerateMarkersSubscriptionContextAIProgressSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourAIGenerateMarkersSubscriptionContextAIProgressSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8d383b22b9fbfef2fa7035977e1dd584",
    "id": null,
    "metadata": {},
    "name": "TourAIGenerateMarkersSubscriptionContextAIProgressSubscription",
    "operationKind": "subscription",
    "text": "subscription TourAIGenerateMarkersSubscriptionContextAIProgressSubscription(\n  $tourId: ID!\n) {\n  tourGenerateAIMarkersUpdated(tourId: $tourId) {\n    tourId\n    marker {\n      id\n      isFastTravel\n      frameIndex\n      name\n    }\n    progressPercentage\n    action\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e2c4b3aaa68e12dbd618fe5399fdc83a';
export default node;
