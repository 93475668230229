import {TermsComponent} from './TermsComponent';
import {termsAndConditionsOfUse} from '../constants/termsAndConditionsOfUse';
import {contentOfTheWebsite} from '../constants/contentOfTheWebsite';
import {thirdPartyContent} from '../constants/thirdPartyContent';
import {usageRestrictions} from '../constants/usageRestrictions';
import {security} from '../constants/security';
import {intellectualPropertyRights} from '../constants/intellectualPropertyRights';
import {riskIndemnity} from '../constants/riskIndemnity';
import {privacyAndCookiePolicy} from '../constants/privacyAndCookiePolicy';
import {confidentiality} from '../constants/confidentiality';
import {breachOrCancellationByServiceProvider} from '../constants/breachOrCancellationByServiceProvider';
import {complianceWithEcommerce} from '../constants/complianceWithEcommerce';
import {complianceWithLaws} from '../constants/complianceWithLaws';
import {notices} from '../constants/notices';
import {generalClauses} from '../constants/generalClauses';
import {dataProtection} from '../constants/dataProtection';
import {cloudComputing} from '../constants/cloudComputing';
import React from 'react';
import {aiTrainingDerivativeWorks} from '../constants/aiTrainingDerivativeWorks';

export function Terms() {
  return (
    <ol>
      <TermsComponent
        title="Terms and conditions of use"
        terms={termsAndConditionsOfUse}
      />
      <TermsComponent
        title="Content of the website"
        terms={contentOfTheWebsite}
      />
      <TermsComponent
        title="Linked third party websites and third party content"
        terms={thirdPartyContent}
      />
      <TermsComponent
        title="Usage Restrictions"
        subTitle={
          'The user hereby agrees that it shall not itself, nor through a third party:'
        }
        terms={usageRestrictions}
      />
      <TermsComponent title="Security" terms={security} />
      <TermsComponent
        title="Intellectual Property Rights"
        terms={intellectualPropertyRights}
      />
      <TermsComponent
        title="Risk, limitation of liability and indemnity"
        terms={riskIndemnity}
      />
      <TermsComponent
        title="Service provider privacy and cookie policy"
        terms={privacyAndCookiePolicy}
      />
      <TermsComponent title="Confidentiality" terms={confidentiality} />
      <TermsComponent
        title="Breach or cancellation by service provider"
        terms={breachOrCancellationByServiceProvider}
      />
      <TermsComponent
        title="Compliance with E-Commerce Disclosures & Regulations"
        terms={complianceWithEcommerce}
      />
      <TermsComponent title="Compliance with laws" terms={complianceWithLaws} />
      <TermsComponent title="Notices" terms={notices} />
      <TermsComponent title="General clauses" terms={generalClauses} />
      <TermsComponent
        title="Data protection and GDPR compliance"
        terms={dataProtection}
      />
      <TermsComponent title="Cloud computing" terms={cloudComputing} />
      <TermsComponent
        title="AI training and derivative works"
        terms={aiTrainingDerivativeWorks}
      />
    </ol>
  );
}
