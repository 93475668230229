import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {theme} from './theme/DefaultTheme';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import {setupCustomBackButtonHandler} from './customBackHandler';
import {SplashScreenPlugin} from './SplashScreenPlugin';

Sentry.init({
  dsn:
    'https://bad783bc4bdf8aae936e645c275c9207@o4506067566723072.ingest.sentry.io/4506067569541120',
  integrations: [SentryBrowser.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

document.addEventListener('DOMContentLoaded', (event) => {
  window.addEventListener('load', () => {
    // Page has fully loaded, hide the splash screen
    SplashScreenPlugin.hide();
  });
});

const CapacitorApp: React.FC = () => {
  const [topPadding, setTopPadding] = useState(0);

  useEffect(() => {
    setupCustomBackButtonHandler();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <div style={{paddingTop: `${topPadding}px`}}>
            <App />
          </div>
        </React.StrictMode>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReactDOM.render(<CapacitorApp />, document.getElementById('root'));

serviceWorker.unregister();
