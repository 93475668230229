/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourEditDrawerMarkersTodoRefetchQueryVariables = {
    id: string;
};
export type TourEditDrawerMarkersTodoRefetchQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TourEditDrawerMarkers_tour">;
    } | null;
};
export type TourEditDrawerMarkersTodoRefetchQuery = {
    readonly response: TourEditDrawerMarkersTodoRefetchQueryResponse;
    readonly variables: TourEditDrawerMarkersTodoRefetchQueryVariables;
};



/*
query TourEditDrawerMarkersTodoRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...TourEditDrawerMarkers_tour
    id
  }
}

fragment TourEditDrawerMarkers_tour on Tour {
  markers {
    id
    name
    isFastTravel
    frameIndex
  }
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourEditDrawerMarkersTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TourEditDrawerMarkers_tour"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourEditDrawerMarkersTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Marker",
                "kind": "LinkedField",
                "name": "markers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFastTravel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frameIndex",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Tour",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa38791da0e7c8337217c12f255239ee",
    "id": null,
    "metadata": {},
    "name": "TourEditDrawerMarkersTodoRefetchQuery",
    "operationKind": "query",
    "text": "query TourEditDrawerMarkersTodoRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TourEditDrawerMarkers_tour\n    id\n  }\n}\n\nfragment TourEditDrawerMarkers_tour on Tour {\n  markers {\n    id\n    name\n    isFastTravel\n    frameIndex\n  }\n  id\n}\n"
  }
};
})();
(node as any).hash = '0c64d21251ed016d72167acbaa836f81';
export default node;
