import {AuthContextType} from './AuthContext'; // Adjust the import path as needed

// Helper function to check if the user is authenticated
const isAuthenticated = (
  auth: AuthContextType
): auth is {authenticated: true; roles: string[]; token: string} => {
  return auth.authenticated;
};

// Role check functions
export const isSuperAdmin = (auth: AuthContextType): boolean => {
  return isAuthenticated(auth) && auth.roles.includes('superadmin');
};

export const isAdmin = (auth: AuthContextType): boolean => {
  return (
    isAuthenticated(auth) &&
    (auth.roles.includes('admin') || auth.roles.includes('superadmin'))
  );
};

// Plan check functions
export const hasProPlan = (auth: AuthContextType): boolean => {
  return (
    isAuthenticated(auth) &&
    (auth.roles.includes('pro') || auth.roles.includes('offlicenselicense'))
  );
};

export const hasBasicPlanOrHigher = (auth: AuthContextType): boolean => {
  return (
    isAuthenticated(auth) &&
    (auth.roles.includes('basic') ||
      auth.roles.includes('pro') ||
      auth.roles.includes('offlicenselicense'))
  );
};

export const hasEntryPlanOrHigher = (auth: AuthContextType): boolean => {
  return (
    isAuthenticated(auth) &&
    (auth.roles.includes('entry') ||
      auth.roles.includes('basic') ||
      auth.roles.includes('pro') ||
      auth.roles.includes('offlicenselicense'))
  );
};

export const hasAnyPaidPlan = (auth: AuthContextType): boolean => {
  return hasEntryPlanOrHigher(auth);
};

// Combine role and plan checks
export const isAdminWithProPlan = (auth: AuthContextType): boolean => {
  return isAdmin(auth) && hasProPlan(auth);
};

// Function to get the user's highest plan level
export const getHighestPlanLevel = (auth: AuthContextType): string => {
  if (!isAuthenticated(auth)) return 'unauthenticated';
  if (auth.roles.includes('offlicenselicense')) return 'Offline License';
  if (auth.roles.includes('pro')) return 'Pro';
  if (auth.roles.includes('basic')) return 'Basic';
  if (auth.roles.includes('entry')) return 'Entry';
  return 'Free';
};
