/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TruvuTeamContainer_user = {
    readonly company: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly profile: {
        readonly name: string;
        readonly surname: string;
        readonly profileImageURL: string;
    } | null;
    readonly " $refType": "TruvuTeamContainer_user";
};
export type TruvuTeamContainer_user$data = TruvuTeamContainer_user;
export type TruvuTeamContainer_user$key = {
    readonly " $data"?: TruvuTeamContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"TruvuTeamContainer_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TruvuTeamContainer_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'fe9ab78bae09c0642c34f643b318cb58';
export default node;
