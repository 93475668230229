/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileDetails_user = {
    readonly id: string;
    readonly profile: {
        readonly name: string;
        readonly surname: string;
        readonly profileImageURL: string;
        readonly email: string;
    } | null;
    readonly company: {
        readonly invites: ReadonlyArray<{
            readonly accepted: boolean;
            readonly createdAt: unknown;
            readonly email: string;
            readonly expiresAt: unknown;
        }> | null;
        readonly license: {
            readonly planName: string | null;
            readonly stripeSubscriptionId: string | null;
            readonly monthlyActiveTours: number | null;
            readonly monthlyUploadedTours: number | null;
            readonly uploadLimit: number | null;
            readonly quantity: number;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ProfileCompanyDetails_user">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ProfileEditDetails_user" | "ProfilePlanDetails_user" | "ProfileAccountDetails_user">;
    readonly " $refType": "ProfileDetails_user";
};
export type ProfileDetails_user$data = ProfileDetails_user;
export type ProfileDetails_user$key = {
    readonly " $data"?: ProfileDetails_user$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileDetails_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ProfileTodoRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "ProfileDetails_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageURL",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamInvite",
          "kind": "LinkedField",
          "name": "invites",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accepted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiresAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "License",
          "kind": "LinkedField",
          "name": "license",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripeSubscriptionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyActiveTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyUploadedTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uploadLimit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileCompanyDetails_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileEditDetails_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfilePlanDetails_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAccountDetails_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = '749c25cbbd5d82faffd89143767b1934';
export default node;
