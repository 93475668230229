import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useRefetchable, useSubscription} from 'relay-hooks';
import {TourCard_tour$key} from '../../../__generated__/TourCard_tour.graphql';
import {TourCardSubscription} from '../../../__generated__/TourCardSubscription.graphql';
import {TourCardProcessed} from './TourCardProcessed';
import {TourCardFailed} from './TourCardFailed';
import {TourCardProcessing} from './TourCardProcessing';
import './tourCard.css';

const subscriptionSpec = graphql`
  subscription TourCardSubscription($tourId: ID!) {
    tourProgressUpdated(tourId: $tourId) {
      id
      status
      progress
      text
      thumbnailURL
    }
  }
`;

const fragmentSpec = graphql`
  fragment TourCard_tour on Tour
  @refetchable(queryName: "TourCardRefetchQuery") {
    id
    title
    views
    createdAt
    description
    videoURL
    thumbnailURL
    reels {
      id
      key
      progress
      seed
      provider
      highURI
      thumbnailURL
      status {
        status
      }
    }
    status {
      status
    }
    logs {
      status
      error
    }
  }
`;

interface TourCardProps {
  tourRef: TourCard_tour$key;
  refetchTours: () => void;
  processing?: boolean;
}

export const TourCard = React.memo(function TourCardComponent({
  tourRef,
  refetchTours,
}: TourCardProps) {
  const {data: tour, refetch, isLoading} = useRefetchable(
    fragmentSpec,
    tourRef
  );
  const {
    id,
    thumbnailURL,
    status: {status},
    title,
  } = tour;

  const [progress, setProgress] = useState(0);

  useSubscription<TourCardSubscription>(
    React.useMemo(
      () => ({
        subscription: subscriptionSpec,
        variables: {
          tourId: id,
        },
        onNext: (response) => {
          if (response?.tourProgressUpdated) {
            console.log(
              'Progress Tour Update.progress:',
              response.tourProgressUpdated.progress
            );
            setProgress(response.tourProgressUpdated.progress);
          }
        },
      }),
      [id]
    )
  );

  React.useEffect(() => {
    if (thumbnailURL === '') {
      refetch({});
    }
  }, [refetch, thumbnailURL]);
  if (status === 'PROCESSED') {
    return (
      <TourCardProcessed
        tour={tour}
        refetchTours={refetchTours}
        refetchTour={() => {
          refetch({});
        }}
        isLoading={isLoading}
      />
    );
  }

  if (status === 'FAILED') {
    return (
      <TourCardFailed title={title} tourId={id} refetchTours={refetchTours} />
    );
  }

  return (
    <TourCardProcessing
      title={title}
      tourId={id}
      refetchTours={refetchTours}
      progress={progress}
    />
  );
});
