import React from 'react';
import {TruvuMenu} from '../../../components/TruvuMenu';
import {UploadProfile} from './UploadProfile';
import {ProfileEditDetails} from './ProfileEditDetails';
import {ProfileEditDetails_user$key} from '../../../__generated__/ProfileEditDetails_user.graphql';

interface ProfileAddDetailsProps {
  userRef: ProfileEditDetails_user$key;
  refetch: () => void;
}

export function ProfileAddDetails({userRef, refetch}: ProfileAddDetailsProps) {
  const [newProfile, setNewProfile] = React.useState<File | null>(null);
  return (
    <>
      <TruvuMenu
        title="Add profile details"
        action={
          <UploadProfile
            src={newProfile ? URL.createObjectURL(newProfile) : ''}
            setNewProfile={setNewProfile}
          />
        }
        alwaysSpaceBetween
      />
      <ProfileEditDetails
        userRef={userRef}
        onCompleted={refetch}
        newProfile={newProfile}
      />
    </>
  );
}
