import React, {forwardRef} from 'react';
import ButtonBase, {ButtonBaseProps} from '@mui/material/ButtonBase';
import {CircularProgress, styled} from '@mui/material';
import {useHistory} from 'react-router';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

export type TruvuButtonVariants =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'default'
  | 'textPrimary'
  | 'text';
export type TruvuIconButtonSizes = 'small' | 'default' | 'large';

export type TruvuButtonBaseProps = ButtonBaseProps & {
  disableAdornments?: boolean;
  variant?: TruvuButtonVariants | undefined;
  size?: TruvuIconButtonSizes;
  loading?: boolean;
  to?: string;
  enableAuxNavigate?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loadingText?: string;
  label?: string;
  upload?: boolean;
  accept?: string;
  InputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

interface StyledButtonProps {
  $variant: TruvuButtonVariants;
  $size: TruvuIconButtonSizes;
  $iconButton?: boolean;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const StyledButton = styled(ButtonBase, {
  shouldForwardProp: addShouldNotForwardProps(
    '$variant',
    '$size',
    'InputProps',
    'upload',
    'loadingText'
  ),
})<StyledButtonProps>(({theme, $variant, $size}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: $size === 'large' ? '20px' : $size === 'small' ? '15px' : '18px',
    textAlign: 'center',
    fontWeight: 700,
    justifyContent: 'center',
    padding: $size === 'large' ? '30px' : $size === 'small' ? '5px' : '20px',
    // justifyContent: 'space-between',
    borderRadius: '100px',
    ...($variant === 'textPrimary'
      ? {color: theme.palette.primary.main}
      : $variant === 'text'
      ? {color: theme.palette.text.primary}
      : $variant === 'default'
      ? {
          color: theme.palette.text.primary,
          backgroundColor: '#cecece',
        }
      : $variant === 'secondary'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
        }
      : $variant === 'danger'
      ? {
          color: theme.palette.error.main,
          backgroundColor: theme.palette.error.light,
        }
      : {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        }),
  };
});

export const TruvuButtonBase = forwardRef<
  HTMLButtonElement,
  TruvuButtonBaseProps
>(function TruvuButtonBase(
  {
    loading = false,
    variant = 'primary',
    disableAdornments,
    size = 'default',
    to,
    onClick,
    onAuxClick,
    children,
    enableAuxNavigate,
    ...props
  },
  ref
) {
  const history = useHistory();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (to) {
        history.push(to);
      } else if (onClick != null) {
        onClick(event);
      }
    },
    [onClick, history, to]
  );

  const handleAuxClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (to && enableAuxNavigate) {
        window.open(to, '_blank');
      } else if (onAuxClick != null) {
        onAuxClick(event);
      }
    },
    [enableAuxNavigate, onAuxClick, to]
  );

  return (
    <StyledButton
      component={props.upload ? 'label' : 'button'}
      $variant={variant}
      $size={size}
      onClick={handleClick}
      onAuxClick={handleAuxClick}
      {...props}
      ref={ref}
    >
      {loading ? (
        <CircularProgress
          size={20}
          color={
            variant === 'primary'
              ? 'secondary'
              : variant === 'danger'
              ? 'error'
              : 'primary'
          }
        />
      ) : (
        props.startIcon
      )}
      {loading && props.loadingText ? props.loadingText : children}
      {props.endIcon}
      {props.upload === true && (
        <VisuallyHiddenInput
          type="file"
          accept={props.accept}
          {...props.InputProps}
        />
      )}
    </StyledButton>
  );
});
