/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BillingPortalSessionInput = {
    returnStub: string;
};
export type ProfilePlanDetailsBillingPortalSessionMutationVariables = {
    input: BillingPortalSessionInput;
};
export type ProfilePlanDetailsBillingPortalSessionMutationResponse = {
    readonly billingPortalSession: {
        readonly url: string;
        readonly error: string | null;
    };
};
export type ProfilePlanDetailsBillingPortalSessionMutation = {
    readonly response: ProfilePlanDetailsBillingPortalSessionMutationResponse;
    readonly variables: ProfilePlanDetailsBillingPortalSessionMutationVariables;
};



/*
mutation ProfilePlanDetailsBillingPortalSessionMutation(
  $input: BillingPortalSessionInput!
) {
  billingPortalSession(input: $input) {
    url
    error
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BillingPortalSessionPayload",
    "kind": "LinkedField",
    "name": "billingPortalSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePlanDetailsBillingPortalSessionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilePlanDetailsBillingPortalSessionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54e15bfc5dd76890d7c7769b1f5b49c1",
    "id": null,
    "metadata": {},
    "name": "ProfilePlanDetailsBillingPortalSessionMutation",
    "operationKind": "mutation",
    "text": "mutation ProfilePlanDetailsBillingPortalSessionMutation(\n  $input: BillingPortalSessionInput!\n) {\n  billingPortalSession(input: $input) {\n    url\n    error\n  }\n}\n"
  }
};
})();
(node as any).hash = '4dfb2850e730a5a15fb5722578a3bf6e';
export default node;
