import React from 'react';
import {TermsComponent} from '../../terms/components/TermsComponent';
import {definitions} from '../constants/definitions';
import {contactUs} from '../constants/contactUs';
import {changesToThisPrivacyPolicy} from '../constants/changesToThisPrivacyPolicy';
import {childrenPrivacy} from '../constants/childrenPrivacy';
import {contests} from '../constants/contests';
import {securityOfData} from '../constants/securityOfData';
import {transferOfData} from '../constants/transferOfData';
import {retentionOfData} from '../constants/retentionOfData';
import {cookies} from '../constants/cookies';
import {typesOfDataCollected} from '../constants/typesOfDataCollected';
import {useOfData} from '../constants/useOfData';
import {legalBasis} from '../constants/legalBasis';
import {disclosureOfData} from '../constants/disclosureOfData';
import {legalRequirements} from '../constants/legalRequirements';
import {yourDataProtection} from '../constants/yourDataProtection';
import {serviceProviders} from '../constants/serviceProviders';
import {complianceWithTheProtection} from '../constants/complianceWithTheProtection';

export function PrivacyPolicies() {
  return (
    <ol>
      <TermsComponent title="Definitions" terms={definitions} />
      <TermsComponent
        title="Types of Data Collected"
        terms={typesOfDataCollected}
      />
      <TermsComponent title="Use of Data" terms={useOfData} />
      <TermsComponent title="Cookies & Advertising" terms={cookies} />
      <TermsComponent
        title="Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)"
        terms={legalBasis}
      />
      <TermsComponent title="Retention of Data" terms={retentionOfData} />
      <TermsComponent title="Transfer of Data" terms={transferOfData} />
      <TermsComponent title="Disclosure of Data" terms={disclosureOfData} />
      <TermsComponent
        title="Legal Requirements: We may disclose your Personal Data in the good faith belief that such action is necessary to:"
        terms={legalRequirements}
      />
      <TermsComponent title="Security of Data" terms={securityOfData} />
      <TermsComponent
        title="Your Data Protection Rights Under General Data Protection Regulation (GDPR)"
        terms={yourDataProtection}
      />
      <TermsComponent
        title="Compliance with the Protection of Personal Information Act (POPIA)"
        terms={complianceWithTheProtection}
      />
      <TermsComponent title="Service Providers" terms={serviceProviders} />
      <TermsComponent title="Contests" terms={contests} />
      <TermsComponent title="Children's Privacy" terms={childrenPrivacy} />
      <TermsComponent
        title="Changes to This Privacy Policy"
        terms={changesToThisPrivacyPolicy}
      />
      <TermsComponent title="Contact Us" terms={contactUs} />
    </ol>
  );
}
