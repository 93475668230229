import React from 'react';

import Typography from '@mui/material/Typography';
import {Stack, styled, useMediaQuery} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ButtonBase from '@mui/material/ButtonBase';
import graphql from 'babel-plugin-relay/macro';
import {useRefetchable} from 'relay-hooks';
import {MemberCard_invite$key} from '../../../__generated__/MemberCard_invite.graphql';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {PendingMemberCardActions} from './PendingMemberCardActions';
import './memberCard.css';
import {Theme} from '@mui/material/styles';
import {ActiveMemberCardActions} from './ActiveMemberCardActions';
import {ActiveMemberStats} from './ActiveMemberStats';
import {ActiveMemberDialog} from './ActiveMemberDialog';

const fragmentSpec = graphql`
  fragment MemberCard_invite on TeamInvite
  @refetchable(queryName: "MemberCardRefetchQuery") {
    ...ActiveMemberStats_invite
    id
    email
    createdAt
    expiresAt
    accepted
    user {
      isAdmin
      user {
        id
        username
      }
    }
  }
`;

const ClickableMemberCard = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  padding: '10px',
  gap: '10px',
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('md')]: {gap: '12px', padding: '12px'},
}));

const StyledCardContent = styled(Stack)(() => ({
  flex: 1,
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  textAlign: 'left',
}));

interface MemberCardProps {
  inviteRef: MemberCard_invite$key;
  refetchTeam: () => void;
  processing?: boolean;
}

export const MemberCard = React.memo(function MemberCardComponent({
  inviteRef,
  refetchTeam,
}: MemberCardProps) {
  const {data: invite} = useRefetchable(fragmentSpec, inviteRef);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {id, email, accepted, user} = invite;

  const {
    openDialog: openActionsDialog,
    onCloseDialog: onCloseActionsDialog,
    onOpenDialog: onOpenActionsDialog,
  } = useTruvuDialog();

  return (
    <>
      {accepted && user ? (
        <ActiveMemberDialog
          open={openActionsDialog}
          onClose={onCloseActionsDialog}
          title={email}
        >
          <ActiveMemberStats fragmentRef={invite} />
          <ActiveMemberCardActions
            onCloseDialog={onCloseActionsDialog}
            onRevokeCallback={refetchTeam}
            title={email}
            user={user}
          />
        </ActiveMemberDialog>
      ) : (
        <TruvuDialog
          open={openActionsDialog}
          onClose={onCloseActionsDialog}
          title={email}
        >
          <PendingMemberCardActions
            onCloseDialog={onCloseActionsDialog}
            onRevokeCallback={refetchTeam}
            title={email}
            id={id}
          />
        </TruvuDialog>
      )}
      <ClickableMemberCard onClick={onOpenActionsDialog}>
        <PersonOutlineIcon
          style={{fontSize: isDesktop ? 60 : 40, color: 'grey'}}
        />
        <StyledCardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="body1">
              {user?.user?.username ?? email}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body2" color={accepted ? 'primary' : 'orange'}>
              {!accepted
                ? 'Pending'
                : user?.isAdmin
                ? 'Admin User'
                : 'Standard User'}
            </Typography>
          </Stack>
          <Typography
            className="truncate"
            component="p"
            fontSize="0.9rem"
            color="text.secondary"
            lineHeight="1.2em"
          >
            {email}
          </Typography>
        </StyledCardContent>
      </ClickableMemberCard>
    </>
  );
});
