/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourPreviewTodoRefetchQueryVariables = {
    id: string;
};
export type TourPreviewTodoRefetchQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TourPreview_tour">;
    } | null;
};
export type TourPreviewTodoRefetchQuery = {
    readonly response: TourPreviewTodoRefetchQueryResponse;
    readonly variables: TourPreviewTodoRefetchQueryVariables;
};



/*
query TourPreviewTodoRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...TourPreview_tour
    id
  }
}

fragment TourPlayerCanvas_tour on Tour {
  id
  title
  description
  videoURL
  thumbnailURL
  tourIntroLogoURL
  videoInfo {
    width
    height
    fullSize
    mediumSize
    smallSize
  }
  markers {
    id
    name
    description
    scrollPosition
    frameIndex
  }
  clips {
    id
    index
    frameCount
    duration
    highURI
    lowURI
  }
}

fragment TourPreviewMarkers_tour on Tour {
  markers {
    id
    name
    isFastTravel
    frameIndex
  }
}

fragment TourPreview_tour on Tour {
  ...TourPreviewMarkers_tour
  ...TourPlayerCanvas_tour
  thumbnailURL
  title
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourPreviewTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TourPreview_tour"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourPreviewTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Marker",
                "kind": "LinkedField",
                "name": "markers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFastTravel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frameIndex",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scrollPosition",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tourIntroLogoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TourVideoInfo",
                "kind": "LinkedField",
                "name": "videoInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediumSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "smallSize",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Clip",
                "kind": "LinkedField",
                "name": "clips",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "index",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frameCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highURI",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowURI",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Tour",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7549f704a4b8df9d4f6da501e7d41411",
    "id": null,
    "metadata": {},
    "name": "TourPreviewTodoRefetchQuery",
    "operationKind": "query",
    "text": "query TourPreviewTodoRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TourPreview_tour\n    id\n  }\n}\n\nfragment TourPlayerCanvas_tour on Tour {\n  id\n  title\n  description\n  videoURL\n  thumbnailURL\n  tourIntroLogoURL\n  videoInfo {\n    width\n    height\n    fullSize\n    mediumSize\n    smallSize\n  }\n  markers {\n    id\n    name\n    description\n    scrollPosition\n    frameIndex\n  }\n  clips {\n    id\n    index\n    frameCount\n    duration\n    highURI\n    lowURI\n  }\n}\n\nfragment TourPreviewMarkers_tour on Tour {\n  markers {\n    id\n    name\n    isFastTravel\n    frameIndex\n  }\n}\n\nfragment TourPreview_tour on Tour {\n  ...TourPreviewMarkers_tour\n  ...TourPlayerCanvas_tour\n  thumbnailURL\n  title\n  id\n}\n"
  }
};
})();
(node as any).hash = '517aeb6847b9219d9ccf9fe3782d6c54';
export default node;
