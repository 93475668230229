/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourEdit_tour = {
    readonly thumbnailURL: string;
    readonly markers: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly description: string;
        readonly scrollPosition: number;
        readonly frameIndex: number;
    }>;
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"TourPlayerCanvas_tour" | "TourEditDrawerMarkers_tour" | "TourAddMarkers_tour">;
    readonly " $refType": "TourEdit_tour";
};
export type TourEdit_tour$data = TourEdit_tour;
export type TourEdit_tour$key = {
    readonly " $data"?: TourEdit_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourEdit_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourEditTodoRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourEdit_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Marker",
      "kind": "LinkedField",
      "name": "markers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scrollPosition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frameIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TourPlayerCanvas_tour"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TourEditDrawerMarkers_tour"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TourAddMarkers_tour"
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = '37451070b40ff93abd692a2de4f32a41';
export default node;
