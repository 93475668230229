import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {Skeleton, Stack, styled} from '@mui/material';
import {TourCardContent} from '../../tourLanding/components/styled/TourCardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import {ActiveMemberTourCard_tour$key} from '../../../__generated__/ActiveMemberTourCard_tour.graphql';

const fragmentSpec = graphql`
  fragment ActiveMemberTourCard_tour on Tour {
    id
    title
    views
    description
    thumbnailURL
  }
`;

interface ActiveMemberTourCardProps {
  tourRef: ActiveMemberTourCard_tour$key;
}

export function ActiveMemberTourCard({tourRef}: ActiveMemberTourCardProps) {
  const {id, thumbnailURL, description, title, views} = useFragment(
    fragmentSpec,
    tourRef
  );

  return (
    <TourCard
      onClick={() => {
        window.open(
          `${window.location.protocol}/${window.location.host}/playerext?tourId=${id}`,
          '_blank'
        );
      }}
    >
      {thumbnailURL != null && thumbnailURL !== '' ? (
        <TourCardThumbnail>
          <img
            src={thumbnailURL}
            alt="Unable to load thumbnail"
            draggable={false}
          />
        </TourCardThumbnail>
      ) : (
        <Skeleton variant="rectangular" width={60} height={60} />
      )}
      <TourCardContent>
        {title ? (
          <Typography className="truncate title" variant="body1">
            {title}
          </Typography>
        ) : (
          <Typography variant="body1" color={'text.secondary'}>
            Insert Title
          </Typography>
        )}
        <Typography
          className="truncate"
          component="p"
          fontSize="0.9rem"
          color="text.secondary"
          lineHeight="1.2em"
        >
          {description}
        </Typography>
        <Stack flex={1} direction="row" spacing={0.5} alignItems="flex-end">
          <Typography color="primary" lineHeight="1.2em">
            {views === 1 ? `${views} view` : `${views} views`}
          </Typography>
        </Stack>
      </TourCardContent>
    </TourCard>
  );
}
const TourCard = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  width: '100%',
  padding: '10px',
  gap: '10px',
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
}));

export const TourCardThumbnail = styled(Box)({
  width: '60px',
  height: '60px',
  borderRadius: '8px',
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    objectFit: 'cover',
    objectPosition: 'center',
    height: '100%',
    width: '100%',
  },
});
