import {TruvuDialog} from '../../../components/dialog/TruvuDialog';
import {LinearProgress, Stack, styled, Typography} from '@mui/material';
import React from 'react';
import {CloudUpload} from '@mui/icons-material';

interface UploadTourProgressDialogProps {
  isOpen: boolean;
  ProgressBar: React.ReactNode;
}

export function UploadTourProgressDialog({
  isOpen,
  ProgressBar,
}: UploadTourProgressDialogProps) {
  return (
    <TruvuDialog isOpen={isOpen}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2.5}
      >
        <Typography variant="h1">Video uploading...</Typography>
        <CloudUpload
          color={'primary'}
          sx={{
            width: '80px',
            height: '80px',
          }}
        />
      </Stack>
      <Stack py={1}>Please wait while your video is uploading</Stack>
      <Stack pt={1} pb={3}>
        {ProgressBar}
      </Stack>
      {/*{actions != null && (*/}
      {/*  <Stack*/}
      {/*    flex={1}*/}
      {/*    spacing={2}*/}
      {/*    justifyContent="flex-end"*/}
      {/*    direction="column"*/}
      {/*    pb={1}*/}
      {/*  >*/}
      {/*    {actions}*/}
      {/*  </Stack>*/}
      {/*)}*/}
    </TruvuDialog>
  );
}

export const ProgressBar = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.secondary.main,
  '& > span': {
    borderRadius: 5,
  },
}));
