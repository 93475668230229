import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import processingIcon from '../../../assets/json/processing_icon.json';
import Typography from '@mui/material/Typography';
import React, {useCallback, useState} from 'react';
import {TourCardContent} from './styled/TourCardContent';
import {useMediaQuery, LinearProgress, Box} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {useMutation} from 'react-relay-mutation';
import {TourCardFailedMutation} from '../../../__generated__/TourCardFailedMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {TourCardClickable} from './styled/TourCardClickable';

interface TourCardProcessingProps {
  tourId: string;
  title: string;
  refetchTours: () => void;
  progress: number;
}

export function TourCardProcessing({
  tourId,
  title,
  refetchTours,
  progress,
}: TourCardProcessingProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {notify} = useSnackbarContext();
  const [tourDelete] = useMutation<TourCardFailedMutation>(
    graphql`
      mutation TourCardProcessingMutation($tourId: ID!) {
        tourDelete(input: {tourId: $tourId}) {
          clientMutationId
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Deleted tour', variant: 'success'});
        onCloseActionsDialog();
        refetchTours();
      },
    }
  );
  const {
    openDialog: openActionsDialog,
    onCloseDialog: onCloseActionsDialog,
    onOpenDialog: onOpenActionsDialog,
  } = useTruvuDialog();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      await tourDelete({
        variables: {
          tourId,
        },
      });
    } catch (e) {
      notify({message: 'Could not delete tour data', variant: 'error'});
    } finally {
      setDeleting(false);
    }
  }, [tourId, notify, tourDelete]);

  return (
    <>
      <TruvuDialog
        open={openActionsDialog}
        onClose={onCloseActionsDialog}
        title={title}
      >
        <TruvuButton
          onClick={handleDelete}
          variant="danger"
          style={{marginTop: '30px'}}
          loading={deleting}
        >
          {deleting ? 'Deleting Tour' : 'Delete Tour'}
        </TruvuButton>
      </TruvuDialog>
      <TourCardClickable onClick={onOpenActionsDialog}>
        <Lottie
          animationData={processingIcon}
          play
          loop
          style={{
            width: isDesktop ? '90px' : '60px',
            height: isDesktop ? '90px' : '60px',
          }}
        />
        <TourCardContent>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2" fontWeight={500} color="primary">
            Processing video
          </Typography>
          <Box sx={{width: '100%', mt: 2}}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Typography variant="body2" sx={{mt: 1}}>
            {`${Math.round(progress)}%`}
          </Typography>
        </TourCardContent>
      </TourCardClickable>
    </>
  );
}
