import React, {KeyboardEvent, useCallback, useState} from 'react';
import {InputAdornment, styled, TextField, TextFieldProps} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Visibility, VisibilityOff} from '@mui/icons-material';

export type TruvuTextFieldBaseProps = TextFieldProps & {
  label?: string;
  isPassword?: boolean;
  helperText?: string;
  displayOnly?: boolean;
  shrinkLabel?: boolean;
  endIcon?: React.ReactNode;
  onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export function TruvuTextFieldBase({
  label,
  name,
  id,
  helperText,
  isPassword,
  displayOnly,
  shrinkLabel = false,
  onEnter,
  endIcon,
  ...props
}: TruvuTextFieldBaseProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const inputId = id ?? name ?? 'truvu-textfield';

  const handleEnterCallback = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (onEnter != null && event.key === 'Enter') {
        onEnter(event);
      }
    },
    [onEnter]
  );

  return (
    <RoundedTextField
      type={isPassword && !showPassword ? 'password' : 'text'}
      id={inputId}
      onKeyDown={onEnter != null ? handleEnterCallback : undefined}
      variant="filled"
      label={label}
      {...props}
      InputProps={{
        disableUnderline: true,
        endAdornment: isPassword ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={
                showPassword ? 'hide the password' : 'display the password'
              }
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : undefined,
        ...props.InputProps,
      }}
    />
  );
}

const RoundedTextField = styled(TextField)(() => ({
  '& .MuiFormControl-root': {
    backgroundColor: '#edeff2',
    color: '#191c1f',
    borderRadius: '16px',
  },
  '& .MuiFilledInput-root': {
    backgroundColor: '#edeff2',
    color: '#191c1f',
    borderRadius: '16px',
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:before': {
      borderBottom: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: '#e8e9ee',
    },
  },
}));
