import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React from 'react';
import {useTheme} from '@mui/material';

interface TruvuDividerProps {
  spacing: number;
}

export function TruvuDivider({spacing = 2}: TruvuDividerProps) {
  const theme = useTheme();
  return (
    <Stack my={spacing}>
      <Divider
        color={theme.palette.divider}
        sx={{height: '3px', borderRadius: '5px'}}
      />
    </Stack>
  );
}
