import React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface UploadProfileProps {
  setNewProfile: React.Dispatch<React.SetStateAction<File | null>>;
  src: string;
}

export function UploadProfile({setNewProfile, src}: UploadProfileProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleUploadFile = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setNewProfile(event.target.files[0]);
      }
    },
    [setNewProfile]
  );
  const UploadIcon = (
    <CloudUploadIcon
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 45,
        height: 45,
        opacity: 0.7,
      }}
    />
  );
  const InputElement = (
    <VisuallyHiddenInput onChange={handleUploadFile} type="file" />
  );

  if (isMobile) {
    return (
      <UploadProfileMobile
        src={src}
        UploadIcon={UploadIcon}
        InputElement={InputElement}
      />
    );
  }

  return (
    <UploadProfileDesktop
      src={src}
      UploadIcon={UploadIcon}
      InputElement={InputElement}
    />
  );
}

interface UploadProfileComponentProps {
  src: string;
  UploadIcon: React.ReactNode;
  InputElement: React.ReactNode;
}

function UploadProfileMobile({
  src,
  UploadIcon,
  InputElement,
}: UploadProfileComponentProps) {
  return (
    <IconButton
      component="label"
      sx={{padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}
    >
      <Avatar src={src} sx={{width: 95, height: 95, opacity: 0.6}} />
      {UploadIcon}
      <CloudUploadIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 45,
          height: 45,
          opacity: 0.5,
        }}
      />
      {InputElement}
    </IconButton>
  );
}
function UploadProfileDesktop({
  src,
  UploadIcon,
  InputElement,
}: UploadProfileComponentProps) {
  const [mouseOver, setMouseOver] = React.useState(false);

  return (
    <IconButton
      component="label"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      sx={{
        padding: 0,
        backgroundColor: mouseOver ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
      }}
    >
      <Avatar
        src={src}
        sx={{
          bgcolor: '#dfe2e6',
          width: 60,
          height: 60,
          opacity: mouseOver ? 0.6 : 1,
        }}
      />
      {mouseOver && UploadIcon}
      {InputElement}
    </IconButton>
  );
}
