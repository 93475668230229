/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileEditCompanyDetails_user = {
    readonly id: string;
    readonly logoimageurl: string;
    readonly name: string;
    readonly address: string;
    readonly vatno: string;
    readonly countrycode: string;
    readonly " $refType": "ProfileEditCompanyDetails_user";
};
export type ProfileEditCompanyDetails_user$data = ProfileEditCompanyDetails_user;
export type ProfileEditCompanyDetails_user$key = {
    readonly " $data"?: ProfileEditCompanyDetails_user$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileEditCompanyDetails_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileEditCompanyDetails_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoimageurl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatno",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countrycode",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
(node as any).hash = '5fbb77a795d3aa21da347801b02ea086';
export default node;
