import React, {useEffect, useState} from 'react';
import {TermsComponent} from '../../terms/components/TermsComponent';
import {definitions} from '../constants/definitions';

export function LicensePolicies() {
  const [licenseTerms, setLicenseTerms] = useState('');

  useEffect(() => {
    fetch('/libav-3.9.5.1-webm-opus-flac.wasm.js')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.text();
      })
      .then((text) => {
        const lines = text.split('\n');
        const filteredLines = lines.filter((line) =>
          line.trim().startsWith('*')
        );
        setLicenseTerms(filteredLines.join('\n'));
      })
      .catch((error) => console.error('Error fetching license file:', error));
  }, []);
  return (
    <ol>
      <TermsComponent title="License Terms" terms={definitions} />
      <div className="terms-container">
        <pre>{licenseTerms}</pre>
      </div>
    </ol>
  );
}
