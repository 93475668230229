import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useRefetchable} from 'relay-hooks';
import {TourPreview_tour$key} from '../../../../__generated__/TourPreview_tour.graphql';
import {FrameStoreBuffer} from '../../types';
import {TourPlayer} from '../../components/TourPlayer';
import {TourPreviewMenu} from './TourPreviewMenu';
import {TourPreviewDrawer} from './TourPreviewDrawer';
import {
  TOUR_DRAWER_HEIGHT,
  TOUR_DRAWER_WIDTH,
} from '../../components/TourDrawer';
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useSearchParam} from 'react-use';

const fragment = graphql`
  fragment TourPreview_tour on Tour
  @refetchable(queryName: "TourPreviewTodoRefetchQuery") {
    ...TourPreviewMarkers_tour
    ...TourPlayerCanvas_tour
    thumbnailURL
    title
  }
`;
interface TourPreviewProps {
  tourRef: TourPreview_tour$key;
  external?: boolean;
}

export function TourPreview({tourRef, external = false}: TourPreviewProps) {
  const {data: tour} = useRefetchable(fragment, tourRef);
  const disabledControls = useSearchParam('disabledControls');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [buffer, setBuffer] = React.useState<FrameStoreBuffer>({
    totalImages: 0,
    storeLength: 0,
    fps: 0,
  });
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const tourCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [keepDrawerOpen, setKeepDrawerOpen] = React.useState(false);
  const [fastTravellingStatus, setFastTravellingStatus] = useState<
    'idle' | 'forward' | 'backward'
  >('idle');

  React.useEffect(() => {
    const onResize = () => {
      if (
        tourCanvasRef.current?.clientHeight == null ||
        tourCanvasRef.current?.clientWidth == null
      ) {
        setKeepDrawerOpen(false);
        return;
      }
      if (
        window.innerWidth > window.innerHeight &&
        tourCanvasRef.current.clientWidth >
          tourCanvasRef.current.clientHeight &&
        window.innerWidth -
          parseInt(TOUR_DRAWER_WIDTH) -
          tourCanvasRef.current.clientWidth >
          50
      ) {
        setKeepDrawerOpen(true);
      } else if (
        window.innerHeight > window.innerWidth &&
        window.innerHeight -
          parseInt(TOUR_DRAWER_HEIGHT) -
          tourCanvasRef.current.clientHeight >
          50
      ) {
        setKeepDrawerOpen(true);
      } else {
        setKeepDrawerOpen(false);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tourCanvasRef]);

  const handleGetCanvasDimensions = React.useCallback(
    (width: number, height: number) => {
      if (width == null || width === 0 || height == null || height === 0) {
        return;
      }

      if (
        height > width &&
        window.innerHeight - height - parseInt(TOUR_DRAWER_HEIGHT) > 50
      ) {
        setKeepDrawerOpen(true);
      } else if (
        !isMobile &&
        width > height &&
        window.innerWidth - width - parseInt(TOUR_DRAWER_WIDTH) > 50
      ) {
        setKeepDrawerOpen(true);
      } else if (
        window.innerHeight - height - parseInt(TOUR_DRAWER_HEIGHT) >
        50
      ) {
        setKeepDrawerOpen(true);
      }
    },
    [isMobile]
  );

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          position: 'absolute',
          inset: 0,
          left: 0,
          zIndex: 0,
          backgroundColor: '#758089',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        {tour.thumbnailURL != null && (
          <img
            src={tour.thumbnailURL}
            alt={'thumbnail'}
            style={{
              width: '120vw',
              height: '120dvh',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              objectFit: 'cover',
              objectPosition: 'center',
              filter: 'blur(50px)',
              opacity: 0.9,
            }}
          />
        )}
      </div>
      <TourPlayer
        fastTravellingStatus={fastTravellingStatus}
        tourRef={tour}
        currentIndex={currentIndex}
        displayController={buffer.storeLength > 0}
        drawerIsOpen={drawerIsOpen || keepDrawerOpen}
        drawerRef={drawerRef}
        tourCanvasRef={tourCanvasRef}
        maxIndex={buffer.storeLength}
        setBuffer={setBuffer}
        setCurrentIndex={setCurrentIndex}
        getCanvasDimensions={handleGetCanvasDimensions}
      />
      {!splitDisableControls?.includes('menu') && (
        <TourPreviewMenu
          drawerIsOpen={drawerIsOpen || keepDrawerOpen}
          title={tour.title}
          tourId={tour.id}
          external={external}
        />
      )}
      {!splitDisableControls?.includes('markers') && (
        <TourPreviewDrawer
          setFastTravellingStatus={setFastTravellingStatus}
          drawerRef={drawerRef}
          drawerIsOpen={drawerIsOpen}
          keepDrawerOpen={keepDrawerOpen}
          setDrawerIsOpen={setDrawerIsOpen}
          tourRef={tour}
          buffer={buffer}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )}
    </>
  );
}
