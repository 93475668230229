/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ActiveMemberCardActionsRevokeAdminDialogMutationVariables = {
    userId: string;
};
export type ActiveMemberCardActionsRevokeAdminDialogMutationResponse = {
    readonly revokeAdminMember: {
        readonly success: boolean;
    };
};
export type ActiveMemberCardActionsRevokeAdminDialogMutation = {
    readonly response: ActiveMemberCardActionsRevokeAdminDialogMutationResponse;
    readonly variables: ActiveMemberCardActionsRevokeAdminDialogMutationVariables;
};



/*
mutation ActiveMemberCardActionsRevokeAdminDialogMutation(
  $userId: ID!
) {
  revokeAdminMember(input: {userId: $userId}) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "RevokeAdminMemberPayload",
    "kind": "LinkedField",
    "name": "revokeAdminMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveMemberCardActionsRevokeAdminDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveMemberCardActionsRevokeAdminDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "118eedb4a7c3111601ca37444385ae76",
    "id": null,
    "metadata": {},
    "name": "ActiveMemberCardActionsRevokeAdminDialogMutation",
    "operationKind": "mutation",
    "text": "mutation ActiveMemberCardActionsRevokeAdminDialogMutation(\n  $userId: ID!\n) {\n  revokeAdminMember(input: {userId: $userId}) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '8973db913e58c52a9ce4c94aa17c3aca';
export default node;
