import React from 'react';
import {TourDrawer} from '../../components/TourDrawer';
import Typography from '@mui/material/Typography';
import {
  TourPreviewMarkers,
  TourPreviewMarkersProps,
} from './TourPreviewMarkers';

interface TourPreviewDrawerProps extends TourPreviewMarkersProps {
  drawerIsOpen: boolean;
  keepDrawerOpen: boolean;
  setFastTravellingStatus: React.Dispatch<
    React.SetStateAction<'idle' | 'forward' | 'backward'>
  >;
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerRef: React.RefObject<HTMLDivElement>;
}

export function TourPreviewDrawer({
  drawerIsOpen,
  keepDrawerOpen,
  setDrawerIsOpen,
  setFastTravellingStatus,
  drawerRef,
  ...markersProps
}: TourPreviewDrawerProps) {
  return (
    <TourDrawer
      keepDrawerOpen={keepDrawerOpen}
      open={drawerIsOpen}
      setOpen={setDrawerIsOpen}
      drawerRef={drawerRef}
    >
      <Typography variant="h2" mb={2}>
        Fast Travel
      </Typography>
      <TourPreviewMarkers
        setFastTravellingStatus={setFastTravellingStatus}
        {...markersProps}
      />
    </TourDrawer>
  );
}
