import Typography from '@mui/material/Typography';
import React from 'react';
import {Stack} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {TruvuDialog} from '../../../components/dialog/TruvuDialog';
import {TruvuButton} from '../../../components/button/TruvuButton';

interface TourStrugglingDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TourStrugglingDialog({
  isOpen,
  onClose,
}: TourStrugglingDialogProps) {
  return (
    <TruvuDialog isOpen={isOpen ?? false} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h1">This is taking a while...</Typography>
        <WarningRoundedIcon
          color="primary"
          sx={{width: '90px', height: '90px'}}
        />
      </Stack>
      <Stack py={3}>
        <Typography>
          This tour seems to be taking longer than usual to load. Please refresh
          to try reload the tour.
        </Typography>
      </Stack>
      <Stack
        flex={1}
        spacing={2}
        justifyContent="flex-end"
        direction="column"
        pb={1}
      >
        <TruvuButton
          variant="secondary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </TruvuButton>
      </Stack>
    </TruvuDialog>
  );
}
