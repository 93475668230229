/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Tours_user = {
    readonly id: string;
    readonly monthlyUploadedTours: number | null;
    readonly monthlyActiveTours: number | null;
    readonly topFiveTours: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly thumbnailURL: string;
        readonly views: number;
        readonly deactivatedViews: number;
    } | null> | null;
    readonly profile: {
        readonly email: string;
    } | null;
    readonly company: {
        readonly invites: ReadonlyArray<{
            readonly accepted: boolean;
            readonly createdAt: unknown;
            readonly email: string;
            readonly expiresAt: unknown;
            readonly user: {
                readonly user: {
                    readonly profile: {
                        readonly email: string;
                        readonly name: string;
                        readonly surname: string;
                    } | null;
                    readonly topFiveTours: ReadonlyArray<{
                        readonly id: string;
                        readonly views: number;
                        readonly " $fragmentRefs": FragmentRefs<"TourMemberCard_tour">;
                    } | null> | null;
                } | null;
            } | null;
        }> | null;
        readonly license: {
            readonly planName: string | null;
            readonly monthlyActiveTours: number | null;
            readonly monthlyUploadedTours: number | null;
            readonly uploadLimit: number | null;
            readonly quantity: number;
        } | null;
    } | null;
    readonly " $refType": "Tours_user";
};
export type Tours_user$data = Tours_user;
export type Tours_user$key = {
    readonly " $data"?: Tours_user$data;
    readonly " $fragmentRefs": FragmentRefs<"Tours_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyUploadedTours",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyActiveTours",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "views",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ToursAlertsRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "Tours_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tour",
      "kind": "LinkedField",
      "name": "topFiveTours",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailURL",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deactivatedViews",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamInvite",
          "kind": "LinkedField",
          "name": "invites",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accepted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiresAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamInviteUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserProfile",
                      "kind": "LinkedField",
                      "name": "profile",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "surname",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Tour",
                      "kind": "LinkedField",
                      "name": "topFiveTours",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "TourMemberCard_tour"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "License",
          "kind": "LinkedField",
          "name": "license",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planName",
              "storageKey": null
            },
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uploadLimit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'd5837ccf15f7a7658d65f3a6e7217a5d';
export default node;
