import ReactGA from 'react-ga4';

export function logViewProgress(progress: number, tourId: string) {
  // console.log('View', tourId, Math.min(Math.round(progress / 10) * 10, 100));

  ReactGA.event({
    category: 'Player',
    action: `ViewProgress:${Math.min(Math.round(progress / 10) * 10, 100)}`,
    label: tourId,
    value: Math.min(Math.round(progress / 10) * 10, 100),
    nonInteraction: true,
    transport: 'xhr',
  });
}
