import React, {useCallback, useState} from 'react';
import {TourDialog} from '../../components/TourDialog';
import {Add, Close} from '@mui/icons-material';
import {TourAddMarkers, TourAddMarkersProps} from './TourAddMarkers';
import {useTruvuDialog} from '../../../../components/dialog/TruvuDialog2';
import {TruvuIconButton} from '../../../../components/button/TruvuIconButton';

interface TourEditAddMarkerMenuProps
  extends Pick<TourAddMarkersProps, 'currentIndex' | 'tourRef'> {
  addMarkersDialog: ReturnType<typeof useTruvuDialog>;
  refetch: () => void;
}

export function TourEditAddMarkerMenu({
  refetch,
  addMarkersDialog,
  tourRef,
  currentIndex,
}: TourEditAddMarkerMenuProps) {
  const [addNewMarker, setAddNewMarker] = useState(false);

  const onAddMarkerCallback = useCallback(() => {
    refetch();
    addMarkersDialog.onClose();
    setAddNewMarker(false);
  }, [addMarkersDialog, refetch]);

  return (
    <TourDialog
      title="Add Marker"
      action={
        <TruvuIconButton
          label={addNewMarker ? 'Cancel' : 'New'}
          onClick={() => setAddNewMarker((prev) => !prev)}
        >
          {addNewMarker ? (
            <Close fontSize="inherit" />
          ) : (
            <Add fontSize="inherit" />
          )}
        </TruvuIconButton>
      }
      {...addMarkersDialog}
    >
      <TourAddMarkers
        addNewMarker={addNewMarker}
        tourRef={tourRef}
        currentIndex={currentIndex}
        onAddMarkerCallback={onAddMarkerCallback}
      />
    </TourDialog>
  );
}
