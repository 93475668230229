import {styled} from '@mui/material';
import Box from '@mui/material/Box';

export const TourCardThumbnail = styled(Box)(({theme}) => ({
  width: '60px',
  height: '60px',
  minWidth: '60px',
  minHeight: '60px',
  maxWidth: '60px',
  maxHeight: '60px',
  borderRadius: '8px',
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    objectFit: 'cover',
    objectPosition: 'center',
    height: '100%',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '120px',
    height: '90px',
    minWidth: '120px',
    minHeight: '90px',
    maxWidth: '120px',
    maxHeight: '90px',
  },
}));
