import React from 'react';
import {Avatar, Stack, StackProps, styled} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TruvuLogoSvg from '../../assets/svg/truvu-logo.svg';
import {AccountCircle} from '@mui/icons-material';
import {useHistory} from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {TruvuToursContainer_user$key} from '../../__generated__/TruvuToursContainer_user.graphql';
import Typography from '@mui/material/Typography';

const StyledContainer = styled(Stack)(() => ({
  minHeight: '100svh',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px 20px',
  maxWidth: '1200px',
}));

const StyledAppBar = styled(Stack)(({theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

const fragment = graphql`
  fragment TruvuToursContainer_user on User {
    profile {
      name
      surname
      profileImageURL
    }
  }
`;
interface TruvuToursContainerProps extends StackProps {
  userRef: TruvuToursContainer_user$key | null | undefined;
}

export function TruvuToursContainer({
  children,
  userRef,
  ...props
}: TruvuToursContainerProps) {
  const {push} = useHistory();

  const onGoToAccount = () => {
    push('/account');
  };

  if (userRef != null) {
    return (
      <TruvuToursContainerWithAvatar
        userRef={userRef}
        onGoToAccount={onGoToAccount}
        {...props}
      >
        {children}
      </TruvuToursContainerWithAvatar>
    );
  }

  return (
    <StyledContainer {...props}>
      <StyledAppBar>
        <Stack direction="row" justifyContent="space-between">
          <IconButton size="large" sx={{p: 0}} onClick={onGoToAccount}>
            <AccountCircle fontSize="large" />
          </IconButton>
        </Stack>
      </StyledAppBar>
      {children}
    </StyledContainer>
  );
}

interface TruvuToursContainerWithAvatarProps extends StackProps {
  userRef: TruvuToursContainer_user$key;
  onGoToAccount: () => void;
}
export function TruvuToursContainerWithAvatar({
  children,
  userRef,
  onGoToAccount,
  ...props
}: TruvuToursContainerWithAvatarProps) {
  const user = useFragment(fragment, userRef);
  const {name = '', surname = ''} = user?.profile || {};
  const initials = `${name
    .trim()[0]
    ?.toUpperCase()}${surname.trim()[0]?.toUpperCase()}`;
  return (
    <StyledContainer {...props}>
      <StyledAppBar>
        <Stack direction="row" justifyContent="space-between">
          <img src={TruvuLogoSvg} alt="Truvu" style={{maxWidth: '70px'}} />
          <IconButton size="large" sx={{p: 0}} onClick={onGoToAccount}>
            {user.profile?.profileImageURL != null &&
            user.profile?.profileImageURL !== '' ? (
              <Avatar
                alt="Remy Sharp"
                src={user.profile.profileImageURL}
                sx={{width: 40, height: 40}}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#dfe2e6',
                  color: '#fff',
                  width: 40,
                  height: 40,
                }}
              >
                <Typography fontSize="1em" fontWeight={700}>
                  {initials}
                </Typography>
              </Avatar>
            )}
          </IconButton>
        </Stack>
      </StyledAppBar>
      {children}
    </StyledContainer>
  );
}
