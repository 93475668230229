import React from 'react';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {UseTruvuDialogResposne} from '../../../components/dialog/TruvuDialog';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {getStripeAccountUrl} from '../../../utils/Variables';

export function SubscriptionCardManageDialog({
  message,
  ...truvuDialog
}: UseTruvuDialogResposne & {message: string}) {
  const stripeAccountUrl = getStripeAccountUrl();
  return (
    <TruvuMessageDialog
      {...truvuDialog}
      variant={'warning'}
      actions={
        <>
          <TruvuButton
            variant="primary"
            onClick={() => {
              if (stripeAccountUrl != null) {
                window.location.href = stripeAccountUrl;
              }
            }}
          >
            Manage Subscription
          </TruvuButton>
          <TruvuButton variant="secondary" onClick={truvuDialog.onClose}>
            Cancel
          </TruvuButton>
        </>
      }
      title={'Manage subscription'}
      message={message}
    />
  );
}
