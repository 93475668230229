/* eslint-disable */
// @ts-nocheck

import {hasWebCodec} from './util';

export async function printIndex(v, idx: number, canvas: HTMLCanvasElement) {
  const w = (canvas.width = v[0].frame.codedWidth);
  const h = (canvas.height = v[0].frame.codedHeight);
  try {
    const context = canvas?.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.canvas.width = w;
    context.canvas.height = h;
    if (hasWebCodec()) {
      const image = await LibAVWebCodecs.createImageBitmap(v[idx].frame);
      context.drawImage(image, 0, 0);
    } else {
      await LibAVWebCodecs.canvasDrawImage(
        context,
        v[idx].frame,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  } catch (e) {
    console.log({e});
  }
}

export function printLogo(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement | undefined,
  percentage: number
) {
  if (!canvas) return;

  const context = canvas.getContext('2d', {alpha: true});
  if (!context) return;

  // Clear the canvas
  context.clearRect(0, 0, canvas.width, canvas.height);

  // Ensure percentage is between 0 and 100
  percentage = Math.max(0, Math.min(100, percentage));

  // Define maximum and minimum sizes
  const maxSize = 200;
  const minSize = maxSize / 3; // Approximately 66.67px

  // Calculate the scale factors
  const maxScale = maxSize / image.width;
  const minScale = minSize / image.width;

  // Calculate dimensions for maximum and minimum sizes
  const newWidthMax = image.width * maxScale;
  const newHeightMax = image.height * maxScale;

  const newWidthMin = image.width * minScale;
  const newHeightMin = image.height * minScale;

  // Positions for center and bottom-right placement
  const centerX = (canvas.width - newWidthMax) / 2;
  const centerY = (canvas.height - newHeightMax) / 2;

  const bottomRightX = canvas.width - newWidthMin - 10; // 10px padding
  const bottomRightY = canvas.height - newHeightMin - 10;

  let scale: number, newWidth: number, newHeight: number, x: number, y: number;

  if (percentage <= 2 || percentage >= 98) {
    // Show large logo in center
    scale = maxScale;
    newWidth = newWidthMax;
    newHeight = newHeightMax;
    x = centerX;
    y = centerY;
  } else if (percentage > 2 && percentage <= 5) {
    // Transition from large center to small bottom right
    const t = (percentage - 2) / 3; // Normalized time between 0 and 1
    scale = maxScale * (1 - t) + minScale * t;
    newWidth = image.width * scale;
    newHeight = image.height * scale;
    x = centerX * (1 - t) + bottomRightX * t;
    y = centerY * (1 - t) + bottomRightY * t;
  } else if (percentage > 5 && percentage < 95) {
    // Show small logo in bottom right
    scale = minScale;
    newWidth = newWidthMin;
    newHeight = newHeightMin;
    x = bottomRightX;
    y = bottomRightY;
  } else if (percentage >= 95 && percentage < 98) {
    // Transition from small bottom right to large center
    const t = (percentage - 95) / 3; // Normalized time between 0 and 1
    scale = minScale * (1 - t) + maxScale * t;
    newWidth = image.width * scale;
    newHeight = image.height * scale;
    x = bottomRightX * (1 - t) + centerX * t;
    y = bottomRightY * (1 - t) + centerY * t;
  } else {
    // Default to large logo in center
    scale = maxScale;
    newWidth = newWidthMax;
    newHeight = newHeightMax;
    x = centerX;
    y = centerY;
  }

  // Draw the image onto the canvas
  context.drawImage(image, x, y, newWidth, newHeight);
}
