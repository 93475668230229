/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ActiveMemberTourCard_tour = {
    readonly id: string;
    readonly title: string;
    readonly views: number;
    readonly description: string;
    readonly thumbnailURL: string;
    readonly " $refType": "ActiveMemberTourCard_tour";
};
export type ActiveMemberTourCard_tour$data = ActiveMemberTourCard_tour;
export type ActiveMemberTourCard_tour$key = {
    readonly " $data"?: ActiveMemberTourCard_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"ActiveMemberTourCard_tour">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveMemberTourCard_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
(node as any).hash = '091e9504964597d35cc431bc91f51c11';
export default node;
