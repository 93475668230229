const request = async ({
  url,
  headers,
  json,
}: {
  url: string;
  headers: Headers;
  json: Record<string, unknown>;
}) => {
  const response = await fetch(url, {
    headers,
    body: JSON.stringify(json),
    method: 'POST',
  });

  const data = await response.json();

  if (response.status === 401) {
    throw new Error(data.message ?? 'Authentication Failed');
  } else if (!response.ok) {
    throw new Error(data.error || data.message || data);
  }

  return data;
};
export class LoginService {
  serverUri: string;
  base64Encode: (data: string) => string;

  constructor(serverUri: string, base64Encode?: (data: string) => string) {
    this.serverUri = serverUri;
    this.base64Encode = base64Encode || ((data: string) => window.btoa(data));
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async login(username: string, password: string): Promise<any> {
    // Implement login logic here
    const headers = new Headers();
    const credentials = this.base64Encode(username + ':' + password);
    const json = {
      username: username,
      password: password,
      grant_type: 'password',
    };
    headers.append('Authorization', 'Basic ' + credentials);
    headers.append('Content-Type', 'application/json');
    const url = this.serverUri + '/oauth/token';
    return request({
      url: url,
      headers: headers,
      json: json,
    });
  }
}
