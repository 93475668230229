import React from 'react';
import {Stack, Box} from '@mui/material';
import CompanyLogoPlaceholder from '../../../assets/svg/companylogo-placeholder.svg';

interface CompanyLogoProps {
  company: {
    logoimageurl?: string;
    name?: string;
    backgroundImageUrl?: string; // New prop for the background image
  };
}

const CompanyTourLogo: React.FC<CompanyLogoProps> = ({company}) => {
  return (
    <Stack
      component="div"
      sx={{
        backgroundColor: '#EDEFF2',
        borderRadius: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '152px',
        position: 'relative',
        overflow: 'hidden', // Ensure the blurred background doesn't overflow
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url('/background-house.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(2px)',
          transform: 'scale(1.1)',
          opacity: 0.9,
        }}
      />
      <Box
        sx={{
          position: 'relative', // Ensure the logo appears above the blurred background
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={company.logoimageurl || CompanyLogoPlaceholder}
          alt={company.name || 'Company Logo'}
          style={{
            maxWidth: '180px',
            maxHeight: '180px',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Stack>
  );
};

export default CompanyTourLogo;
