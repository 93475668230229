import {FrameType} from '../types';
import {hasWebCodec} from './hasWebCodec';

function clearVideoFrames(
  store: Array<{
    frameInClusterId: number;
    frame: FrameType;
    clusterCount: number;
  }>
) {
  if (store.length > 0) {
    if (hasWebCodec()) {
      window.scrollTo({top: 0});

      // eslint-disable-next-line
      // @ts-ignore
      store.forEach(({frame}) => frame.close());
    }
  }
}

export async function terminateExistingWorker(
  worker: Worker | null,
  store: Array<{
    frameInClusterId: number;
    frame: FrameType;
    clusterCount: number;
  }>
) {
  if (worker) {
    await worker.terminate();
  }
  // TODO if we clear frames here, then we need to move the current scroll position cos it is not displaying anything then
  clearVideoFrames(store);
}
