import React from 'react';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {UseTruvuDialogResposne} from '../../../components/dialog/TruvuDialog';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {getStripeAccountUrl} from '../../../utils/Variables';

export function SubscriptionCardErrorDialog({
  message,
  ...truvuDialog
}: UseTruvuDialogResposne & {message: string}) {
  const stripeAccountUrl = getStripeAccountUrl();
  return (
    <TruvuMessageDialog
      {...truvuDialog}
      variant={'error'}
      actions={
        <>
          <TruvuButton
            variant="primary"
            onClick={() =>
              stripeAccountUrl ? window.location.replace(stripeAccountUrl) : {}
            }
          >
            Manage Subscription
          </TruvuButton>
          <TruvuButton variant="secondary" onClick={truvuDialog.onClose}>
            Cancel
          </TruvuButton>
        </>
      }
      title={'Failed to activate plan'}
      message={message}
    />
  );
}
