import {Stack} from '@mui/material';
import {TourPlayerCanvas} from './TourPlayerCanvas';
import {TourFramer} from './TourFramer';
import React from 'react';
import {FrameStoreBuffer} from '../types';
import {styled} from '@mui/material/styles';
import {addShouldNotForwardProps} from '../../../utils/addShouldNotForwardProps';
import {TOUR_DRAWER_HEIGHT, TOUR_DRAWER_WIDTH} from './TourDrawer';
import {TourPlayerCanvas_tour$key} from '../../../__generated__/TourPlayerCanvas_tour.graphql';
import {useSearchParam} from 'react-use';

interface TourPlayerProps {
  tourRef: TourPlayerCanvas_tour$key;
  currentIndex: number;
  displayController: boolean;
  drawerIsOpen: boolean;
  maxIndex: number;
  fastTravellingStatus: 'idle' | 'forward' | 'backward';
  drawerRef: React.RefObject<HTMLDivElement>;
  getCanvasDimensions: (width: number, height: number) => void;
  tourCanvasRef: React.RefObject<HTMLCanvasElement>;
  setBuffer: React.Dispatch<React.SetStateAction<FrameStoreBuffer>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const TourPlayer = React.memo(function TourPlayer({
  tourRef,
  currentIndex,
  displayController,
  drawerIsOpen,
  drawerRef,
  fastTravellingStatus,
  getCanvasDimensions,
  tourCanvasRef,
  maxIndex,
  setBuffer,
  setCurrentIndex,
}: TourPlayerProps) {
  const disabledControls = useSearchParam('disabledControls');
  const hiRes = !!useSearchParam('hiRes');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');

  return (
    <PlayerContainer>
      <PlayerCanvasContainer
        $drawerIsOpen={drawerIsOpen}
        $drawerWidth={
          splitDisableControls?.includes('markers')
            ? 0
            : parseInt(TOUR_DRAWER_WIDTH) / 2
        }
        $drawerHeight={
          splitDisableControls?.includes('markers')
            ? 0
            : drawerRef.current?.clientHeight ?? parseInt(TOUR_DRAWER_HEIGHT)
        }
      >
        <TourPlayerCanvas
          tourCanvasRef={tourCanvasRef}
          tourRef={tourRef}
          currentIndex={currentIndex}
          setBuffer={setBuffer}
          getCanvasDimensions={getCanvasDimensions}
          hiRes={hiRes}
        />
      </PlayerCanvasContainer>
      {displayController && (
        <TourFramer
          maxIndex={maxIndex}
          fastTravellingStatus={fastTravellingStatus}
          setCurrentIndex={setCurrentIndex}
          drawerRef={drawerRef}
          drawerIsOpen={drawerIsOpen}
        />
      )}
    </PlayerContainer>
  );
});

const PlayerContainer = styled(Stack)(() => ({
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '100svw',
  width: '100svw',
  maxWidth: '100svw',
  minHeight: '100svh',
  height: '100svh',
  maxHeight: '100svh',
}));

const PlayerCanvasContainer = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps(
    '$drawerIsOpen',
    '$drawerHeight',
    '$drawerWidth'
  ),
})<{$drawerIsOpen: boolean; $drawerHeight: number; $drawerWidth: number}>(
  ({theme, $drawerIsOpen, $drawerWidth, $drawerHeight}) => ({
    position: 'relative',
    overflow: 'visible',
    marginBottom: $drawerIsOpen ? $drawerHeight : 0,
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp,
    }),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      transform: `translateX(-${$drawerIsOpen ? `${$drawerWidth}px` : 0})`,
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.sharp,
      }),
    },
  })
);
