/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type InviteMemberInput = {
    companyId: string;
    email: string;
};
export type AddMemberMutationVariables = {
    input: InviteMemberInput;
};
export type AddMemberMutationResponse = {
    readonly inviteMember: {
        readonly teamInvite: {
            readonly id: string;
            readonly expiresAt: unknown;
            readonly accepted: boolean;
            readonly user: {
                readonly user: {
                    readonly id: string;
                    readonly monthlyUploadedTours: number | null;
                    readonly monthlyActiveTours: number | null;
                    readonly topFiveTours: ReadonlyArray<{
                        readonly id: string;
                        readonly title: string;
                        readonly thumbnailURL: string;
                        readonly views: number;
                        readonly deactivatedViews: number;
                    } | null> | null;
                } | null;
            } | null;
        };
    };
};
export type AddMemberMutation = {
    readonly response: AddMemberMutationResponse;
    readonly variables: AddMemberMutationVariables;
};



/*
mutation AddMemberMutation(
  $input: InviteMemberInput!
) {
  inviteMember(input: $input) {
    teamInvite {
      id
      expiresAt
      accepted
      user {
        user {
          id
          monthlyUploadedTours
          monthlyActiveTours
          topFiveTours {
            id
            title
            thumbnailURL
            views
            deactivatedViews
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InviteMemberPayload",
    "kind": "LinkedField",
    "name": "inviteMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamInvite",
        "kind": "LinkedField",
        "name": "teamInvite",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accepted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamInviteUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyUploadedTours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyActiveTours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tour",
                    "kind": "LinkedField",
                    "name": "topFiveTours",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "views",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deactivatedViews",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddMemberMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddMemberMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f62e5b21b350e6565b6768f12a233e3f",
    "id": null,
    "metadata": {},
    "name": "AddMemberMutation",
    "operationKind": "mutation",
    "text": "mutation AddMemberMutation(\n  $input: InviteMemberInput!\n) {\n  inviteMember(input: $input) {\n    teamInvite {\n      id\n      expiresAt\n      accepted\n      user {\n        user {\n          id\n          monthlyUploadedTours\n          monthlyActiveTours\n          topFiveTours {\n            id\n            title\n            thumbnailURL\n            views\n            deactivatedViews\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd52b7f18839949627f8a00fcfcd06f78';
export default node;
