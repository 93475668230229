import {TruvuButton} from '../../../components/button/TruvuButton';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'react-relay-mutation';
import React, {useState} from 'react';

const UserResendActivationMutation = graphql`
  mutation ResendEmailActivationMutation($email: String!) {
    userActivationEmailResend(input: {email: $email}) {
      success
    }
  }
`;

export function ResendEmailActivation({email}: {email: string}) {
  const [commit] = useMutation(UserResendActivationMutation);
  const [activationResendStatus, setActivationResendStatus] = useState<
    'idle' | 'sending' | 'success' | 'error'
  >('idle');

  let buttonText = 'Re-send activation email';

  if (activationResendStatus === 'sending') {
    buttonText = 'Sending activation email...';
  }

  if (activationResendStatus === 'success') {
    buttonText = 'Activation email sent';
  }

  if (activationResendStatus === 'error') {
    buttonText = 'Failed to send activation email';
  }

  return (
    <TruvuButton
      variant="danger"
      loading={activationResendStatus === 'sending'}
      onClick={() => {
        setActivationResendStatus('sending');
        commit({
          variables: {email},
          onCompleted: () => {
            setActivationResendStatus('success');
          },
          onError: () => {
            setActivationResendStatus('error');
          },
        });
      }}
    >
      {buttonText}
    </TruvuButton>
  );
}
