import React from 'react';
import {Fab, MobileStepper, Theme} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useUploadTipsStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      backgroundColor: '#D9DEE2',
    },
    activeDot: {
      backgroundColor: theme.palette.primary.main,
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      // [theme.breakpoints.down('md')]: {
      //   justifyContent: 'center',
      // },
    },
  })
);

interface UploadTipsStepperProps {
  onNext: () => void;
  onPrev: () => void;
  activeTip: number;
  total: number;
}

export function UploadTipsStepper({
  activeTip,
  onNext,
  onPrev,
  total,
}: UploadTipsStepperProps) {
  const classes = useUploadTipsStepperStyles();

  return (
    <MobileStepper
      variant="dots"
      steps={total}
      position="static"
      activeStep={activeTip}
      classes={{
        root: classes.root,
        dotActive: classes.activeDot,
        dot: classes.dot,
      }}
      nextButton={
        <Fab size="medium" onClick={onNext} disabled={activeTip === total - 1}>
          <KeyboardArrowRight />
        </Fab>
      }
      backButton={
        <Fab size="medium" onClick={onPrev} disabled={activeTip === 0}>
          <KeyboardArrowLeft />
        </Fab>
      }
    />
  );
}
