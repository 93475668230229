import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import GoogleLoginButton from '../../components/button/GoogleLoginButton';
import {ConfigService} from '../../utils';
import Turnstile from 'react-turnstile';
import {GoogleAcceptTermsField} from './GoogleRedirectAcceptTerms';
import {useAuthSearchParams} from '../../hooks/useAuthSearchParams';

export function GoogleRedirect() {
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {gToken, inviteToken, referredBy} = useAuthSearchParams();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (acceptedTerms && turnstileToken != null) {
      buttonRef.current?.click();
    }
  }, [acceptedTerms, turnstileToken]);

  return (
    <>
      <GoogleAcceptTermsField
        acceptedTerms={acceptedTerms}
        errorMessage={errorMessage}
        onAcceptTerms={() => {
          setAcceptedTerms(true);
          setErrorMessage(undefined);
        }}
      />
      <TurnstileWidget setTurnstileToken={setTurnstileToken} />
      <GoogleLoginButton
        gToken={gToken}
        customState={{referredBy, inviteToken}}
        buttonRef={buttonRef}
      />
    </>
  );
}

const TurnstileWidget = ({
  setTurnstileToken,
}: {
  setTurnstileToken: Dispatch<SetStateAction<string | null>>;
}) => {
  const handleTurnstileSuccess = (token: string) => {
    setTurnstileToken(token); // Set the token in Formik state
  };

  useEffect(() => {
    if (ConfigService.isDev) {
      // In development mode, set a dummy token after a delay
      const timer = setTimeout(() => {
        setTurnstileToken('dev-token');
      }, 500);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [setTurnstileToken]);

  if (ConfigService.isDev) {
    return (
      <div>
        <p>Turnstile verification is disabled in development mode.</p>
      </div>
    );
  }

  return (
    <Turnstile
      sitekey="0x4AAAAAAAcmLl7I0h-o2cwh"
      onVerify={handleTurnstileSuccess}
      theme={'light'}
    />
  );
};
