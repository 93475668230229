import React from 'react';
import {RWebShare} from 'react-web-share';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {TourMenu} from '../../components/TourMenu';

interface TourPreviewMenuProps {
  external: boolean;
  drawerIsOpen: boolean;
  title: string;
  tourId: string;
}

export function TourPreviewMenu({
  drawerIsOpen,
  external,
  title,
  tourId,
}: TourPreviewMenuProps) {
  const shareUrl = `${window.location.protocol}//${window.location.host}/playerext?tourId=${tourId}`;

  return (
    <TourMenu drawerIsOpen={drawerIsOpen} mode={!external ? 'Preview' : null}>
      <RWebShare
        data={{
          text: `Check out this Truvu Walkthrough: ${title}`,
          title: 'Share this Truvu Walkthrough',
          url: shareUrl,
        }}
        disableNative
      >
        <TruvuButton variant="primary">Share</TruvuButton>
      </RWebShare>
      {!external && (
        <>
          <TruvuButton to={`/editplayer?tourId=${tourId}`} variant="secondary">
            Edit Tour
          </TruvuButton>
        </>
      )}
      <TruvuButton to="/" variant="danger">
        {external ? 'Exit Tour' : 'Exit Preview'}
      </TruvuButton>
    </TourMenu>
  );
}
