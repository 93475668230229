import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {TruvuTextField} from '../../../components/textField';
import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {ProfileAccountDetails_user$key} from '../../../__generated__/ProfileAccountDetails_user.graphql';
import {TruvuDialog} from '../../../components/dialog';
import {ArrowBack} from '@mui/icons-material';
import {UploadProfile} from './UploadProfile';
import {ProfileEditDetails} from './ProfileEditDetails';

const fragment = graphql`
  fragment ProfileAccountDetails_user on User {
    ...ProfileEditDetails_user
    id
    profile {
      id
      name
      surname
      company
      email
      contactNumber
      profileImageURL
    }
  }
`;

interface ProfileAccountDetailsProps {
  userRef: ProfileAccountDetails_user$key;
  refetch: () => void;
  openEditDialog: boolean;
  onCloseEditDialog: () => void;
  onOpenEditDialog: () => void;
}

export function ProfileAccountDetails({
  userRef,
  refetch,
  openEditDialog,
  onCloseEditDialog,
  onOpenEditDialog,
}: ProfileAccountDetailsProps) {
  const user = useFragment(fragment, userRef);
  const [newProfile, setNewProfile] = React.useState<File | null>(null);

  return (
    <>
      <TruvuDialog
        open={openEditDialog}
        onClose={onCloseEditDialog}
        title="Edit your profile"
        closeIcon={<ArrowBack />}
        headingAction={
          <UploadProfile
            src={
              newProfile
                ? URL.createObjectURL(newProfile)
                : user.profile?.profileImageURL ?? ''
            }
            setNewProfile={setNewProfile}
          />
        }
        contentScrollable
      >
        <ProfileEditDetails
          userRef={user}
          onCompleted={() => {
            refetch();
            onCloseEditDialog();
          }}
          onCancel={onCloseEditDialog}
          newProfile={newProfile}
        />
      </TruvuDialog>
      <Stack py={2} spacing={1.5}>
        <Stack spacing={1.5} direction="row" justifyContent={'space-between'}>
          <Typography variant="h2">Account Details</Typography>
          <TruvuButton
            variant="textPrimary"
            size="small"
            onClick={onOpenEditDialog}
            disableAdornments
          >
            Edit
          </TruvuButton>
        </Stack>
        <TruvuTextField
          name="name"
          label="Name"
          value={`${user.profile?.name ?? '-'} ${user.profile?.surname ?? '-'}`}
          displayOnly
        />
        <TruvuTextField
          name="email"
          label="E-mail"
          value={user.profile?.email}
          displayOnly
        />
        <TruvuTextField
          name="contactNumber"
          label="Contact Number"
          value={
            user.profile?.contactNumber
              ? user.profile?.contactNumber
              : '+00-000-000-0000'
          }
          displayOnly
        />
      </Stack>
    </>
  );
}
