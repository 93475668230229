export const getMetaTagContent = (name: string): string | null => {
  const meta = document.querySelector(
    `meta[property="${name}"]`
  ) as HTMLMetaElement;
  return meta ? meta.content : null;
};

export const getStripeAccountUrl = (): string | null => {
  return getMetaTagContent('app:stripe-account-url');
};
