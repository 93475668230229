import {LoadingSpinner} from '../../../assets/LoadingSpinner';
import Typography from '@mui/material/Typography';
import React from 'react';
import {styled} from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

const ClickableTourCard = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
  gap: '10px',
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('md')]: {gap: '18px', padding: '18px'},
}));

interface TourLoadMoreCardProps {
  isLoadingNext: boolean;
  loadNext: (count: number) => void;
}

export function TourLoadMoreCard({
  isLoadingNext,
  loadNext,
}: TourLoadMoreCardProps) {
  return (
    <ClickableTourCard
      disabled={isLoadingNext}
      onClick={() => loadNext(10)}
      sx={{height: '100%'}}
      style={{backgroundColor: '#fdfdfd'}}
    >
      {isLoadingNext ? (
        <>
          <LoadingSpinner width={'60px'} height={'60px'} />
          <Typography flex={1} textAlign={'left'} variant="h6">
            Loading more...
          </Typography>
        </>
      ) : (
        <Typography flex={1} textAlign="left" variant="h6">
          Load more tours...
        </Typography>
      )}
    </ClickableTourCard>
  );
}
