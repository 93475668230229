import React from 'react';
import {useHistory} from 'react-router';

import {TruvuTermContainer} from '../components/containers/TruvuTermContainer';
import {LicensePolicies} from '../modules/license/components/LicensePolicies';
import './termsPage.css';

export function LicensePage() {
  const {goBack} = useHistory();

  return (
    <TruvuTermContainer onGoBack={goBack}>
      <LicensePolicies />
    </TruvuTermContainer>
  );
}
