import React from 'react';
import {TruvuButtonBase, TruvuButtonBaseProps} from './TruvuButtonBase';
import {TruvuButtonFormik} from './TruvuButtonFormik';

export type TruvuButtonProps = TruvuButtonBaseProps & {
  formikSubmit?: boolean;
};

export const TruvuButton = React.forwardRef<
  HTMLButtonElement,
  TruvuButtonProps
>(function TruvuButton({formikSubmit = false, ...props}, ref) {
  if (formikSubmit) {
    return <TruvuButtonFormik {...props} />;
  }
  return <TruvuButtonBase {...props} ref={ref} />;
});
