import React from 'react';
import {LoginForm} from '../modules/login/components/LoginForm';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import {Stack, Typography} from '@mui/material';
import {useHistory} from 'react-router';
import {TruvuMenu} from '../components/TruvuMenu';
import {Link} from '../components/typography/Link';

export function LoginPage() {
  const {push} = useHistory();

  const goToSignUp = () => {
    push('/signup');
  };

  return (
    <TruvuContainer skinny>
      <TruvuMenu my="20px" title="Log in" />
      <Stack>
        <LoginForm />
        <Typography
          component="label"
          color={'text.secondary'}
          variant="body2"
          htmlFor="goToSignup"
          mb={1}
          textAlign="center"
          mt={2}
        >
          Don&apos;t have an account?{' '}
          <Typography
            sx={{cursor: 'pointer', textDecoration: 'underline'}}
            component={'strong'}
            color={'primary'}
            onClick={goToSignUp}
          >
            Sign up here.
          </Typography>
        </Typography>
        <Link textAlign="center" mt={2} sx={{}} to="/password/reset/request">
          Forgot password?
        </Link>
      </Stack>
    </TruvuContainer>
  );
}
