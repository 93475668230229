import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {SnackbarContext} from '../context/SnackbarContext';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {SnackbarContent} from '@mui/material';
import clsx from 'clsx';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {amber} from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  borderRadius: {
    borderRadius: theme.spacing(4, 4, 4, 4),
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export default function CusotmSnackbar() {
  const classes = useStyles();
  const {snack, dismiss} = React.useContext(SnackbarContext);
  if (!snack || !snack.variant) {
    return null;
  }

  const Icon = variantIcon[snack.variant];

  function handleClose(event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    dismiss();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: ['left'].includes(snack?.position ?? '')
          ? 'left'
          : ['right'].includes(snack?.position ?? '')
          ? 'right'
          : ['center'].includes(snack?.position ?? '')
          ? 'center'
          : 'right',
      }}
      open={true}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(
          classes[snack.variant],
          classes.margin,
          classes.borderRadius
        )}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {snack.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={dismiss}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
