import React, {useState} from 'react';
import {Stack, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {TruvuTextField} from '../../../components/textField';
import graphql from 'babel-plugin-relay/macro';
import {useFragment, useMutation} from 'relay-hooks';
import {ProfilePlanDetails_user$key} from '../../../__generated__/ProfilePlanDetails_user.graphql';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {TruvuTooltip} from '../../../components/tooltip/TruvuTooltip';
import ButtonBase from '@mui/material/ButtonBase';
import {useHistory} from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import {ProfilePlanDetailsBillingPortalSessionMutation} from '../../../__generated__/ProfilePlanDetailsBillingPortalSessionMutation.graphql';

const fragment = graphql`
  fragment ProfilePlanDetails_user on User {
    monthlyActiveTours
    monthlyUploadedTours
    company {
      name
      license {
        stripeSubscriptionId
        monthlyActiveTours
        monthlyUploadedTours
        planName
        quantity
        uploadLimit
        startDate
        endDate
      }
    }
  }
`;

const billingPortalSessionMutation = graphql`
  mutation ProfilePlanDetailsBillingPortalSessionMutation(
    $input: BillingPortalSessionInput!
  ) {
    billingPortalSession(input: $input) {
      url
      error
    }
  }
`;

interface ProfilePlanDetailsProps {
  userRef: ProfilePlanDetails_user$key;
}

export function ProfilePlanDetails({userRef}: ProfilePlanDetailsProps) {
  const {push} = useHistory();
  const {company} = useFragment(fragment, userRef);
  const [isLoading, setIsLoading] = useState(false);

  const [
    getBillingPortalSession,
  ] = useMutation<ProfilePlanDetailsBillingPortalSessionMutation>(
    billingPortalSessionMutation
  );

  const handleManageClick = () => {
    setIsLoading(true);
    getBillingPortalSession({
      variables: {
        input: {
          returnStub: 'account/subscriptions',
        },
      },
      onCompleted: (response) => {
        setIsLoading(false);
        if (response.billingPortalSession.url) {
          window.location.replace(response.billingPortalSession.url);
        } else if (response.billingPortalSession.error) {
          console.error(
            'Error getting billing portal URL:',
            response.billingPortalSession.error
          );
          // You might want to show an error message to the user here
        }
      },
      onError: (error) => {
        setIsLoading(false);
        console.error('Error executing billingPortalSession mutation:', error);
        // You might want to show an error message to the user here
      },
    });
  };

  if (company?.license == null) {
    return null;
  }

  return (
    <Stack py={2} spacing={1.5}>
      <Stack spacing={1.5} direction="row" justifyContent={'space-between'}>
        <Typography variant="h2">My Plan</Typography>
        {company?.license && company?.license.planName !== 'Free Trial' && (
          <TruvuButton
            variant="textPrimary"
            size="small"
            onClick={handleManageClick}
            disableAdornments
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Manage'}
          </TruvuButton>
        )}
      </Stack>
      <ClickableField
        onClick={() => {
          push('/account/subscriptions');
        }}
      >
        <TruvuTextField
          name="planName"
          label={`Plan Name`}
          value={company?.license?.planName}
          sx={{cursor: 'pointer'}}
          displayOnly
        />
      </ClickableField>
      <TruvuTextField
        name="monthlyActiveTours"
        label="Monthly Active Tours"
        value={`${company?.license?.monthlyActiveTours ?? '-'} of ${
          company?.license?.quantity ?? '-'
        }`}
        endIcon={
          <TruvuTooltip
            title="This gives you a view of how many active tours you have used within your plan limit. As you delete tours, more slots become available within your plan limit."
            arrow
          >
            <InfoOutlined color={'primary'} />
          </TruvuTooltip>
        }
        displayOnly
      />
      <TruvuTextField
        name="monthlyUploadedTours"
        label="Monthly Upload Attempts"
        value={`${company?.license?.monthlyUploadedTours ?? '-'} of ${
          company?.license?.uploadLimit ?? '-'
        }`}
        endIcon={
          <TruvuTooltip
            title="Here you can keep track of how many tour/video upload attempts you have left each month in line with your plan. Deleting a tour will not affect this."
            arrow
          >
            <InfoOutlined color={'primary'} />
          </TruvuTooltip>
        }
        displayOnly
      />
    </Stack>
  );
}

const ClickableField = styled(ButtonBase)({
  borderRadius: '15px',
  '& > div': {
    flex: 1,
    alignItems: 'flex-start',
  },
});
