import React from 'react';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

const StyledTipCard = styled(Box)(({theme}) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  // padding: theme.spacing(2.5),
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'column',
}));

export interface UploadTipCardProps {
  alt: string;
  lottieJson: Record<string, unknown>;
  title: string;
  description: string;
}

export function UploadTipCard({
  lottieJson,
  title,
  description,
}: UploadTipCardProps) {
  const [mouseDown, setMouseDown] = React.useState<boolean>(false);

  const onMouseDown = () => {
    setMouseDown(true);
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  return (
    <StyledTipCard
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      sx={{cursor: mouseDown ? 'grabbing' : 'grab'}}
    >
      <Lottie
        animationData={lottieJson}
        play
        loop
        style={{width: '100%', marginTop: '-40px', marginBottom: '-20px'}}
      />
      <Stack spacing={1} flex={1} px={2} pb={2}>
        <Typography variant="h3" color="text.primary" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </StyledTipCard>
  );
}
