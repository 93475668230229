import {Stack, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useFormikContext} from 'formik';
import ButtonBase from '@mui/material/ButtonBase';
import React, {useState} from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBoxRounded';
import Dialog from '@mui/material/Dialog';
import {Terms} from '../../terms/components/Terms';
import {TruvuTermContainer} from '../../../components/containers/TruvuTermContainer';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';

export function AcceptTermsField() {
  const {
    getFieldHelpers,
    getFieldMeta,
    isValid,
    submitCount,
  } = useFormikContext();
  const {value, error, touched} = getFieldMeta<boolean>('acceptedTerms');
  const {setValue} = getFieldHelpers<boolean>('acceptedTerms');
  const [dialogOpen, setDialogOpen] = useState(false);

  const errorMessage =
    !isValid && (submitCount > 0 || touched) && error ? error : undefined;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckBoxContainer
          onClick={() => {
            setValue(!value);
          }}
        >
          <CheckBoxIcon
            color="primary"
            style={{visibility: value ? 'visible' : 'hidden'}}
            fontSize="large"
          />
        </CheckBoxContainer>
        <Stack direction="row" spacing={0.5}>
          <Typography color="text.secondary">Accept</Typography>
          <Typography
            onClick={() => {
              setDialogOpen(true);
            }}
            sx={{cursor: 'pointer'}}
            color="primary"
          >
            Terms & Conditions
          </Typography>
        </Stack>
      </Stack>
      {errorMessage != null && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        TransitionComponent={DialogTransition}
        PaperProps={{sx: {bgcolor: 'background.default'}}}
        fullScreen
      >
        <TruvuTermContainer onGoBack={() => setDialogOpen(false)}>
          <Terms />
        </TruvuTermContainer>
      </Dialog>
    </Stack>
  );
}

const CheckBoxContainer = styled(ButtonBase)(() => ({
  display: 'grid',
  placeContent: 'center',
  backgroundColor: '#EDEFF2',
  color: '#191C1F',
  padding: '2px',
  borderRadius: '8px',
}));

const DialogTransition = React.forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});
