import * as React from 'react';
import {Box} from '@mui/material';

import PropertyCo from '../assets/svg/PropertyCo.svg';
import {Theme, useMediaQuery} from '@mui/material';

function EmbeddedExamplePage() {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('lg'));

  const tourID = window.location.href.split('/').pop();

  const embedLink =
    tourID && tourID !== 'embedded_example'
      ? `http://localhost:3000/playerext?tourId=${tourID}`
      : `https://${window.location.host}/playerext?tourId=VG91cjo3Mg==`;

  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={'15vh'}
          >
            <Box>
              <img
                alt=";"
                src={PropertyCo}
                style={{width: '20vh', height: '10vh'}}
              />
            </Box>
            <div>
              <iframe
                src={embedLink}
                data-cy={'truvu-embedded'}
                title="Index Iframe "
                width="500vh"
                height="250vh"
                allowFullScreen
                style={{
                  overflow: 'hidden',
                }}
              ></iframe>
            </div>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={'15vh'}
          >
            <Box>
              <img
                alt=";"
                src={PropertyCo}
                style={{width: '20vh', height: '10vh'}}
              />
            </Box>
            <div>
              <iframe
                src={embedLink}
                data-cy={'truvu-embedded'}
                title="Index Iframe "
                width="1000vh"
                height="550vh"
                allowFullScreen
                style={{
                  overflow: 'hidden',
                }}
              ></iframe>
            </div>
          </Box>
        </div>
      )}
    </>
  );
}
export default EmbeddedExamplePage;
