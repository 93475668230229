import React from 'react';
import {useHistory} from 'react-router';

import {TruvuTermContainer} from '../components/containers/TruvuTermContainer';
import {PrivacyPolicies} from '../modules/privacy/components/PrivacyPolicies';
import './termsPage.css';

export function PrivacyPage() {
  const {goBack} = useHistory();

  return (
    <TruvuTermContainer onGoBack={goBack}>
      <PrivacyPolicies />
    </TruvuTermContainer>
  );
}
