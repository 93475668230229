import React, {createContext, useCallback, useContext, useState} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

export type TutorialContextType = {
  firstView: boolean;
  available: boolean;
};

export interface TutorialContextStateType {
  tutorial: TutorialContextType;
  handleResetTutorial: () => void;
  handleCompleteTutorial: () => void;
  handleTutorialVideo: () => void;
}

export const TutorialContext = createContext<TutorialContextStateType>({
  tutorial: {firstView: true, available: true},
  // eslint-disable-next-line
  handleResetTutorial: () => {},
  // eslint-disable-next-line
  handleCompleteTutorial: () => {},
  // eslint-disable-next-line
  handleTutorialVideo: () => {},
});

interface Props {
  children: JSX.Element;
}

export function TutorialContextProvider({children}: Props) {
  const [
    tutorialState,
    setTutorialState,
  ] = useLocalStorage<TutorialContextType>('tutorial', {
    firstView: true,
    available: true,
  });

  const [tutorial, setTutorial] = useState<TutorialContextType>(() => {
    return tutorialState ?? {firstView: true, available: true};
  });

  const handleResetTutorial = useCallback(() => {
    // TODO navigate to home.
    setTutorial({firstView: true, available: true});
    setTutorialState({firstView: true, available: true});
  }, [setTutorialState, setTutorial]);

  const handleCompleteTutorial = useCallback(() => {
    setTutorial({firstView: false, available: true});
    setTutorialState({firstView: false, available: true});
  }, [setTutorialState, setTutorial]);

  const handleTutorialVideo = useCallback(() => {
    setTutorial({firstView: tutorial.firstView, available: false});
    setTutorialState({firstView: tutorial.firstView, available: false});
  }, [setTutorialState, setTutorial, tutorial.firstView]);

  const value = React.useMemo(
    () => ({
      tutorial,
      handleResetTutorial,
      handleCompleteTutorial,
      handleTutorialVideo,
    }),
    [tutorial, handleResetTutorial, handleCompleteTutorial, handleTutorialVideo]
  );

  return (
    <TutorialContext.Provider value={value}>
      {children}
    </TutorialContext.Provider>
  );
}

export default TutorialContextProvider;
export const useTutorialContext = () => useContext(TutorialContext);
