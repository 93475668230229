/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileCompanyDetails_user = {
    readonly id: string;
    readonly name: string;
    readonly countrycode: string;
    readonly referredby: string;
    readonly address: string;
    readonly vatno: string;
    readonly logoimageurl: string;
    readonly " $fragmentRefs": FragmentRefs<"ProfileEditCompanyDetails_user">;
    readonly " $refType": "ProfileCompanyDetails_user";
};
export type ProfileCompanyDetails_user$data = ProfileCompanyDetails_user;
export type ProfileCompanyDetails_user$key = {
    readonly " $data"?: ProfileCompanyDetails_user$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileCompanyDetails_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCompanyDetails_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countrycode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referredby",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatno",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoimageurl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileEditCompanyDetails_user"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
(node as any).hash = '67a1deef19c27a979653e038a1d86339';
export default node;
