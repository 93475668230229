import {Term} from '../../terms/components/TermsComponent';

export const serviceProviders: Term[] = [
  {
    id: '13.1',
    term:
      'We may employ third-party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services, or to assist us in analysing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.',
  },
  {
    id: '13.2',
    term:
      'Analytics: We may use third-party Service Providers to monitor and analyse the use of our Service.',
  },
  {
    id: '13.3',
    term:
      'Google Analytics: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on.',
  },
  {
    id: '13.4',
    term:
      'Advertising: We may use third-party Service Providers to show advertisements to you when you visit our website.',
  },
];
