import * as React from 'react';
export interface RefetchContextInterface {
  refetch?: boolean;
  setRefetch: (refetch: boolean) => void;
}
export const RefetchContext = React.createContext<RefetchContextInterface>({
  refetch: false,

  // eslint-disable-next-line
  setRefetch: () => {},
});
interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
}
export function RefetchProvider({children, ...props}: Props) {
  const [refetch, setRefetch] = React.useState<boolean>();
  return (
    <RefetchContext.Provider
      value={React.useMemo(() => ({refetch, setRefetch}), [
        refetch,
        setRefetch,
      ])}
      {...props}
    >
      {children}
    </RefetchContext.Provider>
  );
}
