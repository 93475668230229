/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Status = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type TourMemberCard_tour = {
    readonly id: string;
    readonly title: string;
    readonly views: number;
    readonly createdAt: unknown;
    readonly description: string;
    readonly videoURL: string;
    readonly thumbnailURL: string;
    readonly reels: ReadonlyArray<{
        readonly provider: string;
    }>;
    readonly status: {
        readonly status: Status;
    };
    readonly logs: ReadonlyArray<{
        readonly status: string;
        readonly error: string;
    }>;
    readonly " $refType": "TourMemberCard_tour";
};
export type TourMemberCard_tour$data = TourMemberCard_tour;
export type TourMemberCard_tour$key = {
    readonly " $data"?: TourMemberCard_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourMemberCard_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TourMemberCard_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reel",
      "kind": "LinkedField",
      "name": "reels",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourProgress",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourLog",
      "kind": "LinkedField",
      "name": "logs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = '386c9dc1dcd571e4a2c960f3b4d23444';
export default node;
