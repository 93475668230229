import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {styled} from '@mui/material/styles';
import TruvuLogoSvg from '../../assets/svg/truvu-logo.svg';
import {AccountCircle} from '@mui/icons-material';
import {useHistory} from 'react-router';

export interface TourAppBarProps {
  onGoBack?: () => void;
  showAccount?: boolean;
}

const StyledAppBar = styled(Stack)(({theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

export function TruvuAppBar({onGoBack, showAccount}: TourAppBarProps) {
  const {push} = useHistory();

  const onGoToAccount = () => {
    push('/account');
  };

  return (
    <StyledAppBar>
      <Stack direction="row" justifyContent="space-between">
        {onGoBack != null ? (
          <IconButton
            onClick={onGoBack}
            sx={{alignSelf: 'flex-start', p: 0}}
            size="large"
          >
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
        ) : (
          <img src={TruvuLogoSvg} alt="Truvu" style={{maxWidth: '70px'}} />
        )}
        {showAccount && (
          <IconButton size="large" sx={{p: 0}} onClick={onGoToAccount}>
            <AccountCircle fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
    </StyledAppBar>
  );
}
