import React, {useCallback, useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {
  CircularProgress,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import {TourAddMarker} from './TourAddMarker';
import {useSnackbarContext} from '../../../../context/SnackbarContext';
import {useMutation} from 'react-relay-mutation';
import {TourAddMarkers_tour$key} from '../../../../__generated__/TourAddMarkers_tour.graphql';

const fragment = graphql`
  fragment TourAddMarkers_tour on Tour {
    id
    title
  }
`;

export interface TourAddMarkersProps {
  addNewMarker: boolean;
  tourRef: TourAddMarkers_tour$key;
  currentIndex: number;
  onAddMarkerCallback: () => void;
}

const markers = [
  'Entrance',
  'Foyer',
  'Hallway',
  'Guest Bedroom',
  'Master Bedroom',
  'Bedroom',
  'En-Suite Bathroom',
  'Bathroom',
  'Living Room',
  'Dining Room',
  'Kitchen',
  'Pantry',
  'Laundry Room',
  'Home Office',
  'Media Room',
  'Game Room',
  'Gym',
  'Garden',
  'Communal Area',
  'Terrace',
  'Balcony',
  'Attic',
  'Basement',
  'Garage',
];

export type AddMarkerState = 'idle' | 'adding' | 'addCustom' | 'addingCustom';

export type onSaveAddMarker = (
  name: string,
  source: 'custom' | 'predefined'
) => Promise<void>;

export function TourAddMarkers({
  addNewMarker,
  tourRef,
  currentIndex,
  onAddMarkerCallback,
}: TourAddMarkersProps) {
  const tour = useFragment(fragment, tourRef);
  const {notify} = useSnackbarContext();
  const [markerSave] = useMutation(
    graphql`
      mutation TourAddMarkersMutation($input: MarkerSaveInput!) {
        markerSave(input: $input) {
          marker {
            id
            name
            description
            scrollPosition
            isFastTravel
            frameIndex
          }
        }
      }
    `,
    {
      onError: (error) => {
        setAddMarkerState('idle');
        setAddingMarker(null);
        notify({
          message: error.message,
          variant: 'error',
          position: 'center',
        });
        onAddMarkerCallback();
      },
      onCompleted: () => {
        setAddMarkerState('idle');
        setAddingMarker(null);
        onAddMarkerCallback();
      },
    }
  );

  const [addMarkerState, setAddMarkerState] = useState<AddMarkerState>('idle');
  const [addingMarker, setAddingMarker] = useState<string | null>(null);

  //TODO!
  // function validateMarkerPos() {
  //   let flag;
  //   tour.frames.forEach((frame, frameIndex) => {
  //     if (
  //       frame.marker &&
  //       currentIndex > frameIndex - 10 &&
  //       currentIndex < frameIndex + 10
  //     ) {
  //       flag = true;
  //     }
  //   });
  //   if (!flag) {
  //     setNewMarkerDrawerOpen();
  //     setOpen();
  //   } else {
  //     notify({
  //       message: 'Scroll position taken',
  //       variant: 'error',
  //       position: 'center',
  //     });
  //   }
  // }

  const onSave = useCallback<onSaveAddMarker>(
    async (name, source) => {
      if (source === 'custom') {
        setAddMarkerState('addingCustom');
      } else {
        setAddMarkerState('adding');
        setAddingMarker(name);
      }

      await markerSave({
        variables: {
          input: {
            name: name,
            description: '',
            tourId: tour.id,
            scrollPosition: 0,
            isFastTravel: true,
            frameIndex: currentIndex,
          },
        },
      });
    },
    [currentIndex, markerSave, tour.id]
  );

  return (
    <Stack spacing={1}>
      <Collapse in={addNewMarker}>
        <TourAddMarker addMarkerState={addMarkerState} onSave={onSave} />
        <Divider sx={{mt: 1.5, mb: 0.5}} />
      </Collapse>
      {markers
        .sort((a, b) => (a > b ? 1 : -1))
        .map((marker) => (
          <PredefinedMarker
            key={marker}
            onClick={() => onSave(marker, 'predefined')}
            disabled={
              addMarkerState === 'adding' ||
              addMarkerState === 'addCustom' ||
              addMarkerState === 'addingCustom'
            }
          >
            <Typography textAlign="start">{marker}</Typography>
            {addMarkerState === 'adding' && addingMarker === marker ? (
              <CircularProgress size={24} />
            ) : null}
          </PredefinedMarker>
        ))}
    </Stack>
  );
}

const PredefinedMarker = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  textAlign: 'start',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flex: 1,
  gap: theme.spacing(0.5),
  backgroundColor: '#EDEFF2',
  padding: '10px 12px',
  borderRadius: '8px',
}));
