import React, {useCallback, useRef} from 'react';
import {Check} from '@mui/icons-material';
import {TruvuTextField} from '../../../../components/textField';
import {useSnackbarContext} from '../../../../context/SnackbarContext';
import {AddMarkerState, onSaveAddMarker} from './TourAddMarkers';
import {TruvuIconButton} from '../../../../components/button/TruvuIconButton';

interface TourAddMarkerProps {
  addMarkerState: AddMarkerState;
  onSave: onSaveAddMarker;
}

export function TourAddMarker({addMarkerState, onSave}: TourAddMarkerProps) {
  const {notify} = useSnackbarContext();
  const markerNameInputRef = useRef<HTMLInputElement>(null);

  const onValidateSave = useCallback(async () => {
    if (
      markerNameInputRef.current?.value == null ||
      markerNameInputRef.current.value === ''
    ) {
      notify({
        message: 'Please enter a marker name',
        variant: 'warning',
        position: 'center',
      });
      return;
    }

    await onSave(markerNameInputRef.current.value, 'custom');
  }, [notify, onSave]);

  return (
    <TruvuTextField
      inputRef={markerNameInputRef}
      placeholder="Enter new marker name"
      onEnter={onValidateSave}
      disabled={
        addMarkerState === 'adding' || addMarkerState === 'addingCustom'
      }
      endAdornment={
        <TruvuIconButton
          variant="primary"
          onClick={onValidateSave}
          loading={addMarkerState === 'addingCustom'}
        >
          <Check fontSize="inherit" />
        </TruvuIconButton>
      }
      fullWidth
    />
  );
}
