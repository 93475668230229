import React from 'react';
import {Tooltip, TooltipProps} from '@mui/material';

export function TruvuTooltip({children, ...props}: TooltipProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Tooltip
      open={open}
      onClick={handleToggle}
      onClose={handleClose}
      onOpen={handleOpen}
      componentsProps={{
        tooltip: {
          sx: {
            p: 2,
            fontSize: '1rem',
            textAlign: 'justify',
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
