/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfilePlanDetails_user = {
    readonly monthlyActiveTours: number | null;
    readonly monthlyUploadedTours: number | null;
    readonly company: {
        readonly name: string;
        readonly license: {
            readonly stripeSubscriptionId: string | null;
            readonly monthlyActiveTours: number | null;
            readonly monthlyUploadedTours: number | null;
            readonly planName: string | null;
            readonly quantity: number;
            readonly uploadLimit: number | null;
            readonly startDate: string | null;
            readonly endDate: string | null;
        } | null;
    } | null;
    readonly " $refType": "ProfilePlanDetails_user";
};
export type ProfilePlanDetails_user$data = ProfilePlanDetails_user;
export type ProfilePlanDetails_user$key = {
    readonly " $data"?: ProfilePlanDetails_user$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfilePlanDetails_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyActiveTours",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyUploadedTours",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfilePlanDetails_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "License",
          "kind": "LinkedField",
          "name": "license",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripeSubscriptionId",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uploadLimit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'c04bd8c9398b41fdd402b49184b44a10';
export default node;
