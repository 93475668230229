import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const spin = keyframes`
0%{
    transform: rotate(0deg)
}
100%{
    transform: rotate(360deg)
}`;
const CustomSvg = styled.svg`
  animation: ${spin} 1s ease infinite;
  .a {
    fill: #5374fc;
  }
  .b {
    fill: #fff;
  }
`;

interface Props {
  width: string | undefined;
  height: string | undefined;
}

export function LoadingSpinner({width = '31.606', height = '31.317'}: Props) {
  return (
    <CustomSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31.606 31.317"
    >
      <g transform="translate(-1711.638 214.689)">
        <g transform="translate(1711.638 -214.689)">
          <path
            className="a"
            d="M1715.385-195.139a3.747,3.747,0,0,1-3.748-3.747,15.82,15.82,0,0,1,15.8-15.8,3.747,3.747,0,0,1,3.747,3.747,3.748,3.748,0,0,1-3.747,3.747,8.317,8.317,0,0,0-8.308,8.307A3.747,3.747,0,0,1,1715.385-195.139Z"
            transform="translate(-1711.638 214.689)"
          />
        </g>
        <g transform="translate(1711.926 -202.346)">
          <path
            className="b"
            d="M1727.653-174.306a15.532,15.532,0,0,1-15.515-15.515,3.459,3.459,0,0,1,3.459-3.459,3.46,3.46,0,0,1,3.459,3.459,8.606,8.606,0,0,0,8.6,8.6,3.46,3.46,0,0,1,3.459,3.459A3.459,3.459,0,0,1,1727.653-174.306Z"
            transform="translate(-1712.138 193.28)"
          />
        </g>
        <g transform="translate(1723.982 -202.346)">
          <path
            className="b"
            d="M1736.507-174.306a3.459,3.459,0,0,1-3.459-3.459,3.459,3.459,0,0,1,3.459-3.459,8.605,8.605,0,0,0,8.6-8.6,3.46,3.46,0,0,1,3.459-3.459,3.46,3.46,0,0,1,3.459,3.459A15.532,15.532,0,0,1,1736.507-174.306Z"
            transform="translate(-1733.048 193.28)"
          />
        </g>
        <g transform="translate(1723.693 -214.689)">
          <path
            className="a"
            d="M1748.351-195.139a3.747,3.747,0,0,1-3.747-3.747,8.317,8.317,0,0,0-8.308-8.307,3.748,3.748,0,0,1-3.747-3.747,3.747,3.747,0,0,1,3.747-3.747,15.82,15.82,0,0,1,15.8,15.8A3.747,3.747,0,0,1,1748.351-195.139Z"
            transform="translate(-1732.548 214.689)"
          />
        </g>
      </g>
    </CustomSvg>
  );
}
