/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourCardRefetchQueryVariables = {
    id: string;
};
export type TourCardRefetchQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TourCard_tour">;
    } | null;
};
export type TourCardRefetchQuery = {
    readonly response: TourCardRefetchQueryResponse;
    readonly variables: TourCardRefetchQueryVariables;
};



/*
query TourCardRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...TourCard_tour
    id
  }
}

fragment TourCard_tour on Tour {
  id
  title
  views
  createdAt
  description
  videoURL
  thumbnailURL
  reels {
    id
    key
    progress
    seed
    provider
    highURI
    thumbnailURL
    status {
      status
      id
    }
  }
  status {
    status
    id
  }
  logs {
    status
    error
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailURL",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourCardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TourCard_tour"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourCardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "views",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoURL",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Reel",
                "kind": "LinkedField",
                "name": "reels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highURI",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReelProgress",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TourProgress",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TourLog",
                "kind": "LinkedField",
                "name": "logs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Tour",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c4ef4698b09327e992d6d8a72086c1a",
    "id": null,
    "metadata": {},
    "name": "TourCardRefetchQuery",
    "operationKind": "query",
    "text": "query TourCardRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TourCard_tour\n    id\n  }\n}\n\nfragment TourCard_tour on Tour {\n  id\n  title\n  views\n  createdAt\n  description\n  videoURL\n  thumbnailURL\n  reels {\n    id\n    key\n    progress\n    seed\n    provider\n    highURI\n    thumbnailURL\n    status {\n      status\n      id\n    }\n  }\n  status {\n    status\n    id\n  }\n  logs {\n    status\n    error\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '5ee618949f4d1456dd8fd532059d91b9';
export default node;
