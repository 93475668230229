import React from 'react';
import {Stack, StackProps, styled} from '@mui/material';
import TruvuLogoSvg from '../../assets/svg/truvu-logo.svg';
import {ArrowBack} from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';

const StyledContainer = styled(Stack)(() => ({
  minHeight: '100svh',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px 20px',
  maxWidth: '1200px',
}));

const StyledAppBar = styled(Stack)(({theme}) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  paddingTop: '20px',
  paddingBottom: '20px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

export function TruvuTermContainer({
  children,
  onGoBack,
  ...props
}: StackProps & {onGoBack: () => void}) {
  return (
    <StyledContainer {...props}>
      <StyledAppBar>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <ButtonBase
              onClick={onGoBack}
              sx={{
                height: 'fit-content',
                padding: '5px 10px 5px 5px',
                marginLeft: '-5px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                borderRadius: 8,
              }}
            >
              <ArrowBack />
              <img
                src={TruvuLogoSvg}
                alt="Truvu"
                style={{width: '70px', maxWidth: '70px'}}
              />
            </ButtonBase>
          </Stack>
        </Stack>
      </StyledAppBar>
      {children}
    </StyledContainer>
  );
}
