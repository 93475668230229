import {Term} from '../../terms/components/TermsComponent';

export const yourDataProtection: Term[] = [
  {
    id: '11.1',
    term:
      'If you are a resident of the European Economic Area (EEA), you have certain data protection rights. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us. In certain circumstances, you have the following data protection rights:',
    terms: [
      {
        id: '11.1.1',
        term:
          'The right to access, update, or delete the information we have on you. Whenever made possible, you can access, update, or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.',
      },
      {
        id: '11.1.2',
        term:
          'The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.',
      },
      {
        id: '11.1.3',
        term:
          'The right to object. You have the right to object to our processing of your Personal Data.',
      },
      {
        id: '11.1.4',
        term:
          'The right of restriction. You have the right to request that we restrict the processing of your personal information.',
      },
      {
        id: '11.1.5',
        term:
          'The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.',
      },
      {
        id: '11.1.6',
        term:
          'The right to withdraw consent. You also have the right to withdraw your consent at any time where we relied on your consent to process your personal information.',
      },
    ],
  },
  {
    id: '11.2',
    term:
      'Please note that we may ask you to verify your identity before responding to such requests. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).',
  },
];
