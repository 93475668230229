import ReactGA from 'react-ga4';

export function logLoadProgress(
  progress: number,
  totalCount: number,
  tourId: string
) {
  // Ensure progress does not exceed 100%
  const boundedProgress = Math.min(progress, totalCount);

  // Round the progress to the nearest 10%
  const bucketedProgress = Math.round((boundedProgress / totalCount) * 20) * 5;
  // console.log('Load', tourId, bucketedProgress);

  ReactGA.event({
    category: 'Player',
    action: `LoadProgress:${bucketedProgress}`,
    label: tourId,
    value: bucketedProgress,
    nonInteraction: true,
    transport: 'xhr',
  });
}
