import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export function TourNotFoundMessage() {
  return (
    <TruvuMessageDialog
      title={'This tour has been removed'}
      variant={'error'}
      isOpen={true}
      message={
        <Stack>
          <Typography variant="body1" gutterBottom>
            Unfortunately this tour has been removed by the owner.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            For more information contact the owner directly or alternatively
            contact <a href="mailto: support@truvu.app">support@truvu.app</a>
          </Typography>
        </Stack>
      }
      onClose={() => {
        window.open('https://truvu.app/', '_self');
      }}
      onOpen={() => {
        window.open('https://truvu.app/', '_self');
      }}
    />
  );
}
