import {Terms} from '../components/TermsComponent';

export const usageRestrictions: Terms = [
  {
    id: '4.1',
    term:
      'copy (other than for backup, archival or disaster recovery purposes), reproduce, translate, adapt, vary, modify, lease, licence, sub-licence, encumber or in any other way deal with any part of the Website for any reason and in any manner, unless it is consistent with the intent and purpose of these Terms;',
  },
  {
    id: '4.2',
    term:
      'decompile, disassemble or reverse engineer any portion of the Website;',
  },
  {
    id: '4.3',
    term:
      'write and/or develop any derivative of the Website or any other software program based on the Website;',
  },
  {
    id: '4.4',
    term:
      'modify or enhance the Website. In the event of a user effecting any modifications or enhancements to the Website in breach of this clause, such modifications and enhancements shall be the property of Service Provider;',
  },
  {
    id: '4.5',
    term:
      "without Service Provider's prior written consent, provide, disclose, divulge or make available to or permit the use of or give access to the Website by persons other than the user;",
  },
  {
    id: '4.6',
    term:
      'remove any identification, trademark, copyright or other notices from the Website;',
  },
  {
    id: '4.7',
    term:
      'post or transmit, by means of reviews, comments, suggestions, ideas, questions or other information through the Website, any content which is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually-explicit, profane or hateful, or racially, ethnically or otherwise objectionable content of any kind; and/or',
  },
  {
    id: '4.8',
    term:
      'notwithstanding anything contained to the contrary in these Terms, use the Website for any purpose other than personal, non-commercial and information purposes.',
  },
];
