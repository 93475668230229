import React from 'react';
import {
  TruvuTextFieldBase,
  TruvuTextFieldBaseProps,
} from './TruvuTextFieldBase';
import {
  TruvuTextFieldFormik,
  TruvuTextFieldFormikProps,
} from './TruvuTextFieldFormik';

type TruvuTextFieldProps =
  | ({formikField: true} & TruvuTextFieldFormikProps)
  | ({formikField?: false} & TruvuTextFieldBaseProps);

export function TruvuTextField({
  formikField,
  label,
  name,
  ...props
}: TruvuTextFieldProps &
  (TruvuTextFieldBaseProps | TruvuTextFieldFormikProps)) {
  if (formikField && name != null) {
    return <TruvuTextFieldFormik name={name} label={label} {...props} />;
  }

  return <TruvuTextFieldBase name={name} label={label} {...props} />;
}
