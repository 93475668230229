import ReactGA from 'react-ga4';

export function logWorkerPlan(
  count: number,
  hasWebCodec: boolean,
  tourId: string
) {
  // console.log(hasWebCodec, count, tourId);

  ReactGA.event({
    category: 'Player',
    action: hasWebCodec ? 'WebCodecsWorkerCount' : 'PolyfillWorkerCount',
    label: tourId,
    value: count,
    nonInteraction: true,
    transport: 'xhr',
  });
}
