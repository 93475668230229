import React from 'react';
import {useSearchParam} from 'react-use';
import {useQuery} from 'relay-hooks';
import {FullscreenLoader} from '../../components/loader/FullscreenLoader';
import graphql from 'babel-plugin-relay/macro';
import {TourEdit} from '../../modules/tourPlayer/tourPlayerEdit/components/TourEdit';
import {TourEditPageQuery} from '../../__generated__/TourEditPageQuery.graphql';
import {TourNotFoundMessage} from '../../modules/tourPlayer/components/TourNotFoundMessage';
import {TourErrorDialog} from '../../modules/tourPlayer/components/TourErrorDialog';
import {TourInvalidLinkDialog} from '../../modules/tourPlayer/components/TourInvalidLinkDialog';

const query = graphql`
  query TourEditPageQuery($tourID: ID!) {
    tour: node(id: $tourID) {
      ...TourEdit_tour
    }
  }
`;

function TourEditPageComponent({tourID}: {tourID: string}) {
  const {data, isLoading, error} = useQuery<TourEditPageQuery>(
    query,
    {tourID},
    {
      fetchPolicy: 'network-only',
      networkCacheConfig: undefined,
    }
  );

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: '#758089',
          height: '100dvh',
        }}
      >
        <FullscreenLoader />
      </div>
    );
  }

  if (error != null) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return <TourErrorDialog isOpen onClose={() => {}} />;
  }
  if (data?.tour == null) {
    return <TourNotFoundMessage />;
  }

  return <TourEdit tourRef={data.tour} />;
}

export function TourEditPage() {
  const tourID = useSearchParam('tourId');

  if (tourID == null) {
    return <TourInvalidLinkDialog />;
  }

  return <TourEditPageComponent tourID={tourID} />;
}
