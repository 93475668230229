import React from 'react';
import {Box, Drawer, useMediaQuery, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import {addShouldNotForwardProps} from '../../../utils/addShouldNotForwardProps';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import ArrowJSON from '../../../assets/json/arrow.json';

export const TOUR_DRAWER_WIDTH = '250px';
export const TOUR_DRAWER_HEIGHT = '400px';

interface TourDrawerProps {
  keepDrawerOpen: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

export function TourDrawer({
  keepDrawerOpen,
  open,
  setOpen,
  drawerRef,
  children,
}: TourDrawerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let handleRotation = '';
  if (isMobile && open) {
    handleRotation = 'rotateZ(-90deg) translateY(3px)';
  } else if (isMobile && !open) {
    handleRotation = 'rotateZ(90deg) translateY(3px)';
  } else if (open) {
    handleRotation = 'rotateY(180deg) translateY(3px)';
  }

  return (
    <>
      {!keepDrawerOpen && (
        <DrawerHandel
          onClick={() => setOpen((prevState) => !prevState)}
          $drawerIsOpen={open}
          $drawerHeight={
            drawerRef.current?.clientHeight ?? parseInt(TOUR_DRAWER_HEIGHT)
          }
        >
          <Lottie
            animationData={ArrowJSON}
            style={{
              transform: handleRotation,
            }}
          />
        </DrawerHandel>
      )}
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        variant="persistent"
        SlideProps={{easing: theme.transitions.easing.sharp}}
        transitionDuration={theme.transitions.duration.standard}
        open={open || keepDrawerOpen}
        onClose={() => setOpen(false)}
        ModalProps={{keepMounted: true}}
        PaperProps={{
          sx: {
            backgroundColor: 'none',
            borderLeft: 'none',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: isMobile ? '15px' : 0,
            borderBottomLeftRadius: isMobile ? 0 : '15px',
          },
        }}
      >
        <DrawerContent ref={drawerRef}>{children}</DrawerContent>
      </Drawer>
    </>
  );
}

const DrawerHandel = styled(Box, {
  shouldForwardProp: addShouldNotForwardProps('$drawerIsOpen', '$drawerHeight'),
})<{$drawerIsOpen: boolean; $drawerHeight: number}>(
  ({theme, $drawerIsOpen, $drawerHeight}) => ({
    position: 'absolute',
    top: '50%',
    right: theme.spacing(1),
    zIndex: 3,
    cursor: 'pointer',
    // padding: theme.spacing(1),
    marginRight: $drawerIsOpen ? TOUR_DRAWER_WIDTH : 0,
    transform: 'translateY(-50%)',
    transition: theme.transitions.create(['margin-right'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp,
    }),
    [theme.breakpoints.down('md')]: {
      top: 'auto',
      right: 'auto',
      left: '50%',
      bottom: 0,
      marginRight: 0,
      transform: 'translateX(-50%)',
      marginBottom: $drawerIsOpen ? `${$drawerHeight}px` : 0,
      transition: theme.transitions.create(['margin-bottom'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.sharp,
      }),
    },
  })
);
const DrawerContent = styled(Box, {
  shouldForwardProp: addShouldNotForwardProps('$isMobile'),
})(({theme}) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  padding: '15px 15px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: TOUR_DRAWER_WIDTH,
  borderTopLeftRadius: '8px',
  [theme.breakpoints.down('md')]: {
    height: '100%',
    maxHeight: TOUR_DRAWER_HEIGHT,
    width: '100%',
  },
}));
