import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
import {Add} from '@mui/icons-material';
import React from 'react';
import {checkUploadLimit} from '../helpers/checkUploadLimit';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {ToursAdd_user$key} from '../../../__generated__/ToursAdd_user.graphql';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {useTruvuDialog} from '../../../components/dialog/TruvuDialog';
import {TruvuButton} from '../../../components/button/TruvuButton';

const fragmentSpec = graphql`
  fragment ToursAdd_user on User {
    company {
      license {
        monthlyActiveTours
        monthlyUploadedTours
        uploadLimit
        quantity
      }
    }
  }
`;
export function ToursAdd({userRef}: {userRef: ToursAdd_user$key}) {
  const user = useFragment(fragmentSpec, userRef);
  const {isActiveAvailable, isUploadAvailable} = checkUploadLimit({
    monthlyActiveTours: user?.company?.license?.monthlyActiveTours,
    monthlyUploadedTours: user?.company?.license?.monthlyUploadedTours,
    uploadLimit: user?.company?.license?.uploadLimit,
    quantity: user?.company?.license?.quantity,
  });
  const disableAdd =
    user?.company?.license == null || !isActiveAvailable || !isUploadAvailable;
  const truvuDialog = useTruvuDialog();
  if (disableAdd) {
    const {onOpen} = truvuDialog;
    return (
      <>
        <TruvuMessageDialog
          title="No monthly uploads left"
          message="Upgrade to a larger plan in order to upload more tours"
          variant={'error'}
          actions={
            <TruvuButton variant="danger" to="/account/subscriptions">
              Upgrade plan
            </TruvuButton>
          }
          {...truvuDialog}
        />
        <TruvuIconButton variant={'default'} label={'Add'} onClick={onOpen}>
          <Add />
        </TruvuIconButton>
      </>
    );
  }
  return (
    <TruvuIconButton
      variant={disableAdd ? 'default' : 'primary'}
      label="Add"
      to="/upload"
      disabled={disableAdd}
    >
      <Add />
    </TruvuIconButton>
  );
}
