/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SubscriptionsPageQueryVariables = {};
export type SubscriptionsPageQueryResponse = {
    readonly me: {
        readonly user: {
            readonly company: {
                readonly license: {
                    readonly planName: string | null;
                    readonly quantity: number;
                } | null;
            } | null;
        } | null;
    } | null;
    readonly stripeProduct: {
        readonly productId: string;
        readonly priceTiers: ReadonlyArray<{
            readonly priceId: string;
            readonly flatAmount: number;
            readonly unitAmount: number;
            readonly upTo: number;
            readonly currencyCode: string;
            readonly currencySymbol: string;
            readonly tierName: string;
        }> | null;
    } | null;
};
export type SubscriptionsPageQuery = {
    readonly response: SubscriptionsPageQueryResponse;
    readonly variables: SubscriptionsPageQueryVariables;
};



/*
query SubscriptionsPageQuery {
  me {
    user {
      company {
        license {
          planName
          quantity
          id
        }
        id
      }
      id
    }
  }
  stripeProduct {
    productId
    priceTiers {
      priceId
      flatAmount
      unitAmount
      upTo
      currencyCode
      currencySymbol
      tierName
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "StripeProduct",
  "kind": "LinkedField",
  "name": "stripeProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePriceTier",
      "kind": "LinkedField",
      "name": "priceTiers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flatAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currencyCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currencySymbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tierName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Me",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "license",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SubscriptionsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Me",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "license",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e9ad517adcc9edf67c7ad07e99918b37",
    "id": null,
    "metadata": {},
    "name": "SubscriptionsPageQuery",
    "operationKind": "query",
    "text": "query SubscriptionsPageQuery {\n  me {\n    user {\n      company {\n        license {\n          planName\n          quantity\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n  stripeProduct {\n    productId\n    priceTiers {\n      priceId\n      flatAmount\n      unitAmount\n      upTo\n      currencyCode\n      currencySymbol\n      tierName\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1755b0c2548a91cd8732719bb510a4b4';
export default node;
