import React from 'react';
import TruvuTextField from '.';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {useFormikContext} from 'formik';

interface PhoneNumberFormikFieldProps {
  name: string;
  label: string;
  placeholder?: string;
}

export function PhoneNumberFormikField({
  name,
  label,
  placeholder,
}: PhoneNumberFormikFieldProps) {
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value, error} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  return (
    <PhoneInput
      defaultCountry="ZA"
      numberInputProps={{
        label,
        name,
        error,
      }}
      style={{
        paddingLeft: '3px',
      }}
      value={typeof value === 'string' ? value : ''}
      placeholder={placeholder}
      onChange={setValue}
      inputComponent={ProfilePhoneNumberInput}
    />
  );
}
export const ProfilePhoneNumberInput = React.forwardRef(
  function ProfilePhoneNumberInput(
    {
      error,
      ...props
    }: {
      error: string | undefined;
      value: string | undefined;
      onBlur: (event: unknown) => void;
      onKeyDown: (event: unknown) => void;
      onChange: (event: unknown) => void;
    },
    ref
  ) {
    return (
      <TruvuTextField
        {...props}
        error={!!error}
        helperText={error}
        inputRef={ref}
        fullWidth
        name="contactNumber"
        label="Contact Number"
      />
    );
  }
);
