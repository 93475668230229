import {getMetaTagContent} from './get-meta';

const {
  NODE_ENV,
  REACT_APP_SERVER_URI = 'http://localhost:5000',
  REACT_APP_GOOGLE_CLIENT_ID = 'something',
} = process.env;

let serverUri: string | null;
let googleClientId: string | null;

if (NODE_ENV === 'development') {
  serverUri = REACT_APP_SERVER_URI;
  googleClientId = REACT_APP_GOOGLE_CLIENT_ID;
} else {
  serverUri = getMetaTagContent('app:server-uri');
  googleClientId = getMetaTagContent('app:google-client-id');
}

const isDev = process.env.NODE_ENV !== 'production';

/*
 * Retrieve the server uri from the app:server-uri meta tag when in prod mode
 * in dev mode use the REACT_APP_SERVER_URI variable
 */
export class ConfigService {
  static get serverUri(): string | null {
    if (serverUri != null) {
      // strip trailing slash
      return serverUri.replace(/\/$/, '');
    } else {
      return serverUri;
    }
  }

  static get googleClientId(): string {
    if (googleClientId != null) {
      // strip trailing slash
      return googleClientId;
    } else {
      return 'something';
    }
  }

  /**
   * Should be one of production,development,testing,staging etc.
   */
  static get stage(): string {
    if (NODE_ENV === 'development') {
      return 'development';
    } else {
      return getMetaTagContent('app:stage') || 'production';
    }
  }

  static get isDev(): boolean {
    return isDev;
  }
}
