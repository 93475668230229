import {Term} from '../../terms/components/TermsComponent';

export const complianceWithTheProtection: Term[] = [
  {
    id: '12.1',
    term:
      'We are committed to protecting your personal information in accordance with the Protection of Personal Information Act (POPIA) 4 of 2013.',
  },
  {
    id: '12.2',
    term:
      'For the purposes of this policy, the terms "personal information", "processing", "data subject", "responsible party", and "operator" shall have the meanings ascribed to them in POPIA.',
  },
  {
    id: '12.3',
    term:
      'We shall process your personal information in accordance with the principles of POPIA, ensuring it is:',
    terms: [
      {
        id: '12.3.1',
        term:
          'Processed lawfully and in a reasonable manner that does not infringe your privacy;',
      },
      {
        id: '12.3.2',
        term:
          'Collected for a specific, explicitly defined, and lawful purpose related to our functions and activities;',
      },
      {
        id: '12.3.3',
        term:
          'Adequate, relevant, and not excessive for the purpose for which it is processed;',
      },
      {
        id: '12.3.4',
        term: 'Accurate and kept up to date where necessary;',
      },
      {
        id: '12.3.5',
        term:
          'Not retained for longer than is necessary to achieve the purpose for which it was collected, unless required by law;',
      },
      {
        id: '12.3.6',
        term: 'Processed in accordance with your rights as a data subject;',
      },
      {
        id: '12.3.7',
        term:
          'Secured against the risk of loss, damage, unauthorised destruction, and unlawful access;',
      },
      {
        id: '12.3.8',
        term:
          'Not transferred to a third party in a foreign country unless that country has similar data protection laws, or with your consent.',
      },
    ],
  },
  {
    id: '12.4',
    term: 'Data Subject Rights:',
    terms: [
      {
        id: '12.4.1',
        term: 'You, as a data subject, have the right to:',
        terms: [
          {
            id: '12.4.1.1',
            term:
              'Access your personal information and receive information about its processing;',
          },
          {
            id: '12.4.1.2',
            term:
              'Request the correction or deletion of your personal information;',
          },
          {
            id: '12.4.1.3',
            term:
              'Object to the processing of your personal information under certain circumstances;',
          },
          {
            id: '12.4.1.4',
            term:
              'Lodge a complaint with the Information Regulator if you believe your personal information has been processed in a manner that is not in accordance with POPIA.',
          },
        ],
      },
    ],
  },
  {
    id: '12.5',
    term: 'Data Security:',
    terms: [
      {
        id: '12.5.1',
        term:
          'We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:',
        terms: [
          {
            id: '12.5.1.1',
            term: 'Pseudonymisation and encryption of personal information;',
          },
          {
            id: '12.5.1.2',
            term:
              'Ensuring ongoing confidentiality, integrity, availability, and resilience of processing systems;',
          },
          {
            id: '12.5.1.3',
            term:
              'The ability to restore availability and access to personal information in a timely manner in the event of a physical or technical incident;',
          },
          {
            id: '12.5.1.4',
            term:
              'Regular testing, assessment, and evaluation of the effectiveness of security measures.',
          },
        ],
      },
    ],
  },
  {
    id: '12.6',
    term: 'Data Breach Notification:',
    terms: [
      {
        id: '12.6.1',
        term:
          'In the event of a personal data breach, we shall notify the Information Regulator and affected data subjects without undue delay, where feasible, within 72 hours of becoming aware of it, unless the breach is unlikely to result in a risk to the rights and freedoms of data subjects.',
      },
      {
        id: '12.6.2',
        term:
          'Where the data breach is likely to result in a high risk to your rights and freedoms, we shall communicate the personal data breach to you without undue delay.',
      },
    ],
  },
];
