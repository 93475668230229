import React from 'react';

interface Snack {
  message: string;
  // default to info
  variant?: 'success' | 'warning' | 'info' | 'error';
  position?: string;
}

export interface SnackbarContextType {
  snack?: Snack;
  //dismiss open snack if it exists
  dismiss(): void;
  notify(snack: Snack): void;
}
export const SnackbarContext = React.createContext<SnackbarContextType>({
  snack: {message: 'test msg', variant: 'info'},
  dismiss: () => null,
  notify: () => null,
});

export const useSnackbarContext = () => React.useContext(SnackbarContext);
