import {Terms} from '../components/TermsComponent';

export const generalClauses: Terms = [
  {
    id: '14.1',
    term:
      'These Terms shall be governed in all respects by the laws of the Republic of South Africa as such laws are applied to agreements entered into and to be performed within South Africa.',
  },
  {
    id: '14.2',
    term:
      'This Website is controlled, operated and administered by Service Provider from its offices within the Republic of South Africa. Service Provider makes no representation that the content of the Website is appropriate or available for use outside of South Africa. Access to the Website from territories or countries where the content of the Website is illegal is prohibited. Users may not use this Website in violation of South African export laws and regulations. If the user accesses this Website from locations outside of South Africa, that user is responsible for compliance with all local laws.',
  },
  {
    id: '14.3',
    term:
      'Service Provider does not guarantee continuous, uninterrupted or secure access to our services, as operation of our Website may be interfered with as a result of a number of factors which are outside of our control.',
  },
  {
    id: '14.4',
    term:
      'If any provision of these Terms is held to be illegal, invalid or unenforceable for any reason, such provision shall be struck out from these Terms and the remaining provisions shall be enforced to the full extent of the law.',
  },
  {
    id: '14.5',
    term:
      "Service Provider's failure to act with respect to a breach by you or others does not constitute a waiver of our right to act with respect to subsequent or similar breaches.",
  },
  {
    id: '14.6',
    term:
      'You shall not be entitled to cede your rights or assign your rights or delegate your obligations in terms of these Terms to any third party without the prior written consent of Service Provider.',
  },
  {
    id: '14.7',
    term:
      'No party shall be bound by any express or implied term, representation, warranty, promise or the like not recorded herein, whether it induced the contract and/or whether it was negligent or not.',
  },
  {
    id: '14.8',
    term:
      'The head notes to the paragraphs to these Terms are inserted for reference purposes only and shall not affect the interpretation of any of the provisions to which they relate.',
  },
  {
    id: '14.9',
    term:
      'Words importing the singular shall include the plural and vice versa, and words importing the masculine gender shall include females and words importing persons shall include partnerships and corporate and unincorporated entities.',
  },
  {
    id: '14.10',
    term:
      'These Terms set forth the entire understanding and agreement between Service Provider and you with respect to the subject matter hereof.',
  },
];
