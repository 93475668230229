export const typesOfDataCollected = [
  {
    id: '2.1',
    term:
      'Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:',
    terms: [
      {id: '2.1.1', term: 'Email address'},
      {id: '2.1.2', term: 'Cookies and Usage Data'},
    ],
  },
  {
    id: '2.2',
    term:
      'We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.',
  },
  {
    id: '2.3',
    term:
      "Usage Data: We may also collect information on how the Service is accessed and used. This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.",
  },
  {
    id: '2.4',
    term:
      'Tracking & Cookies Data: We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.',
  },
  {
    id: '2.5',
    term:
      '"Do Not Track" Signals: We do not currently support Do Not Track ("DNT"). You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.',
  },
];
