import {Terms} from '../components/TermsComponent';

export const security: Terms = [
  {
    id: '5.1',
    term:
      "In order to ensure the security and reliable operation of the services to all Service Provider's users, Service Provider hereby reserves the right to take whatever action it may deem necessary to preserve the security, integrity and reliability of its network and back-office applications.",
  },
  {
    id: '5.2',
    term:
      "You may not utilise the Website in any manner which may compromise the security of Service Provider's networks or tamper with the Website in any manner whatsoever, which shall include without limitation, gaining or attempting to gain unauthorised access to the Website, or delivering or attempting to deliver any unauthorised, damaging or malicious code to the Website, all of which is expressly prohibited. Any person or entity which does so, or attempts to do so, shall be held criminally liable. Further, should Service Provider suffer any damage or loss, civil damages shall be claimed by Service Provider against the user.",
  },
  {
    id: '5.3',
    term:
      'Any user who commits any of the offences detailed in Chapter 13 of the Electronic Communications and Transactions Act 25 of 2002 ("ECTA") (specifically sections 85 to 88 (inclusive)) shall, notwithstanding criminal prosecution, be liable for all resulting liability, loss or damages suffered and/or incurred by Service Provider and its affiliates, agents and/or partners.',
  },
];
