import React from 'react';
import {Close} from '@mui/icons-material';
import {TruvuDialog2} from '../../../components/dialog/TruvuDialog2';
import IconButton from '@mui/material/IconButton';
import {StackProps} from '@mui/material';

interface TourDialogProps
  extends ReturnType<typeof TruvuDialog2.useTruvuDialog> {
  children: React.ReactNode;
  title: string;
  action?: React.ReactNode;
  subtitle?: string;
  ContentProps?: StackProps;
}

export function TourDialog({
  title,
  action,
  subtitle,
  children,
  ContentProps,
  ...dialog
}: TourDialogProps) {
  return (
    <TruvuDialog2 {...dialog} controlled>
      <TruvuDialog2.Dialog maxWidth="xs">
        <IconButton
          onClick={dialog.onClose}
          size="small"
          sx={{mb: 0.5, ml: -1, mt: -0.5, alignSelf: 'flex-start'}}
        >
          <Close fontSize="medium" />
        </IconButton>
        <TruvuDialog2.Title title={title} subtitle={subtitle} action={action} />
        <TruvuDialog2.Content overflow={'auto'} spacing={1.5} {...ContentProps}>
          {children}
        </TruvuDialog2.Content>
      </TruvuDialog2.Dialog>
    </TruvuDialog2>
  );
}
