import {Term} from '../../terms/components/TermsComponent';

export const legalRequirements: Term[] = [
  {id: '9.1', term: 'To comply with a legal obligation'},
  {id: '9.2', term: 'To protect and defend our rights or property'},
  {
    id: '9.3',
    term:
      'To prevent or investigate possible wrongdoing in connection with the Service',
  },
  {
    id: '9.4',
    term:
      'To protect the personal safety of users of the Service or the public',
  },
  {id: '9.5', term: 'To protect against legal liability'},
];
