import React, {useCallback, useRef} from 'react';
import {TruvuTextField} from '../../../../components/textField';
import {TruvuDialogActions} from '../../../../components/dialog/TruvuDialog2';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {TourDialog} from '../../components/TourDialog';
import {useMutation} from 'react-relay-mutation';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../../context/SnackbarContext';
import {EditMarker, MarkerState} from './TourEditMarker';
import {TourEditMarkerEditDialogUpdateMarkerMutation} from '../../../../__generated__/TourEditMarkerEditDialogUpdateMarkerMutation.graphql';

interface TourEditMarkerEditDialogProps {
  markerState: MarkerState;
  setMarkerState: React.Dispatch<React.SetStateAction<MarkerState>>;
  refetch: () => void;
  marker: EditMarker;
}

export function TourEditMarkerEditDialog({
  markerState,
  setMarkerState,
  refetch,
  marker,
}: TourEditMarkerEditDialogProps) {
  const {notify} = useSnackbarContext();

  const [
    infoMarkerUpdate,
  ] = useMutation<TourEditMarkerEditDialogUpdateMarkerMutation>(
    graphql`
      mutation TourEditMarkerEditDialogUpdateMarkerMutation(
        $input: MarkerUpdateInput!
      ) {
        markerUpdate(input: $input) {
          marker {
            id
            name
            description
          }
        }
      }
    `,
    {
      onError: (err) => {
        notify({
          message: err.message ?? 'Failed to update marker',
          variant: 'error',
          position: 'center',
        });
        setMarkerState('view');
        refetch();
      },
      onCompleted: () => {
        notify({
          message: 'Marker updated',
          variant: 'success',
          position: 'center',
        });
        setMarkerState('view');
        refetch();
      },
    }
  );

  const nameInputRef = useRef<HTMLInputElement>(null);

  const onSubmitEdit = useCallback(async () => {
    setMarkerState('editing');
    if (marker.id === undefined) return;

    if (nameInputRef.current?.value === marker.name) {
      notify({message: 'No changes', variant: 'info', position: 'center'});
      setMarkerState('edit');
      return;
    }
    if (
      nameInputRef.current?.value == null ||
      nameInputRef.current.value === ''
    ) {
      notify({
        message: 'Name cannot be empty',
        variant: 'warning',
        position: 'center',
      });
      setMarkerState('edit');
      return;
    }
    await infoMarkerUpdate({
      variables: {
        input: {
          id: marker.id,
          name: nameInputRef.current.value,
          description: '',
          isFastTravel: marker.isFastTravel,
        },
      },
    });
  }, [
    infoMarkerUpdate,
    marker.id,
    marker.isFastTravel,
    marker.name,
    notify,
    setMarkerState,
  ]);

  return (
    <TourDialog
      isOpen={markerState === 'edit' || markerState === 'editing'}
      onClose={() => {
        setMarkerState('view');
      }}
      onOpen={() => {
        setMarkerState('edit');
      }}
      title="Edit Marker"
    >
      <TruvuTextField
        name="name"
        label="Name"
        defaultValue={marker.name}
        inputRef={nameInputRef}
        onEnter={onSubmitEdit}
      />
      <TruvuDialogActions spacing={1}>
        <TruvuButton
          variant="primary"
          onClick={onSubmitEdit}
          loading={markerState === 'editing'}
          sx={{justifyContent: 'center', flex: 1}}
          disableAdornments
        >
          {markerState === 'editing' ? 'Saving...' : 'Save'}
        </TruvuButton>
        <TruvuButton
          variant="secondary"
          onClick={() => {
            setMarkerState('view');
          }}
          disabled={markerState === 'editing'}
          sx={{justifyContent: 'center', flex: 1}}
          disableAdornments
        >
          Cancel
        </TruvuButton>
      </TruvuDialogActions>
    </TourDialog>
  );
}
