/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourPreviewMarkers_tour = {
    readonly markers: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly isFastTravel: boolean;
        readonly frameIndex: number;
    }>;
    readonly " $refType": "TourPreviewMarkers_tour";
};
export type TourPreviewMarkers_tour$data = TourPreviewMarkers_tour;
export type TourPreviewMarkers_tour$key = {
    readonly " $data"?: TourPreviewMarkers_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourPreviewMarkers_tour">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TourPreviewMarkers_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Marker",
      "kind": "LinkedField",
      "name": "markers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFastTravel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frameIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
(node as any).hash = 'c6af74c66bff762c16232cc8d9f79cef';
export default node;
