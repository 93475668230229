/* eslint-disable */
// @ts-nocheck

export type FrameType = {
  codedHeight: number;
  codedWidth: number;
  displayHeight: number;
  displayWidth: number;
  format: string;
  timestamp: number;
  visibleRect: DOMRect;
  _data: Uint8Array;
  _layout: {};
  _nonSquarePixels: boolean;
};

export function hasWebCodec() {
  return (
    typeof VideoDecoder !== 'undefined' && typeof AudioDecoder !== 'undefined'
  );
}
