import React, {useCallback} from 'react';
import Typography from '@mui/material/Typography';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {MarkerState} from './TourEditMarker';
import {useMutation} from 'react-relay-mutation';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../../context/SnackbarContext';
import {TourEditMarkerDeleteDialogDeleteMarkerMutation} from '../../../../__generated__/TourEditMarkerDeleteDialogDeleteMarkerMutation.graphql';
import {TruvuMessageDialog} from '../../../../components/dialog/TruvuMessageDialog';

interface TourEditMarkerDeleteDialogProps {
  markerId: string;
  refetch: () => void;
  markerState: MarkerState;
  setMarkerState: React.Dispatch<React.SetStateAction<MarkerState>>;
}

export function TourEditMarkerDeleteDialog({
  markerId,
  refetch,
  markerState,
  setMarkerState,
}: TourEditMarkerDeleteDialogProps) {
  const {notify} = useSnackbarContext();

  const [
    infoMarkerDelete,
  ] = useMutation<TourEditMarkerDeleteDialogDeleteMarkerMutation>(
    graphql`
      mutation TourEditMarkerDeleteDialogDeleteMarkerMutation($markerId: ID!) {
        markerDelete(input: {markerId: $markerId}) {
          clientMutationId
        }
      }
    `,
    {
      onError: (err) => {
        notify({
          message: err.message ?? 'Failed to delete marker',
          variant: 'error',
          position: 'center',
        });
        setMarkerState('view');
      },
      onCompleted: () => {
        setMarkerState('view');
        refetch();
      },
    }
  );

  const onSubmitDelete = useCallback(async () => {
    setMarkerState('deleting');
    await infoMarkerDelete({
      variables: {markerId},
    });
  }, [infoMarkerDelete, markerId, setMarkerState]);

  return (
    <TruvuMessageDialog
      title="Delete Marker"
      variant="error"
      message={
        <Typography>Are you sure you want to delete this marker?</Typography>
      }
      actions={
        <>
          <TruvuButton
            loading={markerState === 'deleting'}
            variant="danger"
            onClick={onSubmitDelete}
            sx={{justifyContent: 'center', flex: 1}}
            disableAdornments
          >
            {markerState === 'deleting' ? 'Deleting...' : 'Delete'}
          </TruvuButton>
          <TruvuButton
            disabled={markerState === 'deleting'}
            variant="secondary"
            onClick={() => {
              setMarkerState('view');
            }}
            sx={{justifyContent: 'center', flex: 1}}
            disableAdornments
          >
            Cancel
          </TruvuButton>
        </>
      }
      isOpen={markerState === 'delete' || markerState === 'deleting'}
      onClose={() => {
        setMarkerState('view');
      }}
      onOpen={() => {
        setMarkerState('delete');
      }}
    />
  );
}
