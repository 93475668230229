/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ActiveMemberStats_invite = {
    readonly id: string;
    readonly user: {
        readonly user: {
            readonly id: string;
            readonly monthlyActiveTours: number | null;
            readonly monthlyUploadedTours: number | null;
            readonly topFiveTours: ReadonlyArray<{
                readonly id: string;
                readonly views: number;
                readonly deactivatedViews: number;
                readonly " $fragmentRefs": FragmentRefs<"ActiveMemberTourCard_tour">;
            } | null> | null;
        } | null;
    } | null;
    readonly " $refType": "ActiveMemberStats_invite";
};
export type ActiveMemberStats_invite$data = ActiveMemberStats_invite;
export type ActiveMemberStats_invite$key = {
    readonly " $data"?: ActiveMemberStats_invite$data;
    readonly " $fragmentRefs": FragmentRefs<"ActiveMemberStats_invite">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveMemberStats_invite",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamInviteUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyActiveTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyUploadedTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tour",
              "kind": "LinkedField",
              "name": "topFiveTours",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "views",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deactivatedViews",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ActiveMemberTourCard_tour"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TeamInvite",
  "abstractKey": null
};
})();
(node as any).hash = 'b59f29d149511c081bb6e29795a696e7';
export default node;
