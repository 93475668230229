import React from 'react';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import CancelRounded from '@mui/icons-material/CancelRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {TruvuDialog, UseTruvuDialogResposne} from './TruvuDialog';
import {Stack, Typography} from '@mui/material';

interface TruvuMessageDialogProps extends Partial<UseTruvuDialogResposne> {
  title: React.ReactNode;
  variant: 'success' | 'error' | 'warning' | 'info';
  message?: React.ReactNode;
  actions?: React.ReactNode;
}

export function TruvuMessageDialog({
  title,
  variant,
  message,
  actions,
  ...props
}: TruvuMessageDialogProps) {
  let icon: React.ReactNode = null;

  if (variant === 'success') {
    icon = (
      <CheckCircleRounded
        color="primary"
        sx={{width: '90px', height: '90px'}}
      />
    );
  }
  if (variant === 'error') {
    icon = <CancelRounded color="error" sx={{width: '90px', height: '90px'}} />;
  }
  if (variant === 'warning') {
    icon = (
      <WarningRoundedIcon
        color="warning"
        sx={{width: '90px', height: '90px'}}
      />
    );
  }

  return (
    <TruvuDialog isOpen={false} {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h1" {...props}>
          {title}
        </Typography>
        {icon}
      </Stack>
      {message != null && <Stack py={3}>{message}</Stack>}
      {actions != null && (
        <Stack
          flex={1}
          spacing={2}
          justifyContent="flex-end"
          direction="column"
          pb={1}
        >
          {actions}
        </Stack>
      )}
    </TruvuDialog>
  );
}
