import {Term} from '../../terms/components/TermsComponent';

export const definitions: Term[] = [
  {
    id: '1.1',
    term:
      'Service: Any of the Services we operate, including but not limited to our website, forums, and other related platforms.',
  },
  {
    id: '1.2',
    term:
      'Personal Data: Data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).',
  },
  {
    id: '1.3',
    term:
      'Usage Data: Data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  },
  {
    id: '1.4',
    term:
      'Cookies: Files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.',
  },
  {
    id: '1.5',
    term:
      'Data Controller: The natural or legal person who determines the purposes for which and the manner in which any personal information is, or is to be, processed.',
  },
  {
    id: '1.6',
    term:
      'Data Processors (or Service Providers): Any natural or legal person who processes the data on behalf of the Data Controller.',
  },
  {
    id: '1.7',
    term:
      'Data Subject (or User): Any living individual who is using our Service and is the subject of Personal Data.',
  },
];
