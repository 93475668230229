/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberCardRefetchQueryVariables = {
    id: string;
};
export type MemberCardRefetchQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"MemberCard_invite">;
    } | null;
};
export type MemberCardRefetchQuery = {
    readonly response: MemberCardRefetchQueryResponse;
    readonly variables: MemberCardRefetchQueryVariables;
};



/*
query MemberCardRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...MemberCard_invite
    id
  }
}

fragment ActiveMemberStats_invite on TeamInvite {
  id
  user {
    user {
      id
      monthlyActiveTours
      monthlyUploadedTours
      topFiveTours {
        ...ActiveMemberTourCard_tour
        id
        views
        deactivatedViews
      }
    }
  }
}

fragment ActiveMemberTourCard_tour on Tour {
  id
  title
  views
  description
  thumbnailURL
}

fragment MemberCard_invite on TeamInvite {
  ...ActiveMemberStats_invite
  id
  email
  createdAt
  expiresAt
  accepted
  user {
    isAdmin
    user {
      id
      username
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberCardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MemberCard_invite"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MemberCardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamInviteUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthlyActiveTours",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthlyUploadedTours",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tour",
                        "kind": "LinkedField",
                        "name": "topFiveTours",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "views",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deactivatedViews",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAdmin",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiresAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accepted",
                "storageKey": null
              }
            ],
            "type": "TeamInvite",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1feb38bc2675d0caf912655a6f223a2",
    "id": null,
    "metadata": {},
    "name": "MemberCardRefetchQuery",
    "operationKind": "query",
    "text": "query MemberCardRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MemberCard_invite\n    id\n  }\n}\n\nfragment ActiveMemberStats_invite on TeamInvite {\n  id\n  user {\n    user {\n      id\n      monthlyActiveTours\n      monthlyUploadedTours\n      topFiveTours {\n        ...ActiveMemberTourCard_tour\n        id\n        views\n        deactivatedViews\n      }\n    }\n  }\n}\n\nfragment ActiveMemberTourCard_tour on Tour {\n  id\n  title\n  views\n  description\n  thumbnailURL\n}\n\nfragment MemberCard_invite on TeamInvite {\n  ...ActiveMemberStats_invite\n  id\n  email\n  createdAt\n  expiresAt\n  accepted\n  user {\n    isAdmin\n    user {\n      id\n      username\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c56bc9c63b8a8c9297e67e1502e6e9c1';
export default node;
