import React from 'react';
import RelayRenderer from '../RelayRenderer';
import {ProfilePageQuery} from '../__generated__/ProfilePageQuery.graphql';
import graphql from 'babel-plugin-relay/macro';
import {ProfileDetails} from '../modules/profile/components';

const query = graphql`
  query ProfilePageQuery {
    me {
      user {
        ...ProfileDetails_user
      }
    }
  }
`;

export function ProfilePage() {
  return (
    <RelayRenderer<ProfilePageQuery> query={query}>
      {(data) =>
        data.me?.user ? <ProfileDetails userRef={data.me?.user} /> : null
      }
    </RelayRenderer>
  );
}
