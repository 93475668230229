import React, {PropsWithChildren} from 'react';
import * as yup from 'yup';
import {Form as FormikForm, Formik, FormikHelpers, FormikProps} from 'formik';

import {createStyles, makeStyles} from '@mui/styles';

interface FormProps<Values> {
  initialValues: Values;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  validationSchema?: yup.SchemaOf<Values>;
  innerRef?: React.Ref<FormikProps<Values>>;
  enableReinitialize?: boolean;
  disableSubmit?: boolean;
}

export type FormOnSubmit<Values> = FormProps<Values>['onSubmit'];

const useFormStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      margin: 0,
      padding: 0,
      gap: '10px',
    },
  })
);

export function Form<Values>({
  initialValues,
  onSubmit,
  validationSchema,
  innerRef,
  enableReinitialize,
  children,
}: PropsWithChildren<FormProps<Values>>) {
  const classes = useFormStyles();
  return (
    <Formik<Values>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
      enableReinitialize={enableReinitialize}
    >
      <FormikForm className={classes?.form}>{children}</FormikForm>
    </Formik>
  );
}
