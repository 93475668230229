import React from 'react';
import './JoyStickSvg.css';

import {motion} from 'framer-motion/dist/framer-motion';
import {MotionValue} from 'framer-motion';
interface JoyStickProps {
  color?: MotionValue<string>;
}

export const JoyStickSvg = ({color}: JoyStickProps) => {
  return (
    <svg
      id="joystick_svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
    >
      <g id="Layer_1-2">
        <motion.circle className="cls-1" cx="75.43" cy="75.43" r="71.93" />
        <motion.circle
          className="cls-3"
          cx="75.43"
          cy="75.43"
          r="71.93"
          stroke={color}
        />
        <motion.polyline
          className="cls-2"
          points="96.54 88.74 75.43 109.85 54.32 88.74"
          stroke={color}
        />
        <motion.polyline
          className="cls-2"
          points="54.32 62.12 75.43 41.01 96.54 62.12"
          stroke={color}
        />
      </g>
    </svg>
  );
};
