import {Terms} from '../components/TermsComponent';

export const complianceWithEcommerce: Terms = [
  {
    id: '11.1',
    term: 'Full name: Truvu Ltd',
  },
  {
    id: '11.2',
    term: 'Company number: 15896033',
  },
  {
    id: '11.3',
    term: 'Physical address: 930 High Road, London, England, N12 9RT',
  },
  {
    id: '11.4',
    term: 'Fax number: N/A',
  },
  {
    id: '11.5',
    term: 'Telephone number: N/A',
  },
  {
    id: '11.6',
    term: 'Website address: www.truvu.app',
  },
  {
    id: '11.7',
    term: 'E-mail address: legal@truvu.app',
  },
  {
    id: '11.8',
    term: 'Names of office bearers: Dane Steinhobel & Tristan Coetzee',
  },
  {
    id: '11.9',
    term: 'Registered at: London, United Kingdom',
  },
];
