import React from 'react';
import {PasswordResetForm} from '../modules/passwordReset/components/PasswordResetForm';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import Typography from '@mui/material/Typography';
import {Stack} from '@mui/material';
import {useHistory} from 'react-router';
import {TruvuButton} from '../components/button/TruvuButton';
import {TruvuDivider} from '../components/divider/TruvuDivider';
import {TruvuMenu} from '../components/TruvuMenu';

export function PasswordResetPage() {
  const {push} = useHistory();

  const goToLogin = () => {
    push('/login');
  };

  return (
    <TruvuContainer onGoBack={goToLogin} skinny>
      <TruvuMenu my="20px" title="Reset Your Password" />
      <Stack>
        <PasswordResetForm />
        <TruvuDivider spacing={2} />
        <Typography
          component="label"
          htmlFor="goToLogin"
          mb={1}
          textAlign="center"
        >
          Remembered your password?
        </Typography>
        <TruvuButton id="goToLogin" variant="secondary" onClick={goToLogin}>
          Log in
        </TruvuButton>
      </Stack>
    </TruvuContainer>
  );
}
