import {CircularProgress, Stack, styled, useTheme} from '@mui/material';
import React from 'react';
import ButtonBase, {ButtonBaseProps} from '@mui/material/ButtonBase';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';
import {useHistory} from 'react-router';

export type TruvuButtonVariants =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'default'
  | 'textPrimary'
  | 'text';
export type TruvuIconButtonSizes = 'small' | 'default' | 'large';

export type TruvuButtonBaseProps = ButtonBaseProps & {
  disableAdornments?: boolean;
  variant?: TruvuButtonVariants | undefined;
  size?: TruvuIconButtonSizes;
  loading?: boolean;
  to?: string;
  enableAuxNavigate?: boolean;
  loadingText?: string;
  label?: string;
  upload?: boolean;
  accept?: string;
  InputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

interface StyledButtonProps {
  $variant: TruvuButtonVariants;
  $size: TruvuIconButtonSizes;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const StyledButton = styled(ButtonBase, {
  shouldForwardProp: addShouldNotForwardProps(
    '$variant',
    '$size',
    'InputProps',
    'upload',
    'loadingText'
  ),
})<StyledButtonProps>(({theme, $variant, $size}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: $size === 'large' ? '20px' : $size === 'small' ? '15px' : '18px',
    textAlign: 'center',
    fontWeight: 700,
    justifyContent: 'center',
    width: $size === 'large' ? '50px' : $size === 'small' ? '23px' : '30px',
    height: $size === 'large' ? '50px' : $size === 'small' ? '23px' : '30px',
    borderRadius: '50%',
    ...($variant === 'textPrimary'
      ? {color: theme.palette.primary.main}
      : $variant === 'text'
      ? {color: theme.palette.text.primary}
      : $variant === 'default'
      ? {
          color: theme.palette.text.primary,
          backgroundColor: '#cecece',
        }
      : $variant === 'secondary'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
        }
      : $variant === 'danger'
      ? {
          color: theme.palette.error.main,
          backgroundColor: theme.palette.error.light,
        }
      : {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        }),
  };
});
export function TruvuIconButton({
  loading = false,
  variant = 'primary',
  disableAdornments,
  size = 'default',
  to,
  onClick,
  onAuxClick,
  children,
  enableAuxNavigate,
  ...props
}: TruvuButtonBaseProps) {
  const theme = useTheme();
  const history = useHistory();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (to) {
        history.push(to);
      } else if (onClick != null) {
        onClick(event);
      }
    },
    [onClick, history, to]
  );

  const handleAuxClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (to && enableAuxNavigate) {
        window.open(to, '_blank');
      } else if (onAuxClick != null) {
        onAuxClick(event);
      }
    },
    [enableAuxNavigate, onAuxClick, to]
  );
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {props.label != null && (
        <label
          style={{
            color:
              variant === 'default'
                ? theme.palette.text.primary
                : variant === 'secondary'
                ? theme.palette.secondary.main
                : variant === 'danger'
                ? theme.palette.error.main
                : theme.palette.primary.main,
            cursor: props.disabled ? 'default' : 'pointer',
          }}
          htmlFor={props.id ?? props.label}
        >
          {props.label}
        </label>
      )}
      <StyledButton
        $size={size}
        component={props.upload ? 'label' : 'button'}
        $variant={variant}
        id={props.label}
        onClick={handleClick}
        onAuxClick={handleAuxClick}
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={26}
            color={
              variant === 'primary'
                ? 'secondary'
                : variant === 'danger'
                ? 'error'
                : 'primary'
            }
          />
        ) : (
          children
        )}
        {props.upload === true && (
          <VisuallyHiddenInput
            type="file"
            accept={props.accept}
            {...props.InputProps}
          />
        )}
      </StyledButton>
    </Stack>
  );
}
