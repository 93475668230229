import {Terms} from '../components/TermsComponent';

export const dataProtection: Terms = [
  {
    id: '15.1',
    term: 'General Data Protection Regulation (GDPR) Compliance:',
    terms: [
      {
        id: '15.1.1',
        term:
          'We are committed to protecting your personal data and complying with the General Data Protection Regulation (GDPR) (EU) 2016/679.',
      },
      {
        id: '15.1.2',
        term:
          'For the purposes of this agreement, the terms "personal data," "processing," "data subject," "controller," and "processor" shall have the meanings ascribed to them in the GDPR.',
      },
      {
        id: '15.1.3',
        term:
          'We shall process your personal data in accordance with the principles of the GDPR, ensuring it is:',
        terms: [
          {
            id: '15.1.3.1',
            term: 'Processed lawfully, fairly, and transparently.',
          },
          {
            id: '15.1.3.2',
            term:
              'Collected for specified, explicit, and legitimate purposes and not further processed in a manner incompatible with those purposes.',
          },
          {
            id: '15.1.3.3',
            term: 'Adequate, relevant, and limited to what is necessary.',
          },
          {
            id: '15.1.3.4',
            term: 'Accurate and kept up to date.',
          },
          {
            id: '15.1.3.5',
            term:
              'Kept in a form which permits identification of data subjects for no longer than necessary.',
          },
          {
            id: '15.1.3.6',
            term:
              'Processed in a manner that ensures appropriate security of the personal data.',
          },
        ],
      },
    ],
  },
  {
    id: '15.2',
    term: 'Data Subject Rights',
    terms: [
      {
        id: '15.2.1',
        term: 'You, as a data subject, have the right to:',
        terms: [
          {
            id: '15.2.1.1',
            term:
              'Access your personal data and receive information about its processing.',
          },
          {
            id: '15.2.1.2',
            term: 'Rectification of inaccurate personal data.',
          },
          {
            id: '15.2.1.3',
            term:
              'Erasure of personal data where there is no longer a legal ground for processing.',
          },
          {
            id: '15.2.1.4',
            term: 'Restriction of processing under certain circumstances.',
          },
          {
            id: '15.2.1.5',
            term:
              'Data portability, allowing you to obtain and reuse your personal data.',
          },
          {
            id: '15.2.1.6',
            term:
              'Object to processing based on legitimate interests, public tasks, or direct marketing.',
          },
        ],
      },
    ],
  },
  {
    id: '15.3',
    term: 'Data Security',
    terms: [
      {
        id: '15.3.1',
        term:
          'We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:',
        terms: [
          {
            id: '15.3.1.1',
            term: 'Pseudonymisation and encryption of personal data.',
          },
          {
            id: '15.3.1.2',
            term:
              'Ensuring ongoing confidentiality, integrity, availability, and resilience of processing systems.',
          },
          {
            id: '15.3.1.3',
            term:
              'The ability to restore availability and access to personal data in a timely manner in the event of a physical or technical incident.',
          },
          {
            id: '15.3.1.4',
            term:
              'Regular testing, assessment, and evaluation of the effectiveness of security measures.',
          },
        ],
      },
    ],
  },
  {
    id: '15.4',
    term: '15.4.\tData Breach Notification:',
    terms: [
      {
        id: '15.4.1',
        term:
          'In the event of a personal data breach, we shall notify the relevant supervisory authority without undue delay and, where feasible, within 72 hours of becoming aware of it, unless the breach is unlikely to result in a risk to the rights and freedoms of natural persons.',
      },
      {
        id: '15.4.2',
        term:
          'Where the data breach is likely to result in a high risk to your rights and freedoms, we shall communicate the personal data breach to you without undue delay.',
      },
    ],
  },
];
