import * as React from 'react';
import {useQuery, QueryOptions, NETWORK_ONLY} from 'relay-hooks';
import {GraphQLTaggedNode} from 'react-relay';
import {OperationType} from 'relay-runtime';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Info from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 200,
  },
  chevron: {
    transform: ({open}: {open: boolean}) => `rotate(${open ? '90' : '270'}deg)`,
  },
  textArea: {
    minHeight: 200,
  },
}));

interface QueryRendererProps<R extends OperationType> {
  variables?: R['variables'] | (() => R['variables']);
  query: GraphQLTaggedNode;
  queryOpts?: QueryOptions;
  children: (data: R['response']) => JSX.Element | null; // blegh
  // Allow customization of loading component
  loading?: null | React.ReactElement | (() => React.ReactElement);
}

//R is the queryResponseType
export function RelayRenderer<R extends OperationType = OperationType>({
  query,
  variables,
  children,
  loading,
  queryOpts,
}: QueryRendererProps<R>) {
  const [showMore, setShowMore] = React.useState(false);
  const classes = useStyles({open: showMore});
  const queryVariables =
    // eslint-disable-next-line
    // @ts-expect-error
    typeof variables === 'function' ? variables() : variables;
  const queryOptions: QueryOptions = queryOpts
    ? queryOpts
    : {fetchPolicy: NETWORK_ONLY};
  const {data, error, retry} = useQuery<R>(query, queryVariables, queryOptions);
  // const classes = useStyles();
  if (error) {
    return (
      <Dialog open BackdropComponent={() => null}>
        <Paper className={classes.container}>
          <Info />
          <Typography>Something seems to have gone wrong</Typography>
          <Button
            variant="text"
            onClick={() => {
              setShowMore((m) => !m);
            }}
            endIcon={
              <ChevronLeft fontSize="small" className={classes.chevron} />
            }
          >
            Show {showMore ? 'Less' : 'More'} Information
          </Button>
          {showMore && (
            <textarea className={classes.textArea}>
              {JSON.stringify(error, null, 2)}
            </textarea>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              retry();
            }}
          >
            Retry
          </Button>
        </Paper>
      </Dialog>
    );
  }

  if (!data) {
    // Passing in null can be used to suppress the spinner
    if (loading !== undefined) {
      if (typeof loading === 'function') {
        return loading();
      } else {
        return loading;
      }
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '100svh'}}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children(data);
}
export default RelayRenderer;
