import {Terms} from '../components/TermsComponent';

export const breachOrCancellationByServiceProvider: Terms = [
  {
    id: '10.1',
    term:
      "Service Provider is entitled without notice, in addition to any other remedy available to it at law or under these Terms, including obtaining an interdict, to cancel these Terms, limit or deny such user use of the Website and services, or to claim specific performance of any obligation whether or not the due date for performance has arrived, in either event without prejudice to Service Provider's right to claim damages, should any user:",
    terms: [
      {
        id: '10.1.1',
        term: 'breach any of these Terms?',
      },
      {
        id: '10.1.2',
        term:
          'in the sole discretion of Service Provider, use the Website in an unauthorised manner? or',
      },
      {
        id: '10.1.3',
        term: 'infringe any statute, regulation, ordinance or law.',
      },
    ],
  },
  {
    id: '10.2',
    term:
      'Breach of these Terms entitles Service Provider to take legal action without prior notice to the user and the user agrees to reimburse the costs associated with such legal action to Service Provider on an attorney and own client scale.',
  },
];
