import React, {useState} from 'react';
import Stack from '@mui/material/Stack';
import CookieRounded from '@mui/icons-material/CookieRounded';
import Typography from '@mui/material/Typography';
import {TruvuButton} from './button/TruvuButton';
import {getMetaTagContent} from '../utils/Variables';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router-dom';

export function CookiesBanner() {
  const {search} = useLocation();
  const [cookiesStatus, setCookiesStatus] = useState<
    'unknown' | 'accepted' | 'declined' | 'pending'
  >('unknown');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const disableCookies = searchParams.get('disableCookies');
    if (disableCookies === 'true') {
      setCookiesStatus('declined');
    } else {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      switch (cookiesAccepted) {
        case 'false':
          setCookiesStatus('declined');
          break;
        case 'true': {
          const gaToken = getMetaTagContent('app:ga-token');
          if (gaToken && gaToken !== '__APP_GA_TOKEN__') {
            ReactGA.initialize(gaToken);
          }
          setCookiesStatus('accepted');
          break;
        }
        default:
          setCookiesStatus('pending');
          break;
      }
    }
  }, [search]);

  if (cookiesStatus === 'pending') {
    const onAccept = () => {
      setCookiesStatus('accepted');
      localStorage.setItem('cookiesAccepted', 'true');
      const gaToken = getMetaTagContent('app:ga-token');
      if (gaToken && gaToken !== '__APP_GA_TOKEN__') {
        ReactGA.initialize(gaToken);
      }
    };
    const onDecline = () => {
      setCookiesStatus('declined');
      localStorage.setItem('cookiesAccepted', 'false');
    };

    return (
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        borderRadius={'16px'}
        alignItems={{xs: 'stretch', sm: 'center'}}
        spacing={1}
        position={'fixed'}
        bottom={4}
        left={4}
        right={{xs: 4, sm: 'auto'}}
        bgcolor={'#EDEFF2'}
        p={1}
        flexWrap={'wrap'}
        useFlexGap
        zIndex={1200}
      >
        <Stack direction={'row'} spacing={1}>
          <CookieRounded fontSize={'large'} color={'action'} />
          <Typography variant={'caption'} maxWidth={300}>
            We use cookies to personalize your site experience and analyze site
            performance.
          </Typography>
        </Stack>
        <Stack
          alignItems={'stretch'}
          direction={{xs: 'column', sm: 'row'}}
          spacing={1}
        >
          <TruvuButton onClick={onDecline} variant={'secondary'}>
            Decline
          </TruvuButton>
          <TruvuButton onClick={onAccept} variant={'primary'}>
            Accept
          </TruvuButton>
        </Stack>
      </Stack>
    );
  }

  return null;
}
