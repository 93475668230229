import React from 'react';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import {TruvuMenu} from '../components/TruvuMenu';
import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router';
import {GoogleRedirect} from '../modules/googleRedirect/GoogleRedirect';

export function GoogleRedirectPage() {
  const {push} = useHistory();

  const goToLogin = () => {
    push('/login');
  };

  return (
    <TruvuContainer onGoBack={goToLogin} skinny>
      <TruvuMenu my="20px" title="Sign up to Truvu" />
      <GoogleRedirect />
      <Stack>
        <Typography
          component="label"
          color={'text.secondary'}
          variant="body2"
          htmlFor="goToSignup"
          mb={1}
          textAlign="center"
          mt={2}
        >
          Already have an account?{' '}
          <Typography
            sx={{cursor: 'pointer'}}
            component={'strong'}
            color={'primary'}
            onClick={goToLogin}
          >
            Login
          </Typography>
        </Typography>
      </Stack>
    </TruvuContainer>
  );
}
