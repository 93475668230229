export function sliceIntoChunks(arr: Array<unknown>, chunkCount: number) {
  const res = Array.from({length: chunkCount}, (_x, _i) => []);
  for (let i = 0; i < arr.length; i++) {
    const chunkIndex = i % chunkCount;
    // eslint-disable-next-line
    // @ts-ignore
    res[chunkIndex].push(arr[i]);
  }
  return res;
}
