/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TeamPageQueryVariables = {
    count?: number | null;
    cursor?: unknown | null;
};
export type TeamPageQueryResponse = {
    readonly me: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"TruvuTeamContainer_user">;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Team_members">;
};
export type TeamPageQuery = {
    readonly response: TeamPageQueryResponse;
    readonly variables: TeamPageQueryVariables;
};



/*
query TeamPageQuery(
  $count: Int = 100
  $cursor: Cursor
) {
  ...Team_members
  me {
    user {
      ...TruvuTeamContainer_user
      id
    }
  }
}

fragment ActiveMemberStats_invite on TeamInvite {
  id
  user {
    user {
      id
      monthlyActiveTours
      monthlyUploadedTours
      topFiveTours {
        ...ActiveMemberTourCard_tour
        id
        views
        deactivatedViews
      }
    }
  }
}

fragment ActiveMemberTourCard_tour on Tour {
  id
  title
  views
  description
  thumbnailURL
}

fragment MemberCard_invite on TeamInvite {
  ...ActiveMemberStats_invite
  id
  email
  createdAt
  expiresAt
  accepted
  user {
    isAdmin
    user {
      id
      username
    }
  }
}

fragment Team_members on Query {
  me {
    user {
      company {
        id
        name
      }
      id
    }
  }
  invites(first: $count, after: $cursor) {
    edges {
      cursor
      node {
        id
        email
        accepted
        ...MemberCard_invite
        __typename
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}

fragment TruvuTeamContainer_user on User {
  company {
    id
    name
  }
  profile {
    name
    surname
    profileImageURL
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Me",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TruvuTeamContainer_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Team_members"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Me",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageURL",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "TeamInviteConnection",
        "kind": "LinkedField",
        "name": "invites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamInviteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamInvite",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accepted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamInviteUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "monthlyActiveTours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "monthlyUploadedTours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tour",
                            "kind": "LinkedField",
                            "name": "topFiveTours",
                            "plural": true,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "views",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailURL",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "deactivatedViews",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAdmin",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiresAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "Team_invites",
        "kind": "LinkedHandle",
        "name": "invites"
      }
    ]
  },
  "params": {
    "cacheID": "fbb627f8af4d9b988633750c8a281674",
    "id": null,
    "metadata": {},
    "name": "TeamPageQuery",
    "operationKind": "query",
    "text": "query TeamPageQuery(\n  $count: Int = 100\n  $cursor: Cursor\n) {\n  ...Team_members\n  me {\n    user {\n      ...TruvuTeamContainer_user\n      id\n    }\n  }\n}\n\nfragment ActiveMemberStats_invite on TeamInvite {\n  id\n  user {\n    user {\n      id\n      monthlyActiveTours\n      monthlyUploadedTours\n      topFiveTours {\n        ...ActiveMemberTourCard_tour\n        id\n        views\n        deactivatedViews\n      }\n    }\n  }\n}\n\nfragment ActiveMemberTourCard_tour on Tour {\n  id\n  title\n  views\n  description\n  thumbnailURL\n}\n\nfragment MemberCard_invite on TeamInvite {\n  ...ActiveMemberStats_invite\n  id\n  email\n  createdAt\n  expiresAt\n  accepted\n  user {\n    isAdmin\n    user {\n      id\n      username\n    }\n  }\n}\n\nfragment Team_members on Query {\n  me {\n    user {\n      company {\n        id\n        name\n      }\n      id\n    }\n  }\n  invites(first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        id\n        email\n        accepted\n        ...MemberCard_invite\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n\nfragment TruvuTeamContainer_user on User {\n  company {\n    id\n    name\n  }\n  profile {\n    name\n    surname\n    profileImageURL\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b6cace33d893746df948b11e3b444b98';
export default node;
