import React from 'react';
import {TruvuButton} from '../components/button/TruvuButton';
import {useHistory} from 'react-router';
import Typography from '@mui/material/Typography';
import TruvuLogoSvg from '../assets/svg/truvu-logo.svg';
import {Stack, styled} from '@mui/material';

export function NotFoundPage() {
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  };

  return (
    <StyledContainer spacing={2}>
      <img
        src={TruvuLogoSvg}
        alt="Truvu"
        style={{width: '70px', maxWidth: '70px'}}
      />
      <Typography>
        We are sorry, but the page you are trying to access does not exist.
      </Typography>
      <TruvuButton onClick={goHome}>Go Home</TruvuButton>
    </StyledContainer>
  );
}

const StyledContainer = styled(Stack)(() => ({
  minHeight: '100dvh',
  flex: 1,
  flexGrow: 1,
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '20px 20px',
  maxWidth: '500px',
  justifyContent: 'center',
}));
