import React, {useState} from 'react';

import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import lottieJson from '../../assets/json/full-page-spinner.json';

export function FullscreenLoader() {
  const [firstLoopDone, setFirstLoopDone] = useState(false);
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Lottie
        segments={firstLoopDone ? [56, 80] : [0, 56]}
        onLoopComplete={() => setFirstLoopDone(true)}
        animationData={lottieJson}
        play
        style={{width: 150, height: 150}}
      />
    </div>
  );
}
