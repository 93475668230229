import React from 'react';
import {Stack, StackProps, styled} from '@mui/material';
import {TourAppBarProps, TruvuAppBar} from './TruvuAppBar';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

interface StyledContainerProps {
  $skinny?: boolean;
}

const StyledContainer = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps('$skinny'),
})<StyledContainerProps>(({$skinny}) => ({
  minHeight: '100svh',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px 20px',
  maxWidth: $skinny ? '500px' : '1200px',
}));

interface TruvuContainerProps extends StackProps {
  scrollable?: boolean;
  onGoBack?: () => void;
  showAccount?: boolean;
  skinny?: boolean;
  AppBarProps?: TourAppBarProps;
}

export function TruvuContainer({
  children,
  scrollable,
  onGoBack,
  showAccount,
  AppBarProps,
  skinny,
  ...props
}: TruvuContainerProps) {
  return (
    <StyledContainer $skinny={skinny} {...props}>
      <TruvuAppBar showAccount={showAccount} onGoBack={onGoBack} />
      {children}
    </StyledContainer>
  );
}
