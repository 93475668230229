import React from 'react';
import {ToursPageQuery} from '../__generated__/ToursPageQuery.graphql';
import RelayRenderer from '../RelayRenderer';
import {Tours} from '../modules/tourLanding/components';
import graphql from 'babel-plugin-relay/macro';
import {TruvuMenu} from '../components/TruvuMenu';
import {TruvuToursContainer} from '../components/containers/TruvuToursContainer';
import {ToursAdd} from '../modules/tourLanding/components/ToursAdd';

const query = graphql`
  query ToursPageQuery($count: Int = 10, $cursor: Cursor) {
    ...Tours_tours
    invites {
      edges {
        cursor
        node {
          id
        }
      }
    }
    me {
      user {
        ...TruvuToursContainer_user
        ...Tours_user
        ...ToursAdd_user
        company {
          license {
            planName
            monthlyActiveTours
            monthlyUploadedTours
            uploadLimit
            quantity
          }
        }
      }
    }
  }
`;

export function ToursPage() {
  return (
    <RelayRenderer<ToursPageQuery> query={query}>
      {(data) => {
        return (
          <TruvuToursContainer userRef={data.me?.user}>
            <TruvuMenu
              title="My Tours"
              action={
                data.me?.user ? <ToursAdd userRef={data.me?.user} /> : null
              }
              sticky
            />
            <Tours data={data} />
          </TruvuToursContainer>
        );
      }}
    </RelayRenderer>
  );
}
