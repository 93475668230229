import React, {ReactNode} from 'react';
import {useTruvuDialog} from '../../../components/dialog/TruvuDialog2';
import {MenuRounded} from '@mui/icons-material';
import {TourDialog} from './TourDialog';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';
import {TOUR_DRAWER_WIDTH} from './TourDrawer';
import {addShouldNotForwardProps} from '../../../utils/addShouldNotForwardProps';
import {useSearchParam} from 'react-use';
import {useHistory} from 'react-router';
import Typography from '@mui/material/Typography';

interface TourMenuProps {
  children: ReactNode;
  drawerIsOpen: boolean;
  mode: string | null;
}

export function TourMenu({drawerIsOpen, mode, children}: TourMenuProps) {
  const history = useHistory();
  const menuDialog = useTruvuDialog();
  const disabledControls = useSearchParam('disabledControls');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');

  return (
    <>
      <MenuHandel
        onClick={menuDialog.onOpen}
        $drawerIsOpen={drawerIsOpen}
        $drawerWidth={
          splitDisableControls?.includes('markers') ? '0' : TOUR_DRAWER_WIDTH
        }
      >
        <MenuRounded
          sx={{color: '#fff', width: 45, height: 45}}
          fontSize="large"
        />
      </MenuHandel>
      <TourDialog
        title="Player Menu"
        subtitle={mode != null ? `(${mode} Mode)` : undefined}
        {...menuDialog}
        ContentProps={{pb: 1}}
      >
        {children}
        <PlayerTerms>
          <PlayerTermTypography
            onClick={() => {
              history.push('/privacy');
            }}
          >
            Privacy
          </PlayerTermTypography>
          <PlayerTermTypography>/</PlayerTermTypography>
          <PlayerTermTypography
            onClick={() => {
              history.push('/terms');
            }}
          >
            Terms
          </PlayerTermTypography>
          <PlayerTermTypography>/</PlayerTermTypography>
          <PlayerTermTypography
            onClick={() => {
              history.push('/licenses');
            }}
          >
            Licenses
          </PlayerTermTypography>
        </PlayerTerms>
      </TourDialog>
    </>
  );
}

const MenuHandel = styled(Box, {
  shouldForwardProp: addShouldNotForwardProps('$drawerIsOpen', '$drawerWidth'),
})<{$drawerIsOpen: boolean; $drawerWidth: string}>(
  ({theme, $drawerIsOpen, $drawerWidth}) => ({
    zIndex: theme.zIndex.appBar,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(0.5),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginRight: $drawerIsOpen ? $drawerWidth : 0,
      transition: theme.transitions.create(['margin-right'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.sharp,
      }),
    },
  })
);

export const PlayerTermTypography = styled(Typography)(({theme}) => ({
  fontSize: '0.6rem',
  color: theme.palette.text.secondary,
}));

export const PlayerTerms = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(0.25),
  color: '#758088',
  zIndex: 5,
  '& > *:nth-child(odd)': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
