import {Terms} from '../components/TermsComponent';

export const confidentiality: Terms = [
  {
    id: '9.1',
    term:
      'By subscribing as a user, you agree that you shall hold in the strictest confidence and not disclose to any third party information acquired in connection with any aspect of the products and/or services offered by Service Provider. You shall notify Service Provider should you discover any loss or unauthorised disclosure of the information.',
  },
  {
    id: '9.2',
    term:
      'Any information or material sent to Service Provider will be deemed not to be confidential, unless otherwise agreed in writing by the user and Service Provider.',
  },
];
