import React from 'react';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {TourMenu} from '../../components/TourMenu';

interface TourEditMenuProps {
  drawerIsOpen: boolean;
  tourId: string;
}

export function TourEditMenu({drawerIsOpen, tourId}: TourEditMenuProps) {
  return (
    <TourMenu drawerIsOpen={drawerIsOpen} mode="Edit">
      <TruvuButton to={`/previewplayer?tourId=${tourId}`} variant="primary">
        Preview Tour
      </TruvuButton>
      <TruvuButton to="/" variant="danger">
        Save & Exit
      </TruvuButton>
    </TourMenu>
  );
}
