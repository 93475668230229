/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserRegisterInput = {
    username: string;
    password: string;
    inviteToken?: string | null;
    referredBy?: string | null;
    name?: string | null;
    surname?: string | null;
    contactNumber?: string | null;
    turnstileToken: string;
    acceptedTerms: boolean;
};
export type SignupFormMutationVariables = {
    input: UserRegisterInput;
};
export type SignupFormMutationResponse = {
    readonly userRegister: {
        readonly user: {
            readonly username: string;
        };
    };
};
export type SignupFormMutation = {
    readonly response: SignupFormMutationResponse;
    readonly variables: SignupFormMutationVariables;
};



/*
mutation SignupFormMutation(
  $input: UserRegisterInput!
) {
  userRegister(input: $input) {
    user {
      username
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserRegisterPayload",
        "kind": "LinkedField",
        "name": "userRegister",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserRegisterPayload",
        "kind": "LinkedField",
        "name": "userRegister",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b85d7fe85b36a91cb215e1fc797f7f2",
    "id": null,
    "metadata": {},
    "name": "SignupFormMutation",
    "operationKind": "mutation",
    "text": "mutation SignupFormMutation(\n  $input: UserRegisterInput!\n) {\n  userRegister(input: $input) {\n    user {\n      username\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b6189fa99c8d401e7379aac89683b73e';
export default node;
