import {Term} from '../../terms/components/TermsComponent';

export const disclosureOfData: Term[] = [
  {
    id: '8.1',
    term:
      'If we are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.',
  },
  {
    id: '8.2',
    term:
      'Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
  },
];
