import {Terms} from '../components/TermsComponent';

export const cloudComputing: Terms = [
  {
    id: '16.1',
    term: 'Use of Cloud Services:',
    terms: [
      {
        id: '16.1.1',
        term:
          'We utilise Cloud-based services located in western Europe to store, compute and manage data, ensuring scalability, efficiency, and accessibility.',
      },
      {
        id: '16.1.2',
        term:
          'All cloud services used are compliant with relevant data protection regulations and adhere to industry standards for data security and privacy.',
      },
      {
        id: '16.1.3',
        term:
          'A copy of our Data Privacy Policy is available at your request with any queries to be directed to our DPO at legal@truvu.app.',
      },
    ],
  },
  {
    id: '16.2',
    term: 'Data Location and Transfers:',
    terms: [
      {
        id: '16.2.1',
        term:
          'Your data may be stored and processed on servers located in different jurisdictions. We ensure that any data transfers comply with applicable data protection laws, including GDPR.',
      },
      {
        id: '16.2.2',
        term:
          'We shall implement appropriate safeguards, such as Standard Contractual Clauses or equivalent mechanisms, for any data transfers outside the European Economic Area (EEA).',
      },
    ],
  },
  {
    id: '16.3',
    term: 'Third-Party Service Providers:',
    terms: [
      {
        id: '16.3.1',
        term:
          'We may engage third-party service providers to perform certain functions related to cloud computing. These providers are carefully selected and are required to process personal data in accordance with our instructions and data protection policies.',
      },
      {
        id: '16.3.2',
        term:
          'We remain responsible for the protection of your personal data and shall ensure that any third-party service providers maintain appropriate security measures.',
      },
    ],
  },
  {
    id: '16.4',
    term: 'Data Retention and Deletion:',
    terms: [
      {
        id: '16.4.1',
        term:
          'Personal data stored in the cloud shall be retained only for as long as necessary for the purposes outlined in this agreement or as required by law.',
      },
      {
        id: '16.4.2',
        term:
          'You may request the deletion of your personal data by contacting us. We shall take reasonable steps to ensure the prompt deletion of personal data, subject to any legal obligations requiring its retention.',
      },
    ],
  },
  {
    id: '16.5',
    term: 'Right to Migrate Cloud Services:',
    terms: [
      {
        id: '16.5.1',
        term:
          'We reserve the right to migrate our cloud services to different cloud service providers or platforms at our discretion.',
      },
      {
        id: '16.5.2',
        term:
          'In the event of such migration, we will provide you with written notice.',
      },
      {
        id: '16.5.3',
        term:
          'During this notice period, we will ensure that the new cloud service provider or platform complies with all relevant data protection regulations and industry standards for data security and privacy.',
      },
      {
        id: '16.5.4',
        term:
          'The migration of cloud services will not adversely affect the availability, security, or performance of the services provided to you.',
      },
      {
        id: '16.5.5',
        term:
          'You acknowledge and agree that the migration of cloud services is a necessary measure to ensure the continuous improvement and scalability of our services.',
      },
      {
        id: '16.5.6',
        term:
          'Any specific instructions or actions required from you to facilitate the migration will be clearly communicated within the notice period to which you agree by virtue of acceptance of this agreement.',
      },
    ],
  },
];
