import {useSearchParam} from 'react-use';
import {useQuery} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {FullscreenLoader} from '../../components/loader/FullscreenLoader';
import {TourPreview} from '../../modules/tourPlayer/tourPlayerPreview/components/TourPreview';
import React from 'react';
import {TourPreviewPageQuery} from '../../__generated__/TourPreviewPageQuery.graphql';
import {TourNotFoundMessage} from '../../modules/tourPlayer/components/TourNotFoundMessage';
import {TourErrorDialog} from '../../modules/tourPlayer/components/TourErrorDialog';
import {TourInvalidLinkDialog} from '../../modules/tourPlayer/components/TourInvalidLinkDialog';

const query = graphql`
  query TourExtQuery($tourID: ID!) {
    tour: node(id: $tourID) {
      ...TourPreview_tour
    }
  }
`;

function TourExtComponent({tourID}: {tourID: string}) {
  const {data, isLoading, error} = useQuery<TourPreviewPageQuery>(
    query,
    {tourID},
    {
      fetchPolicy: 'network-only',
      networkCacheConfig: undefined,
    }
  );

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: '#758089',
          height: '100svh',
        }}
      >
        <FullscreenLoader />
      </div>
    );
  }

  if (error != null) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return <TourErrorDialog isOpen onClose={() => {}} />;
  }

  if (data?.tour == null) {
    return <TourNotFoundMessage />;
  }

  return <TourPreview tourRef={data.tour} external />;
}

export function TourExt() {
  const tourID = useSearchParam('tourId');

  if (tourID == null) {
    return <TourInvalidLinkDialog />;
  }

  return <TourExtComponent tourID={tourID} />;
}
