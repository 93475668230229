import Typography from '@mui/material/Typography';
import React from 'react';
import {Stack} from '@mui/material';
import {TruvuDialog} from '../../../components/dialog/TruvuDialog';
import {CancelRounded} from '@mui/icons-material';
import {TruvuButton} from '../../../components/button/TruvuButton';

export function TourInvalidLinkDialog() {
  return (
    <TruvuDialog isOpen>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h1">Invalid Link</Typography>
        <CancelRounded color="error" sx={{width: '90px', height: '90px'}} />
      </Stack>
      <Stack py={3}>
        <Typography>
          This is not a valid tour link. Please check the link and try again.
        </Typography>
      </Stack>
      <Stack
        flex={1}
        spacing={2}
        justifyContent="flex-end"
        direction="column"
        pb={1}
      >
        <TruvuButton
          variant="primary"
          onClick={() => {
            window.open('go.truvu.com', '_self');
          }}
        >
          Go Home
        </TruvuButton>
      </Stack>
    </TruvuDialog>
  );
}
