/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ResendEmailActivationMutationVariables = {
    email: string;
};
export type ResendEmailActivationMutationResponse = {
    readonly userActivationEmailResend: {
        readonly success: boolean;
    };
};
export type ResendEmailActivationMutation = {
    readonly response: ResendEmailActivationMutationResponse;
    readonly variables: ResendEmailActivationMutationVariables;
};



/*
mutation ResendEmailActivationMutation(
  $email: String!
) {
  userActivationEmailResend(input: {email: $email}) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UserActivationEmailResendPayload",
    "kind": "LinkedField",
    "name": "userActivationEmailResend",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendEmailActivationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResendEmailActivationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a47b31009cd352a85ebf5c1d332b5ff5",
    "id": null,
    "metadata": {},
    "name": "ResendEmailActivationMutation",
    "operationKind": "mutation",
    "text": "mutation ResendEmailActivationMutation(\n  $email: String!\n) {\n  userActivationEmailResend(input: {email: $email}) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '974aec1c79b4aa68b026a296fc862257';
export default node;
