import {useState} from 'react';

export const useTruvuDialog = (defaultState = false) => {
  const [openDialog, setOpenDialog] = useState(defaultState);

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const onToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  return {
    openDialog,
    setOpenDialog,
    onCloseDialog,
    onOpenDialog,
    onToggleDialog,
  };
};
