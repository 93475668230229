import React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Avatar from '@mui/material/Avatar';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import useMediaQuery from '@mui/material/useMediaQuery';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface UploadThumbnailProps {
  onChangeThumbnail: (file: File) => void;
  src: string;
}

export function UploadThumbnail({
  onChangeThumbnail,
  src,
}: UploadThumbnailProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {notify} = useSnackbarContext();

  const handleUploadFile = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0] != null) {
        onChangeThumbnail(event.target.files[0]);
      } else {
        notify({variant: 'info', message: 'No file selected'});
      }
    },
    [onChangeThumbnail, notify]
  );
  const UploadIcon = (
    <CameraAltIcon
      color="inherit"
      sx={{
        color: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 35,
        height: 38,
        opacity: 0.7,
      }}
    />
  );
  const InputElement = (
    <VisuallyHiddenInput
      onChange={handleUploadFile}
      type="file"
      accept=".jpg, .jpeg, .png"
    />
  );

  if (isMobile) {
    return (
      <ThumbnailMobile
        src={src}
        UploadIcon={UploadIcon}
        InputElement={InputElement}
      />
    );
  }

  return (
    <ThumbnailDesktop
      src={src}
      UploadIcon={UploadIcon}
      InputElement={InputElement}
    />
  );
}

interface ThumbnailComponentProps {
  src: string;
  UploadIcon: React.ReactNode;
  InputElement: React.ReactNode;
}

function ThumbnailMobile({
  src,
  UploadIcon,
  InputElement,
}: ThumbnailComponentProps) {
  return (
    <IconButton
      component="label"
      sx={{padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}
    >
      <Avatar src={src} sx={{width: 93, height: 93, opacity: 0.9}} />
      {UploadIcon}
      {InputElement}
    </IconButton>
  );
}
function ThumbnailDesktop({
  src,
  UploadIcon,
  InputElement,
}: ThumbnailComponentProps) {
  const [mouseOver, setMouseOver] = React.useState(false);

  return (
    <IconButton
      component="label"
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      sx={{
        padding: 0,
        backgroundColor: mouseOver ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
      }}
    >
      <Avatar
        src={src}
        sx={{width: 93, height: 93, opacity: mouseOver ? 0.6 : 1}}
      />
      {mouseOver && UploadIcon}
      {InputElement}
    </IconButton>
  );
}
