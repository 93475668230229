import {Terms} from '../components/TermsComponent';

export const thirdPartyContent: Terms = [
  {
    id: '3.1',
    term:
      "Service Provider may provide links to third party websites on the Website. These links are provided to the user for convenience purposes only and Service Provider does not endorse, nor does the inclusion of any link imply Service Provider's endorsement of, such websites, their owners, licensees or administrators or such websites' content or security practices and operations.",
  },
  {
    id: '3.2',
    term:
      'While Service Provider tries to provide links only to reputable websites or online partners, Service Provider cannot accept responsibility or liability for the information provided on other websites. Linked websites or pages are not under, nor subject to, the control of Service Provider. Service Provider is not responsible for and gives no warranties or makes any representations in respect of the privacy policies or practices of linked or any third party or advertised websites on the Website.',
  },
  {
    id: '3.3',
    term:
      'You agree that Service Provider shall not be held liable, directly or indirectly, in any way for the content, the use or inability to use or access any linked website or any link(s) contained in a linked website, nor for any loss or damage of any sort incurred as a result of any dealings with, or as the result of the presence of such third party linked websites on the Website. Any dealings that you may have with any linked websites, including advertisers, found on the Website, are solely between you and the third party website.',
  },
];
