import {Capacitor} from '@capacitor/core';
import {Toast} from '@capacitor/toast';
import {App} from '@capacitor/app';

let lastBackPress = 0;
const BACK_PRESS_THRESHOLD = 2000; // 2 seconds

const showToast = async (message: string) => {
  await Toast.show({
    text: message,
    duration: 'short',
    position: 'bottom',
  });
};

const exitApp = () => {
  if (Capacitor.isNativePlatform()) {
    App.exitApp();
  } else {
    console.log('App would exit here on a real device');
  }
};

export const setupCustomBackButtonHandler = () => {
  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
    App.addListener('backButton', async () => {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        const now = new Date().getTime();
        if (now - lastBackPress < BACK_PRESS_THRESHOLD) {
          exitApp();
        } else {
          await showToast('Press back again to exit');
          lastBackPress = now;
        }
      }
    });
  }
};
