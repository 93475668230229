import React, {useCallback} from 'react';
import graphql from 'babel-plugin-relay/macro';
import * as yup from 'yup';
import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {useMutation} from 'react-relay-mutation';
import {RequestPasswordResetFormMutation} from '../../../__generated__/RequestPasswordResetFormMutation.graphql';

interface RequestPasswordResetValues {
  email: string;
}

const requestPasswordResetValidationSchema: yup.SchemaOf<RequestPasswordResetValues> = yup.object(
  {
    email: yup
      .string()
      .email('Not a valid email.')
      .required('Email is a required field.'),
  }
);

export function RequestPasswordResetForm() {
  const {notify} = useSnackbarContext();

  const [requestPasswordReset] = useMutation<RequestPasswordResetFormMutation>(
    graphql`
      mutation RequestPasswordResetFormMutation(
        $input: UserRequestPasswordResetInput!
      ) {
        userRequestPasswordReset(input: $input) {
          success
        }
      }
    `,
    {
      onCompleted: (response) => {
        if (response && response.userRequestPasswordReset) {
          notify({
            message: 'Password reset email sent successfully',
            variant: 'success',
          });
        }
      },
    }
  );

  const handleSubmit = useCallback<FormOnSubmit<RequestPasswordResetValues>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);

      try {
        const {email} = values;
        await requestPasswordReset({
          variables: {
            input: {
              email: email.toLowerCase(),
            },
          },
        });
      } catch (e) {
        notify({
          message: 'Error sending password reset email',
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [notify, requestPasswordReset]
  );

  return (
    <Form<RequestPasswordResetValues>
      initialValues={{email: ''}}
      onSubmit={handleSubmit}
      validationSchema={requestPasswordResetValidationSchema}
    >
      <TruvuTextField name="email" placeholder="E-mail" formikField />
      <TruvuButton sx={{mt: 1}} formikSubmit>
        Send Password Reset Email
      </TruvuButton>
    </Form>
  );
}
