import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-relay-mutation';
import graphql from 'babel-plugin-relay/macro';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import {Stack, Typography} from '@mui/material';
import {TruvuDivider} from '../components/divider/TruvuDivider';
import {TruvuButton} from '../components/button/TruvuButton';
import {TruvuMenu} from '../components/TruvuMenu';
import {Cancel, CheckCircle} from '@mui/icons-material';
import {LoadingSpinner} from '../assets/LoadingSpinner';

// Define the tourReactivation mutation
const TourReactivationMutation = graphql`
  mutation TourReactivationPageMutation($tourId: String!) {
    tourReactivate(input: {id: $tourId}) {
      tour {
        id
        title
      }
    }
  }
`;

export function TourReactivationPage() {
  const [commit] = useMutation(TourReactivationMutation);
  const location = useLocation();
  const [activationState, setActivationState] = useState<
    'pending' | 'success' | 'failure'
  >('pending'); // 'pending', 'success', or 'failure'
  const history = useHistory();

  // Function to extract query parameters
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const tourId = query.get('tourId');

  useEffect(() => {
    if (tourId) {
      commit({
        variables: {tourId},
        onCompleted: (_response) => {
          // Handle successful activation
          setActivationState('success');
        },
        onError: (_err) => {
          // Handle network or server errors
          setActivationState('failure');
        },
      });
    }
  }, [tourId, commit]);

  useEffect(() => {
    if (activationState === 'success') {
      const timer = setTimeout(() => {
        history.push('/'); // Redirect to the home page
      }, 2000); // Wait for 2 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [activationState, history]);

  // Render different components based on the activationState
  let content;
  if (activationState === 'pending') {
    content = (
      <TruvuMenu
        mt="20px"
        title="Please wait while we re-activate tour tour."
        action={<LoadingSpinner width={'70px'} height={'70px'} />}
      />
    );
  } else if (activationState === 'success') {
    content = (
      <>
        <Stack>
          <TruvuMenu
            mt="20px"
            title="Re-Activation Successful"
            action={
              <CheckCircle
                color="success"
                sx={{width: 40, height: 40}}
                fontSize="inherit"
              />
            }
          />
          <TruvuDivider spacing={1} />
          <Typography
            component="label"
            variant="body2"
            htmlFor="goToSignup"
            mb={1}
            textAlign="center"
          >
            Your account has been successfully activated!
          </Typography>
          <TruvuButton id="goToSignup" variant="secondary">
            Return to Login
          </TruvuButton>
        </Stack>
      </>
    );
  } else if (activationState === 'failure') {
    content = (
      <>
        <Stack>
          <TruvuMenu
            mt="20px"
            title="Re-activation Failed"
            action={
              <Cancel
                color="error"
                sx={{width: 40, height: 40}}
                fontSize="inherit"
              />
            }
          />
          <TruvuDivider spacing={1} />
          <Typography
            component="label"
            variant="body2"
            htmlFor="goToSignup"
            mb={1}
            textAlign="center"
          >
            There was a problem re-activating your tour. Please try again later.
          </Typography>
          <TruvuButton id="goToSignup" variant="secondary">
            Return to Login
          </TruvuButton>
        </Stack>
      </>
    );
  }

  return <TruvuContainer skinny>{content}</TruvuContainer>;
}
