import {Capacitor} from '@capacitor/core';
import {SplashScreen} from '@capacitor/splash-screen';

export class SplashScreenPlugin {
  static async hide() {
    if (Capacitor.isNativePlatform()) {
      // We're in a native (iOS, Android) environment
      try {
        await SplashScreen.hide();
        console.log('Splash screen hidden in native environment');
      } catch (error) {
        console.error('Error hiding splash screen:', error);
      }
    } else {
      // We're in a web environment
      console.log('SplashScreen.hide() called in web environment');
    }
  }
}
