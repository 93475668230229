import React from 'react';
import {TruvuButtonBase, TruvuButtonBaseProps} from './TruvuButtonBase';
import {useFormikContext} from 'formik';

export function TruvuButtonFormik(props: TruvuButtonBaseProps) {
  const {isSubmitting} = useFormikContext();

  return (
    <TruvuButtonBase
      disabled={isSubmitting}
      loading={isSubmitting}
      type="submit"
      {...props}
    />
  );
}
