/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CompanySaveInput = {
    name: string;
    address?: string | null;
    vatNo?: string | null;
    countryCode: string;
    referredBy?: string | null;
    logoImage?: unknown | null;
};
export type ProfileEditCompanyDetailsMutationVariables = {
    input: CompanySaveInput;
};
export type ProfileEditCompanyDetailsMutationResponse = {
    readonly companySave: {
        readonly company: {
            readonly logoimageurl: string;
            readonly name: string;
            readonly address: string;
            readonly vatno: string;
        };
    };
};
export type ProfileEditCompanyDetailsMutation = {
    readonly response: ProfileEditCompanyDetailsMutationResponse;
    readonly variables: ProfileEditCompanyDetailsMutationVariables;
};



/*
mutation ProfileEditCompanyDetailsMutation(
  $input: CompanySaveInput!
) {
  companySave(input: $input) {
    company {
      logoimageurl
      name
      address
      vatno
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoimageurl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatno",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileEditCompanyDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanySavePayload",
        "kind": "LinkedField",
        "name": "companySave",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileEditCompanyDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanySavePayload",
        "kind": "LinkedField",
        "name": "companySave",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3319952dd36160ee4aee0040cd3d34c",
    "id": null,
    "metadata": {},
    "name": "ProfileEditCompanyDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileEditCompanyDetailsMutation(\n  $input: CompanySaveInput!\n) {\n  companySave(input: $input) {\n    company {\n      logoimageurl\n      name\n      address\n      vatno\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f0124c586d0d2ee67d63bb634b9e7e41';
export default node;
