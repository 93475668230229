import React from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {Dialog as MuiDialog, useMediaQuery, Zoom} from '@mui/material';
import {Theme} from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import {createStyles, makeStyles} from '@mui/styles';
import {DialogProps as MuiDialogProps} from '@mui/material/Dialog/Dialog';
import IconButton from '@mui/material/IconButton';
import {Close} from '@mui/icons-material';

export const useTruvuDialog = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return {
    isOpen,
    onClose: () => {
      setIsOpen(false);
    },
    onOpen: () => {
      setIsOpen(true);
    },
  };
};

export type UseTruvuDialogResposne = ReturnType<typeof useTruvuDialog>;

const useTruvuDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(25,28,31,0.6)',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      maxWidth: '450px',
      margin: 0,
      backgroundImage: 'none',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        bottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  })
);

export function TruvuDialog({
  children,
  isOpen,
  onClose,
  ...props
}: Omit<MuiDialogProps, 'open'> & {isOpen: boolean}) {
  const classes = useTruvuDialogStyles();
  return (
    <MuiDialog
      aria-labelledby="optix-dialog"
      aria-describedby="optix-generic-dialog"
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      classes={{root: classes.container, paper: classes.paper}}
      {...props}
      onClose={onClose}
      open={isOpen}
    >
      <IconButton
        onClick={() => {
          onClose?.({}, 'backdropClick');
        }}
        size="small"
        sx={{mb: 0.5, ml: -1, mt: -0.5, alignSelf: 'flex-start'}}
      >
        <Close fontSize="medium" />
      </IconButton>
      {children}
    </MuiDialog>
  );
}
const Transition = React.forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Slide direction="up" ref={ref} {...props}>
        {children}
      </Slide>
    );
  }
  return (
    <Zoom ref={ref} {...props}>
      {children}
    </Zoom>
  );
});
