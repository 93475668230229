import React, {useEffect, useRef, useState} from 'react';
import {
  Fab,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  Zoom,
} from '@mui/material';
import {BugReport, Check} from '@mui/icons-material';
import {Theme} from '@mui/material/styles';

import * as Sentry from '@sentry/react';
import Dialog from '@mui/material/Dialog';
import {Form, FormOnSubmit} from './form/Form';
import {
  TruvuDialogTransition,
  useTruvuDialogStyles,
} from './dialog/TruvuDialog2';
import {TruvuTextField} from './textField';
import {TruvuButton} from './button/TruvuButton';
import {InferType, object, string} from 'yup';
import {useSearchParam} from 'react-use';

const validationSchema = object({
  name: string()
    .max(30, 'Maximum characters reached')
    .required('Name is a required field.'),
  email: string()
    .trim()
    .email('Invalid email')
    .required('Email is a required field.'),
  comment: string().required('Comment is a required field.'),
});

const eventId = Sentry.captureMessage('User Feedback');

export function FeedbackButton() {
  const disabledControls = useSearchParam('disabledControls');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');

  const classes = useTruvuDialogStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [sendFeedbackStatus, setSendFeedbackStatus] = useState<
    'idle' | 'active' | 'sending' | 'success' | 'error'
  >('idle');

  const timeoutRef = useRef<NodeJS.Timeout>();

  const onSubmit: FormOnSubmit<InferType<typeof validationSchema>> = (
    values,
    formikHelpers
  ) => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    Sentry.captureUserFeedback({
      name: values.name.trim(),
      email: values.email.trim(),
      comments: values.comment.trim(),
      event_id: eventId,
    });
    setSendFeedbackStatus('success');
    formikHelpers.resetForm();
    timeoutRef.current = setTimeout(() => {
      setSendFeedbackStatus('idle');
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Dialog
        open={sendFeedbackStatus === 'active'}
        onClose={() => {
          setSendFeedbackStatus('idle');
        }}
        aria-labelledby="feedback-dialog"
        aria-describedby="feedback-generic-dialog"
        TransitionComponent={TruvuDialogTransition}
        maxWidth="md"
        fullWidth
        classes={{root: classes.container, paper: classes.paperMD}}
      >
        <Typography variant={'h1'} fontWeight={700} mb={2}>
          Send Feedback
        </Typography>
        <Stack spacing={1.5} overflow={'auto'}>
          <Form
            initialValues={{name: '', email: '', comment: ''}}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              justifyContent={'space-between'}
              spacing={1}
            >
              <TruvuTextField
                name="name"
                label="Name"
                placeholder="Your Name"
                shrinkLabel
                fullWidth
                formikField
              />
              <TruvuTextField
                name="email"
                label="Email"
                placeholder="your.email@example.org"
                fullWidth
                shrinkLabel
                formikField
              />
            </Stack>
            <TruvuTextField
              name="comment"
              label="Comment"
              placeholder="What's the bug? What did you expect? What's your feedback?"
              minRows={2}
              shrinkLabel
              multiline
              formikField
            />
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              justifyContent={'space-between'}
              spacing={1}
            >
              <TruvuButton sx={{flex: 1}} formikSubmit>
                Send feedback
              </TruvuButton>
              <TruvuButton
                sx={{flex: 1}}
                variant="secondary"
                onClick={() => {
                  setSendFeedbackStatus('idle');
                }}
              >
                Cancel
              </TruvuButton>
            </Stack>
          </Form>
        </Stack>
      </Dialog>
      <Zoom
        in={
          Sentry.isInitialized() && !splitDisableControls?.includes('feedback')
        }
      >
        <BugReportFab
          variant={isMobile ? 'circular' : 'extended'}
          color={sendFeedbackStatus === 'success' ? 'success' : 'primary'}
          size={isMobile ? 'small' : 'medium'}
          aria-label="feedback-button"
          onClick={() => {
            setSendFeedbackStatus('active');
          }}
        >
          {sendFeedbackStatus === 'success' ? <Check /> : <BugReport />}
          {!isMobile && (
            <Typography ml={0.5}>
              {sendFeedbackStatus === 'success' ? 'Feedback sent' : 'Feedback'}
            </Typography>
          )}
        </BugReportFab>
      </Zoom>
    </>
  );
}

const BugReportFab = styled(Fab)(() => ({
  position: 'fixed',
  right: 16,
  bottom: 16,
  zIndex: 1100,
}));
