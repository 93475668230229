/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MarkerSaveInput = {
    name: string;
    description?: string | null;
    frameId?: string | null;
    scrollPosition?: number | null;
    tourId: string;
    isFastTravel: boolean;
    frameIndex: number;
};
export type TourAddMarkersMutationVariables = {
    input: MarkerSaveInput;
};
export type TourAddMarkersMutationResponse = {
    readonly markerSave: {
        readonly marker: {
            readonly id: string;
            readonly name: string;
            readonly description: string;
            readonly scrollPosition: number;
            readonly isFastTravel: boolean;
            readonly frameIndex: number;
        };
    };
};
export type TourAddMarkersMutation = {
    readonly response: TourAddMarkersMutationResponse;
    readonly variables: TourAddMarkersMutationVariables;
};



/*
mutation TourAddMarkersMutation(
  $input: MarkerSaveInput!
) {
  markerSave(input: $input) {
    marker {
      id
      name
      description
      scrollPosition
      isFastTravel
      frameIndex
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkerSavePayload",
    "kind": "LinkedField",
    "name": "markerSave",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Marker",
        "kind": "LinkedField",
        "name": "marker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scrollPosition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFastTravel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frameIndex",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourAddMarkersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourAddMarkersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "59ed9519f0316fa0e17a667fc2bd8a60",
    "id": null,
    "metadata": {},
    "name": "TourAddMarkersMutation",
    "operationKind": "mutation",
    "text": "mutation TourAddMarkersMutation(\n  $input: MarkerSaveInput!\n) {\n  markerSave(input: $input) {\n    marker {\n      id\n      name\n      description\n      scrollPosition\n      isFastTravel\n      frameIndex\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2f06260e8aa017e524b5e5e37c0079d8';
export default node;
