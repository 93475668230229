import {Terms} from '../components/TermsComponent';

export const riskIndemnity: Terms = [
  {
    id: '7.1',
    term: `The user's use of this website and the information contained on the website is entirely at the user's own risk and the user assumes full responsibility and risk of loss resulting from the use thereof.`,
  },
  {
    id: '7.2',
    term: `The transmission of information via the internet, including without limitation e-mail, is susceptible to monitoring and interception. The user bears all risk of transmitting information in this manner. Under no circumstances shall service provider be liable for any loss, harm, or damage suffered by the user as a result thereof. Service provider reserves the right to request independent verification of any information transmitted via e-mail and the user consents to such verification should service provider deem it necessary.`,
  },
  {
    id: '7.3',
    term: 'To the extent permissible by law:',
    terms: [
      {
        id: '7.3.1',
        term:
          'Neither service provider, its affiliates, shareholders, agents, consultants or employees shall be liable for any damages whatsoever, including without limitation any direct, indirect, special, incidental, consequential or punitive damages, howsoever arising (whether in an action arising out of contract, statute, delict or otherwise) related to the use of, or the inability to access or use the content of the website or any functionality thereof, or the information contained on the website, or of any linked website, even if service provider knows or should reasonably have known or is expressly advised thereof.',
      },
      {
        id: '7.3.2',
        term:
          'The liability of service provider for faulty execution of the website as well as all damages suffered by the user, whether direct or indirect, as a result of the malfunctioning of the website shall be limited to service provider rectifying the malfunction, within a reasonable time and free of charge, provided that service provider is notified immediately of the damage or faulty execution of the website. This liability shall fall away and be expressly excluded if the user attempts to correct or allows third parties to correct or attempt to correct the website without the prior written approval of service provider. However, in no event shall service provider be liable to the user for loss of profits or for special, incidental, consequential or punitive losses or damages arising out of or in connection with the website or its use or the delivery, installation, servicing, performance or use of it in combination with other computer software.',
      },
      {
        id: '7.3.3',
        term:
          'You hereby unconditionally and irrevocably indemnify service provider and agree to hold service provider free from all loss, damages, claims and/or costs, of whatsoever nature suffered or incurred by service provider or instituted against service provider as a direct or indirect result of:',
        terms: [
          {
            id: '7.3.3.1',
            term: 'your use of the website?',
          },
          {
            id: '7.3.3.2',
            term:
              'software, programs and support services supplied by, obtained by or modified by you or any third party without the consent or knowledge of service provider?',
          },
          {
            id: '7.3.3.3',
            term:
              'your failure to comply with any of the terms or any other requirements which service provider may impose from time to time?',
          },
          {
            id: '7.3.3.4',
            term:
              'the actions or requirements of any telecommunications authority or a supplier of telecommunications services or software? or',
          },
          {
            id: '7.3.3.5',
            term:
              'any unavailability of, or interruption in, the service which is beyond the control of service provider.',
          },
        ],
      },
    ],
  },
  {
    id: '7.4',
    term:
      'Service provider makes no warranty or representation as to the availability, accuracy or completeness of the content of the website. You expressly waive and renounce all your rights of whatever nature that you may have against service provider for any LOSS suffered by you, as a result of information supplied by service provider being incorrect, incomplete or inaccurate.',
  },
];
