import React, {useEffect} from 'react';
import Turnstile from 'react-turnstile';
import {ConfigService} from '../../../utils';
import {useFormikContext} from 'formik';

const TurnstileWidget = () => {
  const {setFieldValue} = useFormikContext();

  const handleTurnstileSuccess = (token: string) => {
    setFieldValue('turnstileToken', token); // Set the token in Formik state
  };

  useEffect(() => {
    if (ConfigService.isDev) {
      // In development mode, set a dummy token after a delay
      const timer = setTimeout(() => {
        setFieldValue('turnstileToken', 'dev-token');
      }, 500);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [setFieldValue]);

  if (ConfigService.isDev) {
    return (
      <div>
        <p>Turnstile verification is disabled in development mode.</p>
      </div>
    );
  }

  return (
    <Turnstile
      sitekey="0x4AAAAAAAcmLl7I0h-o2cwh"
      onVerify={handleTurnstileSuccess}
      theme={'light'}
    />
  );
};

export default TurnstileWidget;
