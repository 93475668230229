import {Term} from '../../terms/components/TermsComponent';
import React from 'react';

export const contactUs: Term[] = [
  {
    id: '18.1',
    term: (
      <>
        If you have any questions about this Privacy Policy, please contact us
        by email:{' '}
        <a href="mailto:legal@truvu.app" target="_blank" rel="noreferrer">
          legal@truvu.app
        </a>
      </>
    ),
  },
];
