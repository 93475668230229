import React from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {Stack, useMediaQuery, Zoom} from '@mui/material';
import {Theme} from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import {TruvuMenu} from '../../../components/TruvuMenu';
import {createStyles, makeStyles} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import {Close} from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Slide direction="up" ref={ref} {...props}>
        {children}
      </Slide>
    );
  }
  return (
    <Zoom ref={ref} {...props}>
      {children}
    </Zoom>
  );
});

export function ActiveMemberDialog({
  title,
  children,
  open,
  onClose,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) {
  const classes = useActiveMemberDialogStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{root: classes.container, paper: classes.paper}}
    >
      <IconButton
        onClick={onClose}
        sx={{alignSelf: 'flex-start', mx: 1, mb: 1}}
      >
        <Close />
      </IconButton>
      <TruvuMenu title={title} px={2} alwaysSpaceBetween />
      <Stack spacing="15px" pt={1} minHeight={0} style={{overflow: 'hidden'}}>
        {children}
      </Stack>
    </Dialog>
  );
}
const useActiveMemberDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(25,28,31,0.6)',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      maxWidth: '450px',
      margin: 0,
      padding: theme.spacing(2, 0),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        bottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  })
);
