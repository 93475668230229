import React, {useState} from 'react';

import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import './tourCard.css';
import {TourMemberCard_tour$key} from '../../../__generated__/TourMemberCard_tour.graphql';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Link} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {TourCardClickable} from './styled/TourCardClickable';
import {TourCardThumbnail} from './styled/TourCardThumbnail';
import {Skeleton, Stack, useMediaQuery} from '@mui/material';
import {TourCardContent} from './styled/TourCardContent';
import {Theme} from '@mui/material/styles';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {RWebShare} from 'react-web-share';
import {useHistory} from 'react-router';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
const fragmentSpec = graphql`
  fragment TourMemberCard_tour on Tour {
    id
    title
    views
    createdAt
    description
    videoURL
    thumbnailURL
    reels {
      provider
    }
    status {
      status
    }
    logs {
      status
      error
    }
  }
`;

interface TourMemberCardProps {
  tourRef: TourMemberCard_tour$key;
  userName?: string;
  processing?: boolean;
}

export const TourMemberCard = React.memo(function TourMemberCardComponent({
  tourRef,
  userName,
}: TourMemberCardProps) {
  const tour = useFragment(fragmentSpec, tourRef);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {id, thumbnailURL, description, title, views} = tour;
  const history = useHistory();
  const {
    openDialog: openActionsDialog,
    onCloseDialog: onCloseActionsDialog,
    onOpenDialog: onOpenActionsDialog,
  } = useTruvuDialog();
  const shareUrl = `${window.location.protocol}//${window.location.host}/playerext?tourId=${id}`;
  const [copyLinkStatus, setCopyLinkStatus] = useState<
    'idle' | 'copying' | 'copied' | 'error'
  >('idle');
  const onCopyLink = async () => {
    setCopyLinkStatus('copying');
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopyLinkStatus('copied');
    } catch (error) {
      setCopyLinkStatus('error');
    } finally {
      setTimeout(() => {
        setCopyLinkStatus('idle');
      }, 6000);
    }
  };
  return (
    <>
      <TruvuDialog
        open={openActionsDialog}
        onClose={onCloseActionsDialog}
        title={title}
        subtitle={
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={0.2}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(`/team`);
            }}
          >
            <PersonOutlineOutlinedIcon fontSize="small" color="primary" />
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              fontWeight={700}
            >
              {userName}
            </Typography>
          </Stack>
        }
        headingAction={
          <TruvuIconButton
            variant={copyLinkStatus === 'error' ? 'danger' : 'secondary'}
            size="large"
            onClick={onCopyLink}
            loading={copyLinkStatus === 'copying'}
          >
            {copyLinkStatus === 'idle' || copyLinkStatus === 'copying' ? (
              <Link />
            ) : (
              <Typography fontSize="0.7rem" variant="caption" fontWeight={600}>
                {copyLinkStatus === 'error' ? 'Failed' : 'Copied'}
              </Typography>
            )}
          </TruvuIconButton>
        }
      >
        <TruvuButton
          to={`/previewplayer?tourId=${id}`}
          variant="primary"
          enableAuxNavigate
        >
          Preview
        </TruvuButton>
        <RWebShare
          data={{
            text: `Check out this Truvu Walkthrough: ${title}`,
            title: 'Share this Truvu Walkthrough',
            url: shareUrl,
          }}
          disableNative
        >
          <TruvuButton variant="secondary">Share</TruvuButton>
        </RWebShare>
      </TruvuDialog>
      <TourCardClickable onClick={onOpenActionsDialog}>
        {thumbnailURL != null && thumbnailURL !== '' ? (
          <TourCardThumbnail>
            <img
              src={thumbnailURL}
              alt="Unable to load thumbnail"
              draggable={false}
            />
          </TourCardThumbnail>
        ) : (
          <Skeleton
            variant="rectangular"
            width={isDesktop ? 120 : 60}
            height={isDesktop ? 90 : 60}
          />
        )}
        <TourCardContent>
          {title ? (
            <Typography className="truncate title" variant="body1">
              {title}
            </Typography>
          ) : (
            <Typography variant="body1" color={'text.secondary'}>
              Insert Title
            </Typography>
          )}
          <Typography
            className="truncate"
            component="p"
            fontSize="0.9rem"
            color="text.secondary"
            lineHeight="1.2em"
          >
            {description}
          </Typography>
          <Stack flex={1} direction="row" spacing={0.8} alignItems="flex-end">
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography color="primary" whiteSpace={'nowrap'}>
                {views === 1 ? `${views} view` : `${views} views`}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={0.2}>
              <PersonOutlineOutlinedIcon fontSize="small" color="primary" />
              <Typography
                color="primary"
                textAlign="center"
                alignItems="center"
                display="flex"
                className="truncate title"
              >
                {userName != null && userName.length > 15
                  ? userName.slice(0, 15) + '...'
                  : userName}
              </Typography>
            </Stack>
          </Stack>
        </TourCardContent>
      </TourCardClickable>
    </>
  );
});
