import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router';
import {useEffect, useState} from 'react';

export const useAuthSearchParams = () => {
  const location = useLocation();
  const history = useHistory();
  const [inviteToken, setInviteToken] = useState<string | undefined>(undefined);
  const [referredBy, setReferredBy] = useState<string | undefined>(undefined);
  const [gToken, setGToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const inviteTokenParam = searchParams.get('inviteToken');
    const referredByParam = searchParams.get('rB'); // referredBy
    const gTokenParam = searchParams.get('gToken'); // marketingPageGToken

    if (inviteTokenParam || referredByParam || gTokenParam) {
      setInviteToken(inviteTokenParam ?? undefined);
      setReferredBy(referredByParam ?? undefined);
      setGToken(gTokenParam ?? undefined);

      // Remove inviteToken and referredBy from the URL
      if (inviteTokenParam) searchParams.delete('inviteToken');
      if (referredByParam) searchParams.delete('rB');
      if (gTokenParam) searchParams.delete('gToken');

      // Replace the URL without causing a re-render
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        history.replace({
          pathname: location.pathname,
          search: newSearch,
          hash: location.hash,
        });
      }
    }
  }, [location, history]);

  return {inviteToken, referredBy, gToken};
};
