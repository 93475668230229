import React, {useState, useRef} from 'react';
import {Stack, IconButton} from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

interface TourReelPlayerProps {
  src: string;
  captionSrc?: string;
}

export const TourReelPlayer: React.FC<TourReelPlayerProps> = ({
  src,
  captionSrc,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Stack
      sx={{
        aspectRatio: '16/9',
        bgcolor: 'action.disabledBackground',
        borderRadius: 1,
        minHeight: '200px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <video
        ref={videoRef}
        src={src}
        style={{width: '100%', height: '100%', objectFit: 'cover'}}
      >
        <track
          kind="captions"
          src={captionSrc || 'data:text/vtt,WEBVTT'}
          srcLang="en"
          label="English"
        />
        Your browser does not support the video tag.
      </video>
      <IconButton
        onClick={togglePlay}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {bgcolor: 'rgba(0, 0, 0, 0.7)'},
        }}
      >
        {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
      </IconButton>
    </Stack>
  );
};
