import React from 'react';
import {Avatar, Stack, StackProps, styled} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TruvuLogoSvg from '../../assets/svg/truvu-logo.svg';
import {AccountCircle, ArrowBack} from '@mui/icons-material';
import {useHistory} from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {TruvuTeamContainer_user$key} from '../../__generated__/TruvuTeamContainer_user.graphql';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

const StyledContainer = styled(Stack)(() => ({
  minHeight: '100svh',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px 20px',
  maxWidth: '1200px',
}));

const StyledAppBar = styled(Stack)(({theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

const fragment = graphql`
  fragment TruvuTeamContainer_user on User {
    company {
      id
      name
    }
    profile {
      name
      surname
      profileImageURL
    }
  }
`;
interface TruvuTeamContainerProps extends StackProps {
  userRef: TruvuTeamContainer_user$key | null | undefined;
}

export function TruvuTeamContainer({
  children,
  userRef,
  ...props
}: TruvuTeamContainerProps) {
  const {push} = useHistory();

  const onGoToAccount = () => {
    push('/account');
  };
  const onGoToHome = () => {
    push('/');
  };

  if (userRef != null) {
    return (
      <TruvuTeamContainerWithAvatar
        userRef={userRef}
        onGoToAccount={onGoToAccount}
        onGoToHome={onGoToHome}
        {...props}
      >
        {children}
      </TruvuTeamContainerWithAvatar>
    );
  }

  return (
    <StyledContainer {...props}>
      <StyledAppBar>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <ButtonBase
              onClick={onGoToHome}
              sx={{
                height: 'fit-content',
                padding: '5px 10px 5px 5px',
                marginLeft: '-5px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                borderRadius: 8,
              }}
            >
              <ArrowBack />
              <img
                src={TruvuLogoSvg}
                alt="Truvu"
                style={{width: '70px', maxWidth: '70px'}}
              />
            </ButtonBase>
          </Stack>
          <IconButton size="large" sx={{p: 0}} onClick={onGoToAccount}>
            <AccountCircle fontSize="large" />
          </IconButton>
        </Stack>
      </StyledAppBar>
      {children}
    </StyledContainer>
  );
}

interface TruvuTeamContainerWithAvatarProps extends StackProps {
  userRef: TruvuTeamContainer_user$key;
  onGoToAccount: () => void;
  onGoToHome: () => void;
}
export function TruvuTeamContainerWithAvatar({
  children,
  userRef,
  onGoToAccount,
  onGoToHome,
  ...props
}: TruvuTeamContainerWithAvatarProps) {
  const user = useFragment(fragment, userRef);
  const {name = '', surname = ''} = user?.profile || {};
  const initials = `${name
    .trim()[0]
    ?.toUpperCase()}${surname.trim()[0]?.toUpperCase()}`;
  return (
    <StyledContainer {...props}>
      <StyledAppBar>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <ButtonBase
              onClick={onGoToHome}
              sx={{
                height: 'fit-content',
                padding: '5px 10px 5px 5px',
                marginLeft: '-5px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                borderRadius: 8,
              }}
            >
              <ArrowBack />
              <img
                src={TruvuLogoSvg}
                alt="Truvu"
                style={{width: '70px', maxWidth: '70px'}}
              />
            </ButtonBase>
          </Stack>
          <IconButton size="large" sx={{p: 0}} onClick={onGoToAccount}>
            {user.profile?.profileImageURL != null &&
            user.profile?.profileImageURL !== '' ? (
              <Avatar
                alt="Remy Sharp"
                src={user.profile.profileImageURL}
                sx={{width: 40, height: 40}}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#dfe2e6',
                  color: '#fff',
                  width: 40,
                  height: 40,
                }}
              >
                <Typography fontSize="1em" fontWeight={700}>
                  {initials}
                </Typography>
              </Avatar>
            )}
          </IconButton>
        </Stack>
      </StyledAppBar>
      {children}
    </StyledContainer>
  );
}
