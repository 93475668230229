import React from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Stack,
  StackProps,
  TypographyProps,
  Typography,
  useMediaQuery,
  Zoom,
} from '@mui/material';

import {TruvuButton, TruvuButtonProps} from '../button/TruvuButton';
import {TransitionProps} from '@mui/material/transitions';
import {Theme} from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import {createStyles, makeStyles} from '@mui/styles';

interface TruvuDialogContextProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const TruvuDialogContext = React.createContext<TruvuDialogContextProps | null>(
  null
);

const useTruvuDialogContext = () => {
  const context = React.useContext(TruvuDialogContext);
  if (!context) {
    throw new Error(
      'useTruvuDialogContext must be used within an TruvuDialogProvider'
    );
  }
  return context;
};

export const useTruvuDialog = (): TruvuDialogContextProps => {
  const [isOpen, setIsOpen] = React.useState(false);

  return {
    isOpen,
    onClose: () => {
      setIsOpen(false);
    },
    onOpen: () => {
      setIsOpen(true);
    },
  };
};

type ProviderControlledProps = {
  controlled: true;
} & TruvuDialogContextProps;

interface ProviderUnControlledProps {
  controlled?: false;
}

function ProviderControlled({
  isOpen,
  onOpen,
  onClose,
  children,
}: React.PropsWithChildren<TruvuDialogContextProps>) {
  const memoizedValue = React.useMemo<TruvuDialogContextProps>(
    () => ({isOpen, onOpen, onClose}),
    [isOpen, onClose, onOpen]
  );
  return (
    <TruvuDialogContext.Provider value={memoizedValue}>
      {children}
    </TruvuDialogContext.Provider>
  );
}

function ProviderUnControlled({children}: {children: React.ReactNode}) {
  return (
    <TruvuDialogContext.Provider value={useTruvuDialog()}>
      {children}
    </TruvuDialogContext.Provider>
  );
}

export function TruvuDialogProvider({
  children,
  ...props
}: React.PropsWithChildren<
  ProviderControlledProps | ProviderUnControlledProps
>) {
  if (props.controlled) {
    return <ProviderControlled {...props}>{children}</ProviderControlled>;
  }
  return <ProviderUnControlled>{children}</ProviderUnControlled>;
}

export function TruvuOpenButton({children, ...props}: TruvuButtonProps) {
  const {onOpen} = useTruvuDialogContext();
  return (
    <TruvuButton onClick={onOpen} {...props}>
      {children ?? 'Open Dialog'}
    </TruvuButton>
  );
}
export const TruvuDialogTransition = React.forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Slide direction="up" ref={ref} {...props}>
        {children}
      </Slide>
    );
  }
  return (
    <Zoom ref={ref} {...props}>
      {children}
    </Zoom>
  );
});

export const useTruvuDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(25,28,31,0.6)',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      maxWidth: '450px',
      margin: 0,
      backgroundImage: 'none',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        bottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
    paperMD: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      maxWidth: '650px',
      margin: 0,
      backgroundImage: 'none',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        bottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  })
);

export function TruvuDialogDialog({
  children,
  ...props
}: Omit<MuiDialogProps, 'onClose' | 'open'>) {
  const {isOpen, onClose} = useTruvuDialogContext();
  const classes = useTruvuDialogStyles();
  return (
    <MuiDialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="optix-dialog"
      aria-describedby="optix-generic-dialog"
      TransitionComponent={TruvuDialogTransition}
      maxWidth="sm"
      fullWidth
      classes={{root: classes.container, paper: classes.paper}}
      {...props}
    >
      {children}
    </MuiDialog>
  );
}

interface DialogTitleProps extends TypographyProps {
  title: string;
  action?: React.ReactNode;
  subtitle?: string;
  SubtitleProps?: TypographyProps;
}

export function TruvuDialogTitle({
  title,
  action,
  subtitle,
  SubtitleProps,
  ...props
}: DialogTitleProps) {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={action != null ? 2 : 0}
    >
      <Typography variant="h1" {...props}>
        {title}{' '}
        {subtitle != null && (
          <Typography color="primary" component="span" {...SubtitleProps}>
            {subtitle}
          </Typography>
        )}
      </Typography>
      {action}
    </Stack>
  );
}

export function TruvuDialogContent({children, ...props}: StackProps) {
  return (
    <Stack py={2} flex={1} flexGrow={1} {...props}>
      {children}
    </Stack>
  );
}

export function TruvuDialogActions({children, ...props}: StackProps) {
  return (
    <Stack
      flex={1}
      justifyContent="flex-end"
      direction="row"
      spacing={2}
      {...props}
    >
      {children}
    </Stack>
  );
}

export type onAccept = (
  event: React.MouseEvent<HTMLButtonElement>,
  onClose: () => void
) => void | Promise<void>;

interface DialogAcceptProps {
  acceptBehaviour?: 'closeBefore' | 'closeAfter' | 'keepOpen';
  onAccept: onAccept;
}

export function TruvuDialogAccept({
  acceptBehaviour = 'closeBefore',
  onAccept,
  children,
  ...props
}: DialogAcceptProps & Omit<TruvuButtonProps, 'onClick'>) {
  const {onClose} = useTruvuDialogContext();

  const handleAccept = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (acceptBehaviour === 'closeBefore') {
        onClose();
      }
      await Promise.resolve(onAccept(event, onClose)).then(() => {
        if (acceptBehaviour === 'closeAfter') {
          onClose();
        }
      });
    },
    [acceptBehaviour, onAccept, onClose]
  );

  return (
    //TODO: fix this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TruvuButton variant="primary" onClick={handleAccept} {...props}>
      {children ?? 'Accept'}
    </TruvuButton>
  );
}

export type onDecline = (
  event: React.MouseEvent<HTMLButtonElement>,
  onClose: () => void
) => void | Promise<void>;

interface DialogDeclineProps extends Omit<TruvuButtonProps, 'onClick'> {
  declineBehaviour?: 'closeBefore' | 'closeAfter' | 'keepOpen';
  onDecline?: onDecline;
}

export function TruvuDialogDecline({
  declineBehaviour,
  onDecline,
  children,
  ...props
}: DialogDeclineProps) {
  const {onClose} = useTruvuDialogContext();

  const handleDecline = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (declineBehaviour === 'closeBefore' || !onDecline) {
        onClose();
      }
      if (onDecline) {
        await Promise.resolve(onDecline(event, onClose)).then(() => {
          if (declineBehaviour === 'closeAfter') {
            onClose();
          }
        });
      }
    },
    [declineBehaviour, onDecline, onClose]
  );

  return (
    //TODO: fix this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TruvuButton variant="secondary" onClick={handleDecline} {...props}>
      {children ?? 'Decline'}
    </TruvuButton>
  );
}

export const TruvuDialog2 = Object.assign(TruvuDialogProvider, {
  Dialog: TruvuDialogDialog,
  Title: TruvuDialogTitle,
  Content: TruvuDialogContent,
  Actions: TruvuDialogActions,
  OpenButton: TruvuOpenButton,
  Accept: TruvuDialogAccept,
  Decline: TruvuDialogDecline,
  useTruvuDialog,
});
