import React from 'react';
import {Router} from 'react-router';
import {createBrowserHistory} from 'history';
import {CssBaseline} from '@mui/material';

import {Routes} from './navigation/Routes';
import {MarkerProvider} from './context/MarkerContext';
import {TourProvider} from './context/TourContext';
import {RefetchProvider} from './context/RefetchContext';
import './App.css';
import AuthContextProvider from './context/AuthContext';
import RelayContextProvider from './context/RelayContextProvider';
import {SnackbarController} from './components/SnackbarController';
import TutorialContextProvider from './context/TutorialContext';
import {LastLocationProvider} from 'react-router-last-location';
import * as Sentry from '@sentry/react';
import {FeedbackButton} from './components/FeedbackButton';
import {TourAIGenerateMarkersSubscriptionContextProvider} from './context/TourAIGenerateMarkersSubscriptionContext';
import {CookiesBanner} from './components/CookiesBanner';

const history = createBrowserHistory();

export default function App() {
  React.useEffect(() => {
    function setCssVar() {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', setCssVar);
    return () => {
      window.removeEventListener('resize', setCssVar);
    };
  }, []);

  return (
    <Sentry.ErrorBoundary showDialog>
      <AuthContextProvider>
        <TutorialContextProvider>
          <RelayContextProvider>
            <TourAIGenerateMarkersSubscriptionContextProvider>
              <Router history={history}>
                <LastLocationProvider>
                  <CssBaseline />
                  <RefetchProvider>
                    <TourProvider>
                      <MarkerProvider>
                        <SnackbarController>
                          <Routes />
                          <FeedbackButton />
                          <CookiesBanner />
                        </SnackbarController>
                      </MarkerProvider>
                    </TourProvider>
                  </RefetchProvider>
                </LastLocationProvider>
              </Router>
            </TourAIGenerateMarkersSubscriptionContextProvider>
          </RelayContextProvider>
        </TutorialContextProvider>
      </AuthContextProvider>
    </Sentry.ErrorBoundary>
  );
}
