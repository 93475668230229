/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourEditDrawerMarkers_tour = {
    readonly markers: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly isFastTravel: boolean;
        readonly frameIndex: number;
    }>;
    readonly id: string;
    readonly " $refType": "TourEditDrawerMarkers_tour";
};
export type TourEditDrawerMarkers_tour$data = TourEditDrawerMarkers_tour;
export type TourEditDrawerMarkers_tour$key = {
    readonly " $data"?: TourEditDrawerMarkers_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourEditDrawerMarkers_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourEditDrawerMarkersTodoRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourEditDrawerMarkers_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Marker",
      "kind": "LinkedField",
      "name": "markers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFastTravel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frameIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = '0c64d21251ed016d72167acbaa836f81';
export default node;
