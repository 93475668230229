export const checkUploadLimit = ({
  monthlyActiveTours = 0,
  monthlyUploadedTours = 0,
  uploadLimit = 0,
  quantity = 0,
}: {
  monthlyActiveTours: number | null | undefined;
  monthlyUploadedTours: number | null | undefined;
  uploadLimit: number | null | undefined;
  quantity: number | null | undefined;
}) => {
  const activeDiff = (quantity ?? 0) - (monthlyActiveTours ?? 0);
  const uploadDiff = (uploadLimit ?? 0) - (monthlyUploadedTours ?? 0);
  return {isActiveAvailable: activeDiff > 0, isUploadAvailable: uploadDiff > 0};
};
