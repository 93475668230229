import React, {useEffect, useRef, useState} from 'react';
import {printLogo} from '../helpers';

interface LogoCanvasProps {
  logoUrl: string;
  width: number;
  height: number;
  percentage: number; // Added percentage prop
}

export const LogoCanvas: React.FC<LogoCanvasProps> = ({
  logoUrl,
  width,
  height,
  percentage,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Load the image once
  useEffect(() => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      imageRef.current = image;
      setImageLoaded(true);
    };
    image.src = logoUrl;
  }, [logoUrl]);

  // Update the canvas whenever percentage changes
  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    if (canvas && imageLoaded && image) {
      canvas.width = width;
      canvas.height = height;
      printLogo(image, canvas, percentage);
    }
  }, [width, height, percentage, imageLoaded]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        backgroundColor: 'transparent',
      }}
    />
  );
};
