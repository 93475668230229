import React from 'react';
import {
  TruvuTextFieldBase,
  TruvuTextFieldBaseProps,
} from './TruvuTextFieldBase';
import {useFormikContext} from 'formik';

export interface TruvuTextFieldFormikProps
  extends Omit<TruvuTextFieldBaseProps, 'name'> {
  name: string;
}

export function TruvuTextFieldFormik({
  name,
  ...props
}: TruvuTextFieldFormikProps) {
  const {
    getFieldMeta,
    isSubmitting,
    isValid,
    submitCount,
    handleChange,
    handleBlur,
  } = useFormikContext();
  const {error, touched, value} = getFieldMeta(name);

  const errorMessage =
    !isValid && (submitCount > 0 || touched) && error ? error : undefined;

  return (
    <TruvuTextFieldBase
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={isSubmitting}
      helperText={errorMessage}
      error={!!errorMessage}
      value={value}
      {...props}
    />
  );
}
