import React, {useCallback, useState} from 'react';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {Stack, Typography} from '@mui/material';
import {useMutation} from 'react-relay-mutation';
import {ActiveMemberCardActionsRevokeAdminDialogMutation} from '../../../__generated__/ActiveMemberCardActionsRevokeAdminDialogMutation.graphql';
import {ActiveMemberCardActionsAddAdminDialogMutation} from '../../../__generated__/ActiveMemberCardActionsAddAdminDialogMutation.graphql';
import graphql from 'babel-plugin-relay/macro';

interface ActiveMemberCardActionsDialogProps {
  onRevokeCallback: () => void;
  onCloseDialog: () => void;
  title: string;
  user: {isAdmin: boolean | null; user: {id: string} | null};
}

export function ActiveMemberCardActions({
  onRevokeCallback,
  onCloseDialog,
  user,
}: ActiveMemberCardActionsDialogProps) {
  const {notify} = useSnackbarContext();
  const [requestRevokeConfirmation, setRequestRevokeConfirmation] = useState(
    false
  );
  const [
    requestAddAdminConfirmation,
    setRequestAddAdminConfirmation,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    adminRevoke,
  ] = useMutation<ActiveMemberCardActionsRevokeAdminDialogMutation>(
    graphql`
      mutation ActiveMemberCardActionsRevokeAdminDialogMutation($userId: ID!) {
        revokeAdminMember(input: {userId: $userId}) {
          success
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Team admin removed', variant: 'success'});
      },
    }
  );

  const [addAdmin] = useMutation<ActiveMemberCardActionsAddAdminDialogMutation>(
    graphql`
      mutation ActiveMemberCardActionsAddAdminDialogMutation($userId: ID!) {
        makeAdminMember(input: {userId: $userId}) {
          success
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Team admin added', variant: 'success'});
      },
    }
  );

  const handleRevoke = useCallback(async () => {
    setLoading(true);
    try {
      if (!user.user) {
        return;
      }
      const response = await adminRevoke({
        variables: {
          userId: user.user?.id,
        },
      });

      if (response) {
        notify({message: 'Revoked admin', variant: 'success'});
        setRequestRevokeConfirmation(false);
        onCloseDialog();
        onRevokeCallback();
      }
    } catch (e) {
      notify({message: 'Could not revoke admin', variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [user, notify, onCloseDialog, onRevokeCallback, adminRevoke]);

  const handlePromote = useCallback(async () => {
    setLoading(true);
    try {
      if (!user.user) {
        return;
      }
      const response = await addAdmin({
        variables: {
          userId: user.user?.id,
        },
      });

      if (response) {
        notify({message: 'Promoted to admin', variant: 'success'});
        setRequestRevokeConfirmation(false);
        onCloseDialog();
        onRevokeCallback();
      }
    } catch (e) {
      notify({message: 'Could not promote to admin', variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [user, notify, onCloseDialog, onRevokeCallback, addAdmin]);

  if (requestRevokeConfirmation) {
    return (
      <Stack px={2} spacing={1}>
        <Typography variant="h6" fontWeight={500}>
          Are you sure you want to revoke this invite?
        </Typography>
        <TruvuButton
          variant="secondary"
          onClick={() => setRequestRevokeConfirmation(false)}
          disabled={loading}
        >
          Cancel
        </TruvuButton>
        <TruvuButton onClick={handleRevoke} loading={loading} variant="danger">
          {loading ? 'Revoking...' : 'Revoke'}
        </TruvuButton>
      </Stack>
    );
  }

  if (requestAddAdminConfirmation) {
    return (
      <Stack px={2} spacing={1}>
        <Typography variant="h6" fontWeight={500}>
          Are you sure you want to promote this user to admin?
        </Typography>
        <TruvuButton
          variant="secondary"
          onClick={() => setRequestAddAdminConfirmation(false)}
          disabled={loading}
        >
          Cancel
        </TruvuButton>
        <TruvuButton onClick={handlePromote} loading={loading} variant="danger">
          {loading ? 'Promoting...' : 'Promote'}
        </TruvuButton>
      </Stack>
    );
  }

  return (
    <Stack px={2}>
      {user && user.isAdmin ? (
        <TruvuButton
          onClick={() => setRequestRevokeConfirmation(true)}
          variant="danger"
        >
          Remove Admin Role
        </TruvuButton>
      ) : (
        <TruvuButton
          onClick={() => setRequestAddAdminConfirmation(true)}
          variant="primary"
        >
          Promote to Admin
        </TruvuButton>
      )}
    </Stack>
  );
}
