import {
  RelayNetworkLayer,
  urlMiddleware,
  SubscribeFunction,
  authMiddleware,
  errorMiddleware,
  retryMiddleware,
  progressMiddleware,
  uploadMiddleware,
} from 'react-relay-network-modern';
import {Environment, Observable, RecordSource, Store} from 'relay-runtime';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {ConfigService} from '../utils';

const SHOW_PROGRESS = false;

type HandleLogoutFn = () => void;
type GetAuthTokenFn = () => string;

function createNetworkLayer(
  handleLogout: HandleLogoutFn,
  getAuthTokenFn: GetAuthTokenFn,
  subscribeFn?: SubscribeFunction
) {
  const network = new RelayNetworkLayer(
    [
      /*
        cacheMiddleware({
          size: 100, // max 100 requests
          ttl: 900000, // 15 minutes
        }),
        */
      urlMiddleware({
        url: () => Promise.resolve(`${ConfigService.serverUri}/query`),
      }),
      // IS_DEV_ENV ? loggerMiddleware() : null,
      ConfigService.isDev ? errorMiddleware() : null,
      // IS_DEV_ENV ? perfMiddleware() : null,
      retryMiddleware({
        fetchTimeout: 5000,
        retryDelays: [3200, 6400],
        forceRetry: (cb, delay) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.forceRelayRetry = cb;
          // eslint-disable-next-line no-console
          console.log(
            `call \`forceRelayRetry()\` for immediately retry! Or wait ${delay} ms.`
          );
        },
        statusCodes: [500, 503, 504],
      }),
      uploadMiddleware(),
      authMiddleware({
        token: getAuthTokenFn,
      }),
      SHOW_PROGRESS
        ? progressMiddleware({
            onProgress: (current, total) => {
              // eslint-disable-next-line no-console
              console.log(
                `Downloaded: ${current} B, total: ${
                  total ? total.toString() : '0'
                } B`
              );
            },
          })
        : null,

      (next) => async (req) => {
        req.fetchOpts.headers.Accept = 'application/json';
        //debugger;
        if (req.isFormData()) {
          // req.fetchOpts.headers['Content-Type'] = 'multipart/form-data';
        } else {
          req.fetchOpts.headers['Content-Type'] = 'application/json';
        }

        // TODO x-Request-ID
        // req.fetchOpts.headers['X-Request-ID'] = uuid.v4(); // add `X-Request-ID` to request headers
        req.fetchOpts.credentials = 'same-origin'; // allow to send cookies (sending credentials to same domains)

        try {
          return await next(req);
        } catch (ex) {
          // Logout user out if we get a 401
          if (ex.res && ex.res.status === 401) {
            handleLogout();
          }

          throw ex;
        }
      },
    ],
    {
      subscribeFn,
      noThrow: true,
    }
  );

  return network;
}

export default function createEnv(
  handleLogout: HandleLogoutFn,
  getAuthTokenFn: GetAuthTokenFn
) {
  const handlerProvider = undefined;

  const subscriptionClient = new SubscriptionClient(
    // TODO: remove hack
    `${ConfigService.serverUri}/query`.replace('http', 'ws'),
    {
      connectionParams: async () => ({
        token: await getAuthTokenFn(),
      }),
      reconnect: true,
    }
  );

  // @ts-expect-error bad types
  const subscribeFn: SubscribeFunction = (request, variables) => {
    const subscribeObservable = subscriptionClient.request({
      query: request.text ?? '',
      operationName: request.name,
      variables,
    });

    // @ts-expect-error bad types
    return Observable.from(subscribeObservable);
  };

  const network = createNetworkLayer(handleLogout, getAuthTokenFn, subscribeFn);

  const source = new RecordSource();
  const relayStore = new Store(source);

  return new Environment({
    handlerProvider,
    network,
    store: relayStore,
  });
}
