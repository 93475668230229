import React from 'react';

import {SnackbarContext, SnackbarContextType} from '../context/SnackbarContext';
import Snackbar from './Snackbar';

interface Props {
  children: React.ReactNode;
}

export function SnackbarController({children}: Props) {
  const [snack, setSnack] = React.useState<SnackbarContextType['snack']>();

  const dismiss = React.useCallback(() => {
    setSnack(undefined);
  }, [setSnack]);

  const notify = React.useCallback(
    (snack) => {
      setSnack(snack);
    },
    [setSnack]
  );
  return (
    <SnackbarContext.Provider value={{snack, dismiss, notify}}>
      <Snackbar />
      {children}
    </SnackbarContext.Provider>
  );
}
