import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import jwt_decode from 'jwt-decode';
import * as authUtils from './authUtils';

export type AuthContextType =
  | {
      token: string;
      authenticated: true;
      roles: string[] | null;
      requiresReauth: boolean;
    }
  | {
      authenticated: false;
    };

export interface AuthContextStateType {
  auth: AuthContextType;
  handleLogin: (
    username: string,
    token: string,
    source: 'truvu' | 'google'
  ) => void;
  handleLogout: () => void;
  hasRole(role: string): boolean;
  isSuperAdmin: () => boolean;
  isAdmin: () => boolean;
  hasProPlan: () => boolean;
  hasBasicPlanOrHigher: () => boolean;
  hasEntryPlanOrHigher: () => boolean;
  hasAnyPaidPlan: () => boolean;
  isAdminWithProPlan: () => boolean;
  getHighestPlanLevel: () => string;
}

export const AuthContext = createContext<AuthContextStateType>({
  auth: {authenticated: false},
  handleLogin: async () => {},
  handleLogout: () => {},
  hasRole: () => false,
  isSuperAdmin: () => false,
  isAdmin: () => false,
  hasProPlan: () => false,
  hasBasicPlanOrHigher: () => false,
  hasEntryPlanOrHigher: () => false,
  hasAnyPaidPlan: () => false,
  isAdminWithProPlan: () => false,
  getHighestPlanLevel: () => 'unauthenticated',
});

interface Props {
  children: JSX.Element;
}

interface DecodedToken {
  roles?: string[];
  exp: number;
}

export function AuthContextProvider({children}: Props) {
  const loggingOut = useRef(false);
  const authTokenLoaded = useRef<boolean>(false);
  const logoutTimer = useRef<NodeJS.Timeout | null>(null);

  const [localAuth, setLocalAuth] = useLocalStorage<AuthContextType>('auth', {
    authenticated: false,
  });
  const [auth, setAuth] = useState<AuthContextType>(() => {
    authTokenLoaded.current = true;
    return localAuth ?? {authenticated: false};
  });

  const decodeToken = (token: string): DecodedToken | null => {
    try {
      return jwt_decode<DecodedToken>(token);
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  };

  const handleLogout = useCallback(() => {
    if (auth.authenticated === true) {
      setAuth({authenticated: false});
      setLocalAuth({authenticated: false});
      if (logoutTimer.current) {
        clearTimeout(logoutTimer.current);
        logoutTimer.current = null;
      }
    }
  }, [auth.authenticated, setLocalAuth]);

  const handleLogin = useCallback(
    async function (
      username: string,
      token: string,
      source: 'truvu' | 'google'
    ) {
      localStorage.setItem('prevLoginMethod', source);

      const decodedToken = decodeToken(token);
      if (
        !decodedToken ||
        !decodedToken.roles ||
        !Array.isArray(decodedToken.roles) ||
        decodedToken.roles.length === 0
      ) {
        console.error('Invalid token');
        handleLogout();
        return;
      }

      const newAuth = {
        token,
        authenticated: true,
        roles: decodedToken.roles || null,
        requiresReauth:
          !decodedToken.roles ||
          !Array.isArray(decodedToken.roles) ||
          decodedToken.roles.length === 0,
      };
      setAuth(newAuth);
      setLocalAuth(newAuth);

      // Set up a timer to logout when the token expires
      const timeUntilExpiry = decodedToken.exp * 1000 - Date.now();
      if (logoutTimer.current) {
        clearTimeout(logoutTimer.current);
      }
      logoutTimer.current = setTimeout(handleLogout, timeUntilExpiry);
    },
    [setLocalAuth, handleLogout]
  );

  useEffect(() => {
    if (loggingOut.current) {
      handleLogout();
      loggingOut.current = false;
    }
  }, [loggingOut, handleLogout]);

  useEffect(() => {
    let innerAuth: AuthContextType = {authenticated: false};

    if (localAuth != null && localAuth.authenticated) {
      const decodedToken = decodeToken(localAuth.token);
      if (
        decodedToken &&
        decodedToken.roles != null &&
        decodedToken.roles.length > 0 &&
        decodedToken.exp * 1000 > Date.now()
      ) {
        innerAuth = {
          ...localAuth,
          roles: decodedToken.roles || null,
          requiresReauth:
            !decodedToken.roles ||
            !Array.isArray(decodedToken.roles) ||
            decodedToken.roles.length === 0,
        };
        // Set up a timer for the remaining time until token expiry
        const timeUntilExpiry = decodedToken.exp * 1000 - Date.now();
        if (logoutTimer.current) {
          clearTimeout(logoutTimer.current);
        }
        logoutTimer.current = setTimeout(handleLogout, timeUntilExpiry);
      } else {
        // Token has expired or is invalid, log out
        handleLogout();
      }
    }

    setAuth(innerAuth);
  }, [localAuth, handleLogout]);

  const hasRole = useCallback(
    (role: string) => {
      if (!auth.authenticated || !auth.roles) {
        return false;
      }

      return auth.roles.includes(role);
    },
    [auth]
  );

  const value = React.useMemo(
    () => ({
      auth,
      handleLogin,
      handleLogout,
      hasRole,
      isSuperAdmin: () => authUtils.isSuperAdmin(auth),
      isAdmin: () => authUtils.isAdmin(auth),
      hasProPlan: () => authUtils.hasProPlan(auth),
      hasBasicPlanOrHigher: () => authUtils.hasBasicPlanOrHigher(auth),
      hasEntryPlanOrHigher: () => authUtils.hasEntryPlanOrHigher(auth),
      hasAnyPaidPlan: () => authUtils.hasAnyPaidPlan(auth),
      isAdminWithProPlan: () => authUtils.isAdminWithProPlan(auth),
      getHighestPlanLevel: () => authUtils.getHighestPlanLevel(auth),
    }),
    [auth, handleLogin, handleLogout, hasRole]
  );

  // Prevent double render before auth token loaded
  if (!authTokenLoaded.current) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
