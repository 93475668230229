import processErrorIcon from '../../../assets/svg/process_error-icon.svg';
import Typography from '@mui/material/Typography';
import React, {useCallback, useState} from 'react';
import {TourCardThumbnail} from './styled/TourCardThumbnail';
import {TourCardContent} from './styled/TourCardContent';
import {TourCardClickable} from './styled/TourCardClickable';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useMutation} from 'react-relay-mutation';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {TourCardFailedMutation} from '../../../__generated__/TourCardFailedMutation.graphql';

interface TourCardFailedProps {
  tourId: string;
  title: string;
  refetchTours: () => void;
}

export function TourCardFailed({
  tourId,
  title,
  refetchTours,
}: TourCardFailedProps) {
  const {notify} = useSnackbarContext();
  const [tourDelete] = useMutation<TourCardFailedMutation>(
    graphql`
      mutation TourCardFailedMutation($tourId: ID!) {
        tourDelete(input: {tourId: $tourId}) {
          clientMutationId
        }
      }
    `,
    {
      onCompleted: () => {
        onCloseActionsDialog();
        refetchTours();
      },
    }
  );
  const {
    openDialog: openActionsDialog,
    onCloseDialog: onCloseActionsDialog,
    onOpenDialog: onOpenActionsDialog,
  } = useTruvuDialog();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      await tourDelete({
        variables: {
          tourId,
        },
      });
    } catch (e) {
      notify({message: 'Could not delete tour data', variant: 'error'});
    } finally {
      setDeleting(false);
    }
  }, [tourId, notify, tourDelete]);

  return (
    <>
      <TruvuDialog
        open={openActionsDialog}
        onClose={onCloseActionsDialog}
        title={title}
      >
        <TruvuButton
          onClick={handleDelete}
          variant="danger"
          style={{marginTop: '30px'}}
          loading={deleting}
        >
          {deleting ? 'Deleting Tour' : 'Delete Tour'}
        </TruvuButton>
      </TruvuDialog>
      <TourCardClickable onClick={onOpenActionsDialog}>
        <TourCardThumbnail>
          <img
            src={processErrorIcon}
            alt="Unable to load thumbnail"
            draggable={false}
          />
        </TourCardThumbnail>
        <TourCardContent>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2" fontWeight={500} color="error">
            Processing failed
          </Typography>
        </TourCardContent>
      </TourCardClickable>
    </>
  );
}
