/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReelStatus = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type Status = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type TourCard_tour = {
    readonly id: string;
    readonly title: string;
    readonly views: number;
    readonly createdAt: unknown;
    readonly description: string;
    readonly videoURL: string;
    readonly thumbnailURL: string;
    readonly reels: ReadonlyArray<{
        readonly id: string;
        readonly key: string;
        readonly progress: number;
        readonly seed: number;
        readonly provider: string;
        readonly highURI: string;
        readonly thumbnailURL: string;
        readonly status: {
            readonly status: ReelStatus;
        };
    }>;
    readonly status: {
        readonly status: Status;
    };
    readonly logs: ReadonlyArray<{
        readonly status: string;
        readonly error: string;
    }>;
    readonly " $refType": "TourCard_tour";
};
export type TourCard_tour$data = TourCard_tour;
export type TourCard_tour$key = {
    readonly " $data"?: TourCard_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourCard_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailURL",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourCardRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourCard_tour",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoURL",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Reel",
      "kind": "LinkedField",
      "name": "reels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highURI",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ReelProgress",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourProgress",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourLog",
      "kind": "LinkedField",
      "name": "logs",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = '5ee618949f4d1456dd8fd532059d91b9';
export default node;
