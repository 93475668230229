import {Terms} from '../components/TermsComponent';

export const intellectualPropertyRights: Terms = [
  {
    id: '6.1',
    term:
      'For the purpose of this clause, the following words shall have the following meanings ascribed to them:',
    terms: [
      {
        id: '6.1.1',
        term: `"Intellectual property rights" means all and any of the rights in and to intellectual property of any nature whatsoever owned and/or controlled directly or under licence by Service Provider, now or in the future, including without limitation, Service Provider's rights, title and interest in and to all technology, source code/s, trade secrets, logos, systems, methods, trademarks, trade names, styles, insignia, designs, patents and copyright, and all similar proprietary rights which may subsist in any part of the world, whether registered or not.`,
      },
    ],
  },
  {
    id: '6.2',
    term:
      'All copyright and other intellectual property rights in all content, trademarks, software, data, material, including logos, databases, text, graphics, icons, hyperlinks, confidential information, designs, agreements, and multimedia works, published on or via the Website ("proprietary material"), are the property of, or are licensed to, Service Provider and as such are protected from infringement by local and international legislation and treaties.',
  },
  {
    id: '6.3',
    term:
      'By submitting reviews, comments and/or any other content (other than your personal information) to Service Provider for posting on the Website, you automatically grant Service Provider and its affiliates a non-exclusive, royalty-free, perpetual, irrevocable right and licence to use, reproduce, publish, translate, sub-license, copy and distribute such content in whole or in part worldwide, and to incorporate it in other works in any form, media, or technology now known or hereinafter developed, for the full term of any copyright that may exist in such content. Subject to this licence, you retain any and all rights that may exist in such content.',
  },
  {
    id: '6.4',
    term:
      'All rights not expressly granted are reserved and no right, title or interest in any proprietary material or information contained in this Website is granted to you.',
  },
  {
    id: '6.5',
    term:
      "Except with Service Provider's express written permission, no proprietary material from this Website may be copied or retransmitted.",
  },
  {
    id: '6.6',
    term:
      'Irrespective of the existence of copyright, the user acknowledges that Service Provider is the proprietor of all material on the Website (except where a third party is indicated as the proprietor), whether it constitutes confidential information or not, and that the user has no right, title or interest in any such material.',
  },
  {
    id: '6.7',
    term:
      'Service Provider authorises you only to view, copy, temporarily download to a local drive and to print the content of this Website, or any part thereof, provided that such content is used for personal purposes and for information purposes only, and such content is used for non-commercial purposes.',
  },
  {
    id: '6.8',
    term:
      'Any modifications, enhancements, adaptations, translations, or derivative works of the Website or any proprietary material (collectively, "Derivative Works") made by you or any third party, whether with or without the consent or knowledge of the Service Provider, shall be the exclusive property of the Service Provider. You hereby assign any and all rights, title, and interest in and to any Derivative Works to the Service Provider and agree to execute any documents necessary to effectuate such assignment.',
  },
];
