import React from 'react';
import {useHistory} from 'react-router';
import {Stack, styled} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBoxRounded';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

interface AcceptTermsFieldProps {
  errorMessage: string | undefined;
  acceptedTerms: boolean;
  onAcceptTerms: () => void;
}

export function GoogleAcceptTermsField({
  errorMessage,
  acceptedTerms,
  onAcceptTerms,
}: AcceptTermsFieldProps) {
  const {push} = useHistory();

  return (
    <Stack spacing={1} mb={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckBoxContainer onClick={onAcceptTerms} disabled={acceptedTerms}>
          <CheckBoxIcon
            color="primary"
            style={{visibility: acceptedTerms ? 'visible' : 'hidden'}}
            fontSize="large"
          />
        </CheckBoxContainer>
        <Stack direction="row" spacing={0.5}>
          <Typography color="text.secondary">Accept</Typography>
          <Typography
            onClick={() => {
              push('/terms');
            }}
            sx={{cursor: 'pointer'}}
            color="primary"
          >
            Terms & Conditions
          </Typography>
        </Stack>
      </Stack>
      {errorMessage != null && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
}

const CheckBoxContainer = styled(ButtonBase)(() => ({
  display: 'grid',
  placeContent: 'center',
  backgroundColor: '#EDEFF2',
  color: '#191C1F',
  padding: '2px',
  borderRadius: '8px',
}));
