import {Terms} from '../components/TermsComponent';

export const aiTrainingDerivativeWorks: Terms = [
  {
    id: '17.1',
    term: 'Use of Data for AI Training',
    terms: [
      {
        id: '17.1.1',
        term:
          'You acknowledge and agree that the Service Provider may use data, including but not limited to, content submitted by you, for the purpose of training, improving, and developing artificial intelligence (AI) models and related technologies.',
      },
      {
        id: '17.1.2',
        term:
          'Any data used for AI training will be processed in accordance with our Data Privacy Policy and relevant data protection regulations.',
      },
    ],
  },
  {
    id: '17.2',
    term: 'Ownership of AI Derivative Works',
    terms: [
      {
        id: '17.2.1',
        term:
          'Any modifications, enhancements, adaptations, translations, or derivative works resulting from the use of your data for AI training (collectively, "AI Derivative Works") shall be the exclusive property of the Service Provider.',
      },
      {
        id: '17.2.2',
        term:
          'You hereby assign any and all rights, title, and interest in and to any AI Derivative Works to the Service Provider and agree to execute any documents necessary to effectuate such assignment.',
      },
      {
        id: '17.2.3',
        term:
          'The AI Derivative Works shall be treated in accordance with the terms set out in Clause 6.8 of this agreement regarding the ownership of derivative works.',
      },
    ],
  },
];
