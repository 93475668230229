import React from 'react';

export interface Term {
  id: string;
  term: React.ReactNode;
  terms?: Terms;
}

export type Terms = Term[];

interface TermsOrderedListProps {
  title: string;
  subTitle?: string;
  terms: Terms;
}

export function TermsComponent({
  title,
  subTitle,
  terms,
}: TermsOrderedListProps) {
  return (
    <li>
      <strong>
        {title}
        {subTitle && (
          <>
            <br />
            {subTitle}
          </>
        )}
      </strong>
      <ol>
        {terms.map((term) => (
          <TermComponent key={term.id} term={term} />
        ))}
      </ol>
    </li>
  );
}

function TermComponent({term}: {term: Term}) {
  return (
    <li>
      {term.term}
      {term.terms != null && term.terms.length > 0 && (
        <ol>
          {term.terms.map((subTerm) => (
            <TermComponent key={subTerm.id} term={subTerm} />
          ))}
        </ol>
      )}
    </li>
  );
}
