/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourAddMarkers_tour = {
    readonly id: string;
    readonly title: string;
    readonly " $refType": "TourAddMarkers_tour";
};
export type TourAddMarkers_tour$data = TourAddMarkers_tour;
export type TourAddMarkers_tour$key = {
    readonly " $data"?: TourAddMarkers_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourAddMarkers_tour">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TourAddMarkers_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
(node as any).hash = 'f5cdc6bd5f66c8078404b94bbde0ec47';
export default node;
