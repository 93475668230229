/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReelStatus = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type TourReelCardSubscriptionVariables = {
    reelId: string;
};
export type TourReelCardSubscriptionResponse = {
    readonly reelProgressUpdated: {
        readonly id: string;
        readonly status: ReelStatus;
        readonly progress: number;
        readonly thumbnailURL: string | null;
    };
};
export type TourReelCardSubscription = {
    readonly response: TourReelCardSubscriptionResponse;
    readonly variables: TourReelCardSubscriptionVariables;
};



/*
subscription TourReelCardSubscription(
  $reelId: ID!
) {
  reelProgressUpdated(reelId: $reelId) {
    id
    status
    progress
    thumbnailURL
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reelId",
        "variableName": "reelId"
      }
    ],
    "concreteType": "ReelProgress",
    "kind": "LinkedField",
    "name": "reelProgressUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourReelCardSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourReelCardSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d6831a875ac5f9c55eb06b01ae69466",
    "id": null,
    "metadata": {},
    "name": "TourReelCardSubscription",
    "operationKind": "subscription",
    "text": "subscription TourReelCardSubscription(\n  $reelId: ID!\n) {\n  reelProgressUpdated(reelId: $reelId) {\n    id\n    status\n    progress\n    thumbnailURL\n  }\n}\n"
  }
};
})();
(node as any).hash = '41d07194938f1fa3d1c39f53c014bf39';
export default node;
