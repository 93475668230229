import {styled} from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

export const TourCardClickable = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  width: '100%',
  padding: '10px',
  gap: '10px',
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('md')]: {gap: '12px', padding: '12px'},
}));
