/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ToursAdd_user = {
    readonly company: {
        readonly license: {
            readonly monthlyActiveTours: number | null;
            readonly monthlyUploadedTours: number | null;
            readonly uploadLimit: number | null;
            readonly quantity: number;
        } | null;
    } | null;
    readonly " $refType": "ToursAdd_user";
};
export type ToursAdd_user$data = ToursAdd_user;
export type ToursAdd_user$key = {
    readonly " $data"?: ToursAdd_user$data;
    readonly " $fragmentRefs": FragmentRefs<"ToursAdd_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToursAdd_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "License",
          "kind": "LinkedField",
          "name": "license",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyActiveTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyUploadedTours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uploadLimit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '494359e31fa125b2500bb0ed19e7c6f9';
export default node;
