import React from 'react';

import {
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion/dist/framer-motion';
import {PanInfo} from 'framer-motion';
import gsap from 'gsap';
import {Theme, useMediaQuery} from '@mui/material';
import {JoyStickSvg} from '../../../assets/JoyStickSvg';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import cursorSwipJson from '../../../assets/json/cursor-swipe.json';
import handSwipJson from '../../../assets/json/hand-swipe.json';
import {TOUR_DRAWER_HEIGHT, TOUR_DRAWER_WIDTH} from './TourDrawer';
import {styled} from '@mui/material/styles';
import {useSearchParam} from 'react-use';
import {addShouldNotForwardProps} from '../../../utils/addShouldNotForwardProps';
import Typography from '@mui/material/Typography';
import useWindowDimensions from '../hooks/useWindowDimensions';

/** The lower the value, the quicker the velocity */
const VELOCITY_RATIO = 80;

const TourFramerComponent = ({
  fastTravellingStatus,
  maxIndex,
  drawerIsOpen,
  drawerRef,
  setCurrentIndex,
}: {
  fastTravellingStatus: 'idle' | 'forward' | 'backward';
  drawerIsOpen: boolean;
  drawerRef: React.RefObject<HTMLDivElement>;
  maxIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const disabledControls = useSearchParam('disabledControls');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'));
  const y = useMotionValue(0);
  const [
    displayHelperAnimation,
    setDisplayHelperAnimation,
  ] = React.useState<boolean>(true);
  const [mouseDown, setMouseDown] = React.useState(false);
  const [{height}] = useWindowDimensions();

  const handleMove = React.useCallback(
    (distance: number, type: 'scroll' | 'drag') => {
      const max = maxIndex - 2;
      const roundedVelocity = Math.abs(Math.round(distance) / VELOCITY_RATIO);
      let removeHelperAnimation = false;

      setCurrentIndex((prev) => {
        // Move down
        if (distance > 0) {
          const newVal = prev - roundedVelocity;
          if (newVal >= 0) {
            return newVal;
          }
          return 0;
        }
        // Move up
        const newVal = prev + roundedVelocity;
        if (newVal < max) {
          if (newVal >= 5 && type === 'drag') {
            removeHelperAnimation = true;
          }
          return newVal;
        }
        return max;
      });

      if (removeHelperAnimation) {
        setDisplayHelperAnimation(false);
      }
    },
    [maxIndex, setCurrentIndex]
  );

  const handleDrag = React.useCallback(
    (_event, info: PanInfo) => {
      handleMove(info.offset.y, 'drag');
    },
    [handleMove]
  );

  const createEasingOutEvents = React.useCallback(
    (info: PanInfo) => {
      const maxCounter = 100; // maximum number of events
      gsap.to(info.offset, {
        y: 0,
        duration: maxCounter * 0.01, // adjust the duration for different frequency of events
        ease: 'power3.out', // easing function
        onUpdate: () => {
          handleMove(info.offset.y, 'drag');
        },
      });
    },
    [handleMove]
  );

  const handleDragEnd = React.useCallback(
    (_event, info: PanInfo) => {
      // create a series of move events which ease out from info.offset.y
      createEasingOutEvents(info);
    },
    [createEasingOutEvents]
  );

  const color = useTransform(y, [-10, 0, 10], ['#5171EA', '#fff', '#5171EA']);

  const handleScroll = React.useCallback(
    (ev: WheelEvent | Event) => {
      let deltaY = 0;

      if (ev instanceof WheelEvent) {
        deltaY = ev.deltaY;
      } else if (ev instanceof Event) {
        const target = ev.target as HTMLElement;
        deltaY = target.scrollTop;
      }
      if (deltaY !== 0) {
        handleMove(deltaY * -1.5, 'scroll');
      }
    },
    [handleMove]
  );
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.onwheel = handleScroll;
    }
  }, [handleScroll]);

  const onMouseDown = React.useCallback(() => {
    setMouseDown(true);
  }, []);
  const onMouseUp = React.useCallback(() => {
    setMouseDown(false);
  }, []);

  return (
    <Div
      $drawerWidth={
        splitDisableControls?.includes('markers')
          ? 0
          : parseInt(TOUR_DRAWER_WIDTH)
      }
      $drawerIsOpen={drawerIsOpen}
      $mouseDown={mouseDown}
      $drawerHeight={
        splitDisableControls?.includes('markers')
          ? 0
          : drawerRef.current?.clientHeight
      }
      drag="y"
      dragConstraints={{top: 0, bottom: 0}}
      ref={divRef}
      style={{y}}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <motion.div
        style={
          isMobile
            ? {
                width: '3.5rem',
                height: '3.5rem',
                position: 'absolute',
                top: height <= 550 && drawerIsOpen ? '50%' : '70%',
                transition: 'top 300ms cubic-bezier(0.4, 0, 0.6, 1)',
              }
            : {
                width: '5rem',
                height: '5rem',
                position: 'relative',
                overflow: 'visible',
              }
        }
      >
        <JoyStickSvg color={color} />
        {displayHelperAnimation && (
          <DragMe>
            <Typography variant={'caption'}>drag me</Typography>
          </DragMe>
        )}
        {(displayHelperAnimation || fastTravellingStatus !== 'idle') && (
          <>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '10rem',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Lottie
                animationData={isMobile ? handSwipJson : cursorSwipJson}
                play
                loop
              />
            </div>
          </>
        )}
      </motion.div>
    </Div>
  );
};

const DragMe = styled('div')(({theme}) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: '120%',
  // right: 0,
  transform: 'translateY(-50%)',
  // left: 0,
  top: '50%',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  background: 'rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(4px)',
  color: '#fff',
  borderRadius: '8px',
  padding: theme.spacing(0.5, 1),
}));

const Div = styled(motion.div, {
  shouldForwardProp: addShouldNotForwardProps(
    '$drawerIsOpen',
    '$drawerHeight',
    '$drawerWidth',
    '$mouseDown'
  ),
})<{
  $drawerIsOpen: boolean;
  $drawerHeight: number | null | undefined;
  $drawerWidth: number;
  $mouseDown: boolean;
}>(({theme, $drawerIsOpen, $drawerHeight, $drawerWidth, $mouseDown}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  zIndex: 2,
  cursor: $mouseDown ? 'grabbing' : 'grab',
  right: 0,
  bottom: $drawerIsOpen ? $drawerHeight ?? parseInt(TOUR_DRAWER_HEIGHT) : 0,
  justifyContent: 'center',
  alignItems: 'flex-end',
  transition: theme.transitions.create(['bottom'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.sharp,
  }),
  [theme.breakpoints.up('md')]: {
    bottom: 0,
    right: $drawerIsOpen ? $drawerWidth : 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 3rem',
    transition: theme.transitions.create(['right'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));

export const TourFramer = React.memo(TourFramerComponent);
