import {Term} from '../../terms/components/TermsComponent';

export const useOfData: Term[] = [
  {
    id: '3.1',
    term: 'We use the collected data for various purposes:',
    terms: [
      {id: '3.1.1', term: 'To provide and maintain our Service'},
      {id: '3.1.2', term: 'To notify you about changes to our Service'},
      {
        id: '3.1.3',
        term:
          'To allow you to participate in interactive features of our Service when you choose to do so',
      },
      {id: '3.1.4', term: 'To provide customer support'},
      {
        id: '3.1.5',
        term:
          'To gather analysis or valuable information so that we can improve our Service',
      },
      {id: '3.1.6', term: 'To monitor the usage of our Service'},
      {id: '3.1.7', term: 'To detect, prevent, and address technical issues'},
      {
        id: '3.1.8',
        term:
          'To provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information',
      },
    ],
  },
];
