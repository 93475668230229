import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {ActiveMemberStats_invite$key} from '../../../__generated__/ActiveMemberStats_invite.graphql';
import {Stack} from '@mui/material';
import {TruvuMenu} from '../../../components/TruvuMenu';
import React from 'react';
import Typography from '@mui/material/Typography';
import {ActiveMemberTourCard} from './ActiveMemberTourCard';

const fragmentSpec = graphql`
  fragment ActiveMemberStats_invite on TeamInvite {
    id
    user {
      user {
        id
        monthlyActiveTours
        monthlyUploadedTours

        topFiveTours {
          ...ActiveMemberTourCard_tour
          id
          views
          deactivatedViews
        }
      }
    }
  }
`;

interface ActiveMemberStatsProps {
  fragmentRef: ActiveMemberStats_invite$key;
}

export function ActiveMemberStats({fragmentRef}: ActiveMemberStatsProps) {
  const {user} = useFragment(fragmentSpec, fragmentRef);

  return (
    <Stack spacing={1} minHeight={0}>
      <Stack px={2}>
        <Stack spacing={2} p={2} bgcolor={'#eee'} borderRadius={'16px'}>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Typography color="text.secondary">Monthly Active Tours</Typography>
            <Typography color="primary">
              {user?.user?.monthlyActiveTours}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Typography color="text.secondary">
              Monthly Uploaded Tours
            </Typography>
            <Typography color="primary">
              {user?.user?.monthlyUploadedTours}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Typography color="text.secondary">Total view</Typography>
            <Typography color="primary">
              {user?.user?.topFiveTours?.reduce(
                (acc, tour) => acc + (tour?.views ?? 0),
                0
              )}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Typography color="text.secondary">Deleted tour views</Typography>
            <Typography color="primary">
              {user?.user?.topFiveTours?.reduce(
                (acc, tour) => acc + (tour?.deactivatedViews ?? 0),
                0
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {user?.user?.topFiveTours != null && user.user.topFiveTours.length > 0 && (
        <>
          <TruvuMenu
            title="Top Performing Tours"
            sx={{px: 2}}
            variant="h5"
            subMenu
            sticky
          />
          <Stack px={2} spacing={1} overflow={'auto'}>
            {user?.user?.topFiveTours?.map(
              (tour, i) =>
                tour != null && <ActiveMemberTourCard key={i} tourRef={tour} />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
