/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TourCardActionsDialogMutationVariables = {
    tourId: string;
};
export type TourCardActionsDialogMutationResponse = {
    readonly tourDelete: {
        readonly clientMutationId: string | null;
    };
};
export type TourCardActionsDialogMutation = {
    readonly response: TourCardActionsDialogMutationResponse;
    readonly variables: TourCardActionsDialogMutationVariables;
};



/*
mutation TourCardActionsDialogMutation(
  $tourId: ID!
) {
  tourDelete(input: {tourId: $tourId}) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tourId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "tourId",
            "variableName": "tourId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "TourDeletePayload",
    "kind": "LinkedField",
    "name": "tourDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourCardActionsDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourCardActionsDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bba8d5acbcd49e7ea2ccb904c3c556bb",
    "id": null,
    "metadata": {},
    "name": "TourCardActionsDialogMutation",
    "operationKind": "mutation",
    "text": "mutation TourCardActionsDialogMutation(\n  $tourId: ID!\n) {\n  tourDelete(input: {tourId: $tourId}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '769ccc964c0de2aa7de81c60d086ac6f';
export default node;
