import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {requestSubscription, useRelayEnvironment} from 'relay-hooks';
import {TourAIGenerateMarkersSubscriptionContextAIProgressSubscription} from '../__generated__/TourAIGenerateMarkersSubscriptionContextAIProgressSubscription.graphql';
import {EditMarker} from '../modules/tourPlayer/tourPlayerEdit/components/TourEditMarker';
import {TruvuMessageDialog} from '../components/dialog/TruvuMessageDialog';
import {TruvuButton} from '../components/button/TruvuButton';

interface EditMarkerWithAction extends EditMarker {
  action: 'ADD' | 'REMOVE';
}

interface TourAIGenerateMarkersSubscriptionContextProps {
  generationProgress: number;
  generatedMarkers: EditMarkerWithAction[];
  activeTourId: string | undefined;
  onSubscribeToAIGeneration: (
    tourId: string,
    refetchCallback: () => void
  ) => 'subscribed' | 'alreadySubscribed';
}

const subscriptionSpec = graphql`
  subscription TourAIGenerateMarkersSubscriptionContextAIProgressSubscription(
    $tourId: ID!
  ) {
    tourGenerateAIMarkersUpdated(tourId: $tourId) {
      tourId
      marker {
        id
        isFastTravel
        frameIndex
        name
      }
      progressPercentage
      action
    }
  }
`;
const TourAIGenerateMarkersSubscriptionContext = React.createContext<TourAIGenerateMarkersSubscriptionContextProps | null>(
  null
);

export const useTourAIGenerateMarkersSubscriptionContext = () => {
  const context = React.useContext(TourAIGenerateMarkersSubscriptionContext);
  if (!context) {
    throw new Error(
      'useTourAIGenerateMarkersSubscriptionContext must be used within an TourAIGenerateMarkersSubscriptionContextProvider'
    );
  }
  return context;
};

export function TourAIGenerateMarkersSubscriptionContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const environment = useRelayEnvironment();

  const [generationProgress, setGenerationProgress] = useState(0);
  const [generatedMarkers, setGeneratedMarkers] = useState<
    EditMarkerWithAction[]
  >([]);
  const [activeTourId, setActiveTourId] = useState<string | undefined>();
  const [
    tourAlreadyRunningWarningMessage,
    setTourAlreadyRunningWarningMessage,
  ] = useState(false);

  const onSubscribeToAIGeneration = useCallback(
    (tourId: string, refetchCallback: () => void) => {
      if (activeTourId != null) {
        setTourAlreadyRunningWarningMessage(true);
        return 'alreadySubscribed';
      }
      setTourAlreadyRunningWarningMessage(false);
      setActiveTourId(tourId);
      localStorage.setItem('activeTourId', tourId);
      localStorage.setItem('activeTourTimestamp', new Date().toISOString());

      requestSubscription<TourAIGenerateMarkersSubscriptionContextAIProgressSubscription>(
        environment,
        {
          subscription: subscriptionSpec,
          variables: {tourId},
          onNext: (response) => {
            const action = response?.tourGenerateAIMarkersUpdated?.action;
            const marker = response?.tourGenerateAIMarkersUpdated?.marker;
            const progressPercentage =
              response?.tourGenerateAIMarkersUpdated?.progressPercentage;
            if (progressPercentage != null && progressPercentage > 0) {
              setGenerationProgress(progressPercentage);
              if (progressPercentage === 100) {
                setTimeout(() => {
                  setActiveTourId(undefined);
                  setGenerationProgress(0);
                  setGeneratedMarkers([]);
                  refetchCallback();
                  localStorage.removeItem('activeTourId');
                  localStorage.removeItem('activeTourTimestamp');
                }, 1100);
              }
            }

            if (action === 'REMOVE' && marker != null) {
              setGeneratedMarkers((prevState) => [
                ...prevState.filter(({id}) => id !== marker.id),
                {...marker, action: 'REMOVE'},
              ]);
            }
            if (action === 'ADD' && marker != null) {
              setGeneratedMarkers((prevState) => [
                ...prevState,
                {...marker, action: 'ADD'},
              ]);
            }
          },
          onError: () => {
            setActiveTourId(undefined);
            setGenerationProgress(0);
            setGeneratedMarkers([]);
            refetchCallback();
            localStorage.removeItem('activeTourId');
            localStorage.removeItem('activeTourTimestamp');
          },
          onCompleted: () => {
            setActiveTourId(undefined);
            setGenerationProgress(0);
            setGeneratedMarkers([]);
            refetchCallback();
            localStorage.removeItem('activeTourId');
            localStorage.removeItem('activeTourTimestamp');
          },
        }
      );
      return 'subscribed';
    },
    [activeTourId, environment]
  );

  useEffect(() => {
    const localStorageActiveTourId = localStorage.getItem('activeTourId');
    const localStorageActiveTourTimestamp = localStorage.getItem(
      'activeTourTimestamp'
    );

    if (
      localStorageActiveTourId != null &&
      localStorageActiveTourTimestamp != null
    ) {
      const localTimestampDiff =
        new Date().getTime() -
        new Date(localStorageActiveTourTimestamp).getTime();
      /** Clear storage if time diff is more than 1 minute */
      if (localTimestampDiff > 60000) {
        localStorage.removeItem('activeTourId');
        localStorage.removeItem('activeTourTimestamp');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubscribeToAIGeneration(localStorageActiveTourId, () => {});
      }
    }
    /** Only run on initial render */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TourAIGenerateMarkersSubscriptionContext.Provider
      value={useMemo(
        () => ({
          generatedMarkers,
          generationProgress,
          activeTourId,
          onSubscribeToAIGeneration,
        }),
        [
          activeTourId,
          generatedMarkers,
          generationProgress,
          onSubscribeToAIGeneration,
        ]
      )}
    >
      <TruvuMessageDialog
        title={'AI Markers already generating for another tour.'}
        variant="error"
        message={null}
        actions={
          <TruvuButton
            variant="secondary"
            onClick={() => {
              setTourAlreadyRunningWarningMessage(false);
            }}
          >
            Close
          </TruvuButton>
        }
        isOpen={tourAlreadyRunningWarningMessage}
        onOpen={() => {
          setTourAlreadyRunningWarningMessage(true);
        }}
        onClose={() => {
          setTourAlreadyRunningWarningMessage(false);
        }}
      />
      {children}
    </TourAIGenerateMarkersSubscriptionContext.Provider>
  );
}
