import React, {useCallback, useMemo, useState} from 'react';
import {usePagination} from 'relay-hooks';
import {MemberCard} from './MemberCard';
import {
  TeamPageQuery,
  TeamPageQueryResponse,
} from '../../../__generated__/TeamPageQuery.graphql';
import {Team_members$key} from '../../../__generated__/Team_members.graphql';
import graphql from 'babel-plugin-relay/macro';
import Grid2 from '@mui/material/Unstable_Grid2';
import {TruvuMenu} from '../../../components/TruvuMenu';
import {Stack, Theme, useMediaQuery} from '@mui/material';
import {Add} from '@mui/icons-material';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {AddMember} from './AddMember';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';

interface TeamLayoutProps {
  data: TeamPageQueryResponse;
}

const fragmentSpec = graphql`
  fragment Team_members on Query @refetchable(queryName: "TeamRefetchQuery") {
    me {
      user {
        company {
          id
          name
        }
      }
    }
    invites(first: $count, after: $cursor) @connection(key: "Team_invites") {
      edges {
        cursor
        node {
          id
          email
          accepted
          ...MemberCard_invite
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export function Team({data}: TeamLayoutProps) {
  const {
    data: {invites, me},
    refetch,
  } = usePagination<TeamPageQuery, Team_members$key>(fragmentSpec, data);
  const [requestInviteCreation, setRequestInviteCreation] = useState(false);
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('lg'));

  const handleRefetchTeam = useCallback(async () => {
    refetch({});
  }, [refetch]);

  const handleInviteMember = (companyId: string | null) => {
    if (!companyId) {
      return;
    }
    setRequestInviteCreation(true);
  };

  const pendingMembers = useMemo(
    () => invites.edges?.filter((edge) => edge?.node?.accepted !== true) ?? [],
    [invites.edges]
  );
  const activeMembers = useMemo(
    () => invites.edges?.filter((edge) => edge?.node?.accepted === true) ?? [],
    [invites.edges]
  );

  return (
    <>
      <TruvuMenu
        title={me?.user?.company?.name ?? 'My Team'}
        action={
          <TruvuIconButton
            label={!isMobile ? 'Invite Member' : undefined}
            onClick={() => handleInviteMember(me?.user?.company?.id ?? null)}
          >
            <Add />
          </TruvuIconButton>
        }
        sticky
      />
      <TruvuMessageDialog
        isOpen={requestInviteCreation}
        onClose={() => {
          setRequestInviteCreation(false);
        }}
        onOpen={() => {
          setRequestInviteCreation(true);
        }}
        title="Invite member"
        variant="info"
        message={
          <AddMember
            onSubmitCallback={() => {
              setRequestInviteCreation(false);
              refetch({});
            }}
            onCancelCallback={() => {
              setRequestInviteCreation(false);
            }}
            companyId={me?.user?.company?.id ?? null}
          />
        }
      />
      {(invites?.edges == null || invites.edges.length === 0) && (
        <TruvuMenu title="No Members Invited" variant="h2" subMenu sticky />
      )}
      {pendingMembers.length > 0 && (
        <Stack my={1} spacing={0.1}>
          <TruvuMenu title="Pending Invites" variant="h2" subMenu sticky />
          <Grid2 spacing="10px" container>
            {pendingMembers?.map(
              (edge) =>
                edge?.node?.id != null && (
                  <Grid2 xs={12} sm={6} md={4} key={edge.node.id}>
                    <MemberCard
                      key={edge.node.id}
                      inviteRef={edge.node}
                      refetchTeam={handleRefetchTeam}
                    />
                  </Grid2>
                )
            )}
          </Grid2>
        </Stack>
      )}
      {activeMembers.length > 0 && (
        <Stack my={1} spacing={0.1}>
          <TruvuMenu title="My Team" variant="h2" subMenu sticky />
          <Grid2 spacing="10px" container>
            {activeMembers?.map(
              (edge) =>
                edge?.node?.id != null && (
                  <Grid2 xs={12} sm={6} md={4} key={edge.node.id}>
                    <MemberCard
                      key={edge.node.id}
                      inviteRef={edge.node}
                      refetchTeam={handleRefetchTeam}
                    />
                  </Grid2>
                )
            )}
          </Grid2>
        </Stack>
      )}
    </>
  );
}
