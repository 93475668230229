import * as React from 'react';

// export interface MarkerType {
//   id: string;
//   title: string;
//   description: string;
// }

// let myMarker = {
//   title: "Main Bedroom",
//   description: "On suite bedroom and bathroom",
//   infoPoints: [
//     { name: "pool pump", description: "swimming pool pump switcher" },
//   ],
// };

export interface MarkerContextType {
  markers: string[];
  setMarkers: (vals: string[]) => void;
}

export const MarkerContext = React.createContext<MarkerContextType>({
  markers: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMarkers: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const MarkerProvider = ({children}: Props) => {
  const [markers, setMarkers] = React.useState<string[]>([
    'Bedroom',
    'Kitchen',
    'Bathroom',
    'Lounge',
    'Dining Room',
    'Living Room',
    'Pool',
    'Ensuite',
    'Scullery',
    'Patio',
    'Balcony',
  ]);
  const value = React.useMemo(() => ({markers, setMarkers}), [
    markers,
    setMarkers,
  ]);
  return (
    <MarkerContext.Provider value={value}>{children}</MarkerContext.Provider>
  );
};
