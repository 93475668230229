import {Terms} from '../components/TermsComponent';

export const privacyAndCookiePolicy: Terms = [
  {
    id: '8.1',
    term:
      'This clause 8 provides details about our Privacy and Cookie Policy, which Policy forms part of these Terms. Service Provider takes your privacy seriously and is committed to protecting your personal information. We use the personal information that we collect from you in accordance with this Privacy and Cookie Policy.',
  },
  {
    id: '8.2',
    term:
      "Personal information when used in this Policy means information that can identify you as an individual or is capable of identifying you. By personal information we don't mean general, statistical, aggregated or anonymised information.",
  },
  {
    id: '8.3',
    term:
      'Your use of our services signifies your consent to us collecting and using your personal information as specified below:',
  },
  {
    id: '8.4',
    term: 'How we collect information about you',
    terms: [
      {
        id: '8.4.1',
        term:
          'We collect personal information about you in various ways including when you register on our website, when you interact with our services by creating virtual tours, when you participate in any of our marketing campaigns, promotions, or surveys. Additionally, if you contact our support team for assistance, we may collect information needed to verify your identity and to assist you efficiently.',
      },
    ],
  },
  {
    id: '8.5',
    term: 'How we use your information',
    terms: [
      {
        id: '8.5.1',
        term:
          'We use your personal information to provide you with our services effectively, such as by creating your user account, facilitating the generation of virtual tours, and ensuring that these services are tailored to your needs. We may also use your information to communicate with you about service updates, conduct internal research and development, and enhance the security and operational functioning of our services. Furthermore, we use your information to comply with legal obligations and enforce our terms and conditions.',
      },
    ],
  },
  {
    id: '8.6',
    term: 'How long do we keep your information for?',
    terms: [
      {
        id: '8.6.1',
        term:
          'The time periods for which we keep your information may vary according to the use or purpose attached to the information. Unless there is a specific legal requirement requiring us to keep your information, however, we will not keep it for longer than necessary for the purpose for which the information was collected or for which it is to be processed.',
      },
    ],
  },
  {
    id: '8.7',
    term: 'Disclosing your information to third parties',
    terms: [
      {
        id: '8.7.1',
        term:
          'We may share your personal information with third-party service providers who perform services on our behalf, such as data hosting and processing, software support, and marketing assistance. We ensure that these third parties are bound by confidentiality agreements and do not use your personal information for any unauthorized purposes. Additionally, we may disclose your information if required by law or in the good faith belief that such action is necessary to comply with legal proceedings or government requests.',
      },
    ],
  },
  {
    id: '8.8',
    term: 'When and where do we use cookies?',
    terms: [
      {
        id: '8.8.1',
        term:
          'We use cookies to enhance your experience on our website. Cookies are used during the registration process and while you interact with our services. These cookies help us remember your preferences and settings, manage session information, and enhance site navigation and security.',
      },
    ],
  },
  {
    id: '8.9',
    term: 'How can you refuse or opt out of cookies?',
    terms: [
      {
        id: '8.9.1',
        term:
          "Most browsers are set by default to accept cookies automatically, but you can alter the settings of your browser to prevent automatic acceptance and to prompt you every time a cookie is sent to you or to allow you to choose not to receive cookies at all. For detailed instructions, please refer to your browser's help menu.",
      },
    ],
  },
  {
    id: '8.10',
    term: 'How can you manage your privacy preferences?',
    terms: [
      {
        id: '8.10.1',
        term:
          'If you would like us to stop processing your information for marketing purposes or if you have any other privacy preferences, you can contact us at the address set out in clause 11 below. We will accommodate your request in accordance with applicable legal requirements.',
      },
    ],
  },
];
