import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import React, {ReactNode} from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {TruvuMessageDialog} from '../dialog/TruvuMessageDialog';
import {useTruvuDialog} from '../dialog/TruvuDialog';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

export type TruvuAlertSeverity = 'error' | 'warning' | 'info' | 'success';

export interface TruvuAlertPopup {
  label: string;
  message: ReactNode;
  actions: ReactNode;
}

export interface TruvuAlertProps {
  severity?: TruvuAlertSeverity;
  label: string;
  message: string;
  popup?: TruvuAlertPopup;
}
export function TruvuAlert({
  label,
  message,
  severity = 'error',
  popup,
}: TruvuAlertProps) {
  const truvuDialog = useTruvuDialog();

  if (popup != null) {
    return (
      <>
        <TruvuAlertCardClickable
          $severity={severity}
          onClick={truvuDialog.onOpen}
        >
          <Stack alignItems="flex-start">
            <Typography textAlign="left" variant="subtitle2" fontWeight={400}>
              {label}
            </Typography>
            <Typography textAlign="left" variant="body1" fontSize={16}>
              {message}
            </Typography>
          </Stack>
          <InfoOutlined fontSize="small" />
        </TruvuAlertCardClickable>
        <TruvuMessageDialog
          title={popup.label}
          message={popup.message}
          // message="Please click the link in the email we sent you to activate your account."
          variant={severity}
          actions={popup.actions}
          {...truvuDialog}
        />
      </>
    );
  }

  return (
    <TruvuAlertCard $severity={severity}>
      <Stack alignItems="flex-start">
        <Typography textAlign="left" variant="subtitle2" fontWeight={400}>
          {label}
        </Typography>
        <Typography textAlign="left" variant="body1" fontSize={16}>
          {message}
        </Typography>
      </Stack>
      <InfoOutlined fontSize="small" />
    </TruvuAlertCard>
  );
}

const TruvuAlertCard = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('$severity'),
})<{$severity: TruvuAlertSeverity}>(({theme, $severity}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette[$severity].light,
  color: theme.palette[$severity].main,
}));

const TruvuAlertCardClickable = styled(ButtonBase, {
  shouldForwardProp: addShouldNotForwardProps('$severity'),
})<{$severity: TruvuAlertSeverity}>(({theme, $severity}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  backgroundColor:
    $severity === 'error'
      ? theme.palette.error.light
      : theme.palette.secondary.main,
  color:
    $severity === 'error'
      ? theme.palette.error.main
      : theme.palette.primary.main,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius,
}));
