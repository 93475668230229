import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {Box, CircularProgress} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import {TourEditMarkerDeleteDialog} from './TourEditMarkerDeleteDialog';
import {TourEditMarkerEditDialog} from './TourEditMarkerEditDialog';
import ButtonBase from '@mui/material/ButtonBase';
import gsap from 'gsap';

export interface EditMarker {
  id: string | undefined;
  name: string;
  isFastTravel: boolean;
  frameIndex: number;
}
export interface EditMarkerWithType extends EditMarker {
  type: 'added' | 'removed' | 'existed';
}
interface TourEditMarkerProps {
  refetch: () => void;
  marker: EditMarkerWithType;
  disabled: boolean;
  isActive: boolean;
  isLoading: boolean;
  onClick: (frameIndex: number) => void;
}

export type MarkerState = 'view' | 'edit' | 'delete' | 'editing' | 'deleting';

export function TourEditMarker({
  marker,
  refetch,
  disabled,
  isActive,
  isLoading,
  onClick,
}: TourEditMarkerProps) {
  const [markerState, setMarkerState] = useState<
    'view' | 'edit' | 'delete' | 'editing' | 'deleting'
  >('view');
  const markerRef = useRef<HTMLDivElement>(null);

  const onMarkerClick = useCallback(() => {
    if (!disabled && !isActive && marker.frameIndex != null) {
      onClick(marker.frameIndex);
    }
  }, [disabled, isActive, marker.frameIndex, onClick]);

  const onEditClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMarkerState('edit');
    },
    []
  );

  const onDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMarkerState('delete');
    },
    []
  );

  let markerNameColor = 'text.secondary';

  if (disabled) {
    markerNameColor = 'text.disabled';
  }

  if (isActive) {
    markerNameColor = 'primary';
  }

  useLayoutEffect(() => {
    if (marker.type === 'removed') {
      gsap.to(markerRef.current, {
        ease: 'power3.out', // easing function
        duration: 1,
        opacity: 0,
        x: -100,
        display: 'none',
      });
    } else if (marker.type === 'added') {
      // Set the initial state of the element
      gsap.set(markerRef.current, {
        opacity: 0,
        x: -100,
        display: 'flex', // Ensure the element is displayed
      });
      // Animate the element in from the left
      gsap.to(markerRef.current, {
        ease: 'power3.out', // easing function
        duration: 1,
        opacity: 1,
        x: 0,
      });
    } else {
      gsap.set(markerRef.current, {
        opacity: 1,
        x: 0,
        display: 'flex', // Ensure the element is displayed
      });
    }
  }, [marker.type]);

  return (
    <>
      <Marker ref={markerRef}>
        <MarkerButton
          onClick={onMarkerClick}
          sx={{cursor: isActive || disabled ? 'default' : 'pointer', flex: 1}}
        >
          <Typography color={markerNameColor} flex={1} noWrap>
            {marker.name}
          </Typography>
        </MarkerButton>
        <MarkerEditButton
          onClick={onEditClick}
          style={{backgroundColor: 'rgba(83,116,252,0.1)'}}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Edit fontSize="small" color={'primary'} />
          )}
        </MarkerEditButton>
        <MarkerEditButton
          onClick={onDeleteClick}
          style={{backgroundColor: '#FC53531A'}}
          disabled={isLoading}
        >
          <Delete fontSize="small" color={'error'} />
        </MarkerEditButton>
      </Marker>
      <TourEditMarkerEditDialog
        marker={marker}
        refetch={refetch}
        markerState={markerState}
        setMarkerState={setMarkerState}
      />
      <TourEditMarkerDeleteDialog
        markerId={marker.id ?? ''}
        markerState={markerState}
        setMarkerState={setMarkerState}
        refetch={refetch}
      />
    </>
  );
}

const Marker = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'stretch',
  flex: 1,
  gap: theme.spacing(0.4),
}));

export const MarkerButton = styled(Box)(({theme}) => ({
  display: 'flex',
  textAlign: 'start',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(0.5),
  backgroundColor: '#EDEFF2',
  padding: '7px',
  borderRadius: '5px',
}));

const MarkerEditButton = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  textAlign: 'start',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: '7px',
  borderRadius: '5px',
}));
