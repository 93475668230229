import React, {useCallback} from 'react';
import graphql from 'babel-plugin-relay/macro';
import * as yup from 'yup';
import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {useLocation} from 'react-router-dom';
import {useMutation} from 'react-relay-mutation';
import {PasswordResetFormMutation} from '../../../__generated__/PasswordResetFormMutation.graphql';
import {useHistory} from 'react-router';

interface PasswordResetValues {
  newPassword: string;
  confirmPassword: string;
}

const passwordResetValidationSchema: yup.SchemaOf<PasswordResetValues> = yup.object(
  {
    newPassword: yup
      .string()
      .required('New Password is a required field.')
      .min(8, 'Password must be at least 8 characters long.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[0-9]/, 'Password must contain at least one number.'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is a required field.'),
  }
);

export function PasswordResetForm() {
  const {notify} = useSnackbarContext();
  const {push} = useHistory();

  const location = useLocation();

  // Function to extract query parameters
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const token = query.get('token') || '';

  const [passwordReset] = useMutation<PasswordResetFormMutation>(
    graphql`
      mutation PasswordResetFormMutation($input: UserPasswordResetInput!) {
        userPasswordReset(input: $input) {
          user {
            username
          }
        }
      }
    `,
    {
      onCompleted: (response) => {
        if (response && response.userPasswordReset) {
          push('/login');
        }
      },
    }
  );

  const handleSubmit = useCallback<FormOnSubmit<PasswordResetValues>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);

      try {
        const {newPassword} = values;
        await passwordReset({
          variables: {
            input: {
              token,
              newPassword,
            },
          },
        });
      } catch (e) {
        notify({
          message: 'Error resetting password',
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [notify, passwordReset, token]
  );

  return (
    <Form<PasswordResetValues>
      initialValues={{newPassword: '', confirmPassword: ''}}
      onSubmit={handleSubmit}
      validationSchema={passwordResetValidationSchema}
    >
      <TruvuTextField
        name="newPassword"
        placeholder="New Password"
        isPassword
        formikField
      />
      <TruvuTextField
        name="confirmPassword"
        placeholder="Confirm New Password"
        isPassword
        formikField
      />
      <TruvuButton sx={{mt: 1}} formikSubmit>
        Reset Password
      </TruvuButton>
    </Form>
  );
}
