import React from 'react';
import {Typography, TypographyProps} from '@mui/material';
import {useHistory} from 'react-router';

interface LinkProps extends TypographyProps {
  to: string;
}

export function Link({to, children, ...props}: LinkProps) {
  const history = useHistory();

  const onNavigate = () => {
    history.push(to);
  };

  return (
    <Typography
      variant="body2"
      color="primary"
      fontWeight={500}
      onClick={onNavigate}
      {...props}
      sx={{cursor: 'pointer', ...props.sx}}
    >
      {children}
    </Typography>
  );
}
