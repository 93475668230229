import React, {useCallback, useState} from 'react';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {Typography} from '@mui/material';
import {useMutation} from 'react-relay-mutation';
import {PendingMemberCardActionsDialogMutation} from '../../../__generated__/PendingMemberCardActionsDialogMutation.graphql';
import graphql from 'babel-plugin-relay/macro';

interface PendingMemberCardActionsDialogProps {
  onRevokeCallback: () => void;
  onCloseDialog: () => void;
  title: string;
  id: string;
}

export function PendingMemberCardActions({
  onRevokeCallback,
  onCloseDialog,
  // title,
  id,
}: PendingMemberCardActionsDialogProps) {
  const {notify} = useSnackbarContext();
  const [requestRevokeConfirmation, setRequestRevokeConfirmation] = useState(
    false
  );
  const [deleting, setDeleting] = useState(false);
  const [inviteRevoke] = useMutation<PendingMemberCardActionsDialogMutation>(
    graphql`
      mutation PendingMemberCardActionsDialogMutation($inviteId: ID!) {
        revokeMemberInvite(input: {inviteId: $inviteId}) {
          success
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Team removed', variant: 'success'});
      },
    }
  );

  const handleRevoke = useCallback(async () => {
    setDeleting(true);
    try {
      const response = await inviteRevoke({
        variables: {
          inviteId: id,
        },
      });

      if (response) {
        notify({message: 'Revoked invite', variant: 'success'});
        setRequestRevokeConfirmation(false);
        onCloseDialog();
        onRevokeCallback();
      }
    } catch (e) {
      notify({message: 'Could not revoke invite', variant: 'error'});
    } finally {
      setDeleting(false);
    }
  }, [id, notify, onCloseDialog, onRevokeCallback, inviteRevoke]);

  if (requestRevokeConfirmation) {
    return (
      <>
        <Typography variant="h6" fontWeight={500}>
          Are you sure you want to revoke this invite?
        </Typography>
        <TruvuButton
          variant="secondary"
          onClick={() => setRequestRevokeConfirmation(false)}
          disabled={deleting}
        >
          Cancel
        </TruvuButton>
        <TruvuButton onClick={handleRevoke} loading={deleting} variant="danger">
          {deleting ? 'Deleting...' : 'Revoke'}
        </TruvuButton>
      </>
    );
  }

  return (
    <>
      <TruvuButton
        onClick={() => setRequestRevokeConfirmation(true)}
        variant="danger"
        style={{marginTop: '30px'}}
      >
        Revoke Invite
      </TruvuButton>
    </>
  );
}
