import {Term} from '../../terms/components/TermsComponent';

export const legalBasis: Term[] = [
  {
    id: '5.1',
    term:
      'If you are from the European Economic Area (EEA), our legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it. We may process your Personal Data because:',
    terms: [
      {id: '5.1.1', term: 'We need to perform a contract with you'},
      {id: '5.1.2', term: 'You have given us permission to do so'},
      {
        id: '5.1.3',
        term:
          'The processing is in our legitimate interests and it is not overridden by your rights',
      },
      {id: '5.1.4', term: 'To comply with the law'},
    ],
  },
];
