import React, {useCallback, useState} from 'react';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useMutation} from 'react-relay-mutation';
import {AddMemberMutation} from '../../../__generated__/AddMemberMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import * as yup from 'yup';

interface CreateInviteValues {
  email: string;
}
const createInviteValidationSchema: yup.SchemaOf<CreateInviteValues> = yup.object(
  {
    email: yup
      .string()
      .email('Not a valid email.')
      .required('Email is a required field.'),
  }
);

export function AddMember({
  onSubmitCallback,
  onCancelCallback,
  companyId,
}: {
  onSubmitCallback: () => void;
  onCancelCallback: () => void;
  companyId: string | null;
}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useSnackbarContext();

  const [inviteMember] = useMutation<AddMemberMutation>(
    graphql`
      mutation AddMemberMutation($input: InviteMemberInput!) {
        inviteMember(input: $input) {
          teamInvite {
            id
            expiresAt
            accepted
            user {
              user {
                id
                monthlyUploadedTours
                monthlyActiveTours
                topFiveTours {
                  id
                  title
                  thumbnailURL
                  views
                  deactivatedViews
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: (response) => {
        if (response && response.inviteMember) {
          notify({
            message: 'Email has been invited successfully',
            variant: 'success',
          });
        }
      },
    }
  );
  const handleSubmit = useCallback<FormOnSubmit<CreateInviteValues>>(
    async (values, {setSubmitting}) => {
      if (companyId == null) {
        notify({
          message: 'Unknown company',
          variant: 'error',
        });
        return;
      }
      setSubmitting(true);
      setLoading(true);

      try {
        const {email} = values;
        await inviteMember({
          variables: {
            input: {
              email,
              companyId,
            },
          },
        });
      } catch (e) {
        notify({
          message: 'Error creating user invite',
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setSubmitting(false);
        onSubmitCallback();
      }
    },
    [inviteMember, companyId, notify, onSubmitCallback]
  );

  return (
    <Form<CreateInviteValues>
      initialValues={{email: ''}}
      onSubmit={handleSubmit}
      validationSchema={createInviteValidationSchema}
    >
      <TruvuTextField name="email" placeholder="Invitee Email" formikField />
      <TruvuButton
        sx={{mt: 2}}
        variant="secondary"
        onClick={onCancelCallback}
        disabled={loading}
      >
        Cancel
      </TruvuButton>
      <TruvuButton
        formikSubmit
        loading={loading}
        variant="primary"
        disabled={companyId == null}
      >
        {companyId == null
          ? 'Unknown Company'
          : loading
          ? 'Inviting...'
          : 'Invite'}
      </TruvuButton>
    </Form>
  );
}
