import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
export function UsedLastChip() {
  return (
    <Box
      bgcolor={'#DDEEE1'}
      color={'#3BB86D'}
      py={'6px'}
      px={'16px'}
      gap={'4px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      alignSelf={'center'}
      textAlign={'center'}
      borderRadius={'100px'}
    >
      <SubdirectoryArrowLeftIcon
        fontSize={'inherit'}
        sx={{rotate: '90deg', fontSize: '18px'}}
      />
      <Typography fontWeight={500}>Used Last</Typography>
    </Box>
  );
}
// width: Hug (126px)px;
// height: Hug (35px)px;
// padding: var(--spacing2) var(--spacing4) var(--spacing2) var(--spacing4);
// gap: var(--spacing1);
// border-radius: var(--radiusfull);
// opacity: 0px;
// #DDEEE1
