import React from 'react';
import SwipeableView from 'react-swipeable-views';
import {UploadTipCard, UploadTipCardProps} from './UploadTipCard';
import KeepCamSteady from '../../../assets/json/Keep_cam_steady.json';
import PlanRoute from '../../../assets/json/Plan_route.json';
import WidestLens from '../../../assets/json/Widest_lens.json';
import {UploadTipsStepper} from './UploadTipsStepper';

const tips: UploadTipCardProps[] = [
  {
    lottieJson: KeepCamSteady,
    alt: '',
    title: 'Keep camera steady',
    description:
      'Ensure a steady camera throughout your tour video to maintain a professional look and avoid disorienting viewers; consistency in orientation enhances the viewing experience.',
  },
  {
    lottieJson: PlanRoute,
    alt: '',
    title: 'Plan route',
    description:
      'Strategically planning your route is essential for an efficient and engaging tour, allowing you to highlight key points of interest and manage time effectively while ensuring a logical and seamless flow for your audience.',
  },
  {
    lottieJson: WidestLens,
    alt: '',
    title: 'Widest lens',
    description:
      'Employing the widest lens in your arsenal allows you to embrace more of the scene in a single shot, making it ideal for capturing architectural spaces, or shots where you want to include as much context and detail as possible.',
  },
];

export function UploadTips() {
  const [activeTip, setActiveStep] = React.useState(0);

  const handleTipChange = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <SwipeableView
        axis="x"
        index={activeTip}
        onChangeIndex={handleTipChange}
        enableMouseEvents
        style={{borderRadius: '20px', marginBottom: '10px'}}
        slideStyle={{
          display: 'flex',
          alignItems: 'stretch',
          overflow: 'hidden',
          borderRadius: '20px',
        }}
      >
        {tips.map((tip, index) => (
          <UploadTipCard key={index} {...tip} />
        ))}
      </SwipeableView>
      <UploadTipsStepper
        activeTip={activeTip}
        total={tips.length}
        onNext={handleNext}
        onPrev={handleBack}
      />
    </>
  );
}
