import * as React from 'react';

export interface MarkerInterface {
  id: string;
  name: string;
  description?: string;
  scrollPosition: number;
  current: boolean;
  frameURL: string;
  isFastTravel: boolean;
}

export interface TourInterface {
  id?: string;
  status?: string;
  thumbnailURL?: string;
  videoURL?: string;
  frameURLs?: string[];
  title?: string;
  description?: string;
  markers?: MarkerInterface[];
}

export interface TourContextInterface {
  tour?: TourInterface;
  setTour: (tour: TourInterface) => void;
}

export const TourContext = React.createContext<TourContextInterface>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTour: () => {},
});

interface Props {
  children: React.ReactNode;
}

export function TourProvider({children}: Props) {
  const [tour, setTour] = React.useState<TourInterface>();

  return (
    <TourContext.Provider
      value={React.useMemo(() => ({tour, setTour}), [tour, setTour])}
    >
      {children}
    </TourContext.Provider>
  );
}
