import React from 'react';
import RelayRenderer from '../RelayRenderer';
import {Team} from '../modules/team/components';
import graphql from 'babel-plugin-relay/macro';
import {TruvuTeamContainer} from '../components/containers/TruvuTeamContainer';
import {TeamPageQuery} from '../__generated__/TeamPageQuery.graphql';

const query = graphql`
  query TeamPageQuery($count: Int = 100, $cursor: Cursor) {
    ...Team_members
    me {
      user {
        ...TruvuTeamContainer_user
      }
    }
  }
`;

export function TeamPage() {
  return (
    <RelayRenderer<TeamPageQuery> query={query}>
      {(data) => {
        return (
          <TruvuTeamContainer userRef={data.me?.user}>
            <Team data={data} />
          </TruvuTeamContainer>
        );
      }}
    </RelayRenderer>
  );
}
