import React from 'react';
import {UploadTips} from '../modules/upload/component';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import {useHistory} from 'react-router';
import {UploadTourVideo} from '../modules/upload/component/UploadTourVideo';
import Typography from '@mui/material/Typography';
import {Stack} from '@mui/material';

export function UploadPage() {
  const {push} = useHistory();
  const goHome = () => {
    push('/');
  };
  const goToTerms = () => {
    push('/terms');
  };
  return (
    <TruvuContainer onGoBack={goHome} sx={{maxWidth: '400px'}} skinny>
      <UploadTips />
      <Stack direction="row" justifyContent="center" spacing={0.5}>
        <Typography color="text.secondary">Link to</Typography>
        <Typography
          onClick={goToTerms}
          sx={{cursor: 'pointer'}}
          color="primary"
        >
          {`T's and C's`}
        </Typography>
      </Stack>
      <UploadTourVideo />
    </TruvuContainer>
  );
}
