import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {TourEditDetails} from './TourEditDetails';
import {Link} from '@mui/icons-material';
import {TourCardActions} from './TourCardActions';
import {CircularProgress, Skeleton, Stack, useMediaQuery} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef, useState} from 'react';
import {TourCardClickable} from './styled/TourCardClickable';
import {TourCardThumbnail} from './styled/TourCardThumbnail';
import {TourCardContent} from './styled/TourCardContent';
import {TourCard_tour} from '../../../__generated__/TourCard_tour.graphql';
import {Theme} from '@mui/material/styles';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
import TourReels from './TourReels';
interface TourCardProcessedProps {
  tour: TourCard_tour;
  isLoading: boolean;
  refetchTours: () => void;
  refetchTour: () => void;
}

export function TourCardProcessed({
  tour,
  isLoading,
  refetchTours,
  refetchTour,
}: TourCardProcessedProps) {
  const {id, thumbnailURL, description, title, views} = tour;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {
    openDialog: openActionsDialog,
    onCloseDialog: onCloseActionsDialog,
    onOpenDialog: onOpenActionsDialog,
  } = useTruvuDialog();
  const {
    openDialog: openEditDialog,
    onCloseDialog: onCloseEditDialog,
    onOpenDialog: onOpenEditDialog,
  } = useTruvuDialog();
  const {
    openDialog: openReelsDialog,
    onCloseDialog: onCloseReelsDialog,
    onOpenDialog: onOpenReelsDialog,
  } = useTruvuDialog();
  const shareUrl = `${window.location.protocol}//${window.location.host}/playerext?tourId=${id}`;
  const [copyLinkStatus, setCopyLinkStatus] = useState<
    'idle' | 'copying' | 'copied' | 'error'
  >('idle');
  const copyTimeoutRef = useRef<NodeJS.Timeout>();

  const onCopyLink = async () => {
    setCopyLinkStatus('copying');
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopyLinkStatus('copied');
    } catch (error) {
      setCopyLinkStatus('error');
    } finally {
      copyTimeoutRef.current = setTimeout(() => {
        setCopyLinkStatus('idle');
      }, 6000);
    }
  };

  const onEditDetails = () => {
    onOpenEditDialog();
    onCloseActionsDialog();
  };

  const onReelsDetails = () => {
    onOpenReelsDialog();
    onCloseActionsDialog();
  };

  const onEditComplete = () => {
    onCloseEditDialog();
    refetchTours();
  };

  useEffect(() => {
    return () => {
      if (copyTimeoutRef.current != null) {
        clearTimeout(copyTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <TruvuDialog open={openEditDialog} onClose={onCloseEditDialog}>
        <TourEditDetails
          tour={tour}
          onCancel={onCloseEditDialog}
          onCompleted={onEditComplete}
        />
      </TruvuDialog>
      <TruvuDialog open={openReelsDialog} onClose={onCloseReelsDialog}>
        <TourReels tour={tour} refetchTour={refetchTour} />
      </TruvuDialog>
      <TruvuDialog
        open={openActionsDialog}
        onClose={onCloseActionsDialog}
        title={title}
        headingAction={
          <Stack direction={'row'} spacing={0.5}>
            <TruvuIconButton
              variant={copyLinkStatus === 'error' ? 'danger' : 'secondary'}
              size="large"
              onClick={onCopyLink}
              loading={copyLinkStatus === 'copying'}
            >
              {copyLinkStatus === 'idle' || copyLinkStatus === 'copying' ? (
                <Link fontSize={'inherit'} sx={{fontSize: '35px'}} />
              ) : (
                <Typography
                  fontSize="0.7rem"
                  variant="caption"
                  fontWeight={600}
                >
                  {copyLinkStatus === 'error' ? 'Failed' : 'Copied'}
                </Typography>
              )}
            </TruvuIconButton>
          </Stack>
        }
        contentScrollable
      >
        <TourCardActions
          onCloseDialog={onCloseActionsDialog}
          onEditDetails={onEditDetails}
          onReelsDetails={onReelsDetails}
          onDeleteCallback={refetchTours}
          title={title}
          id={id}
        />
      </TruvuDialog>
      <TourCardClickable onClick={onOpenActionsDialog}>
        {thumbnailURL != null && thumbnailURL !== '' ? (
          <TourCardThumbnail>
            <img
              src={thumbnailURL}
              alt="Unable to load thumbnail"
              draggable={false}
            />
          </TourCardThumbnail>
        ) : isLoading ? (
          <CircularProgress size={isDesktop ? 90 : 60} />
        ) : (
          <Skeleton
            variant="rectangular"
            width={isDesktop ? 120 : 60}
            height={isDesktop ? 90 : 60}
          />
        )}
        <TourCardContent>
          {title ? (
            <Typography className="truncate title" variant="body1">
              {title}
            </Typography>
          ) : (
            <Typography variant="body1" color={'text.secondary'}>
              Insert Title
            </Typography>
          )}
          <Typography
            className="truncate"
            component="p"
            fontSize="0.9rem"
            color="text.secondary"
            lineHeight="1.2em"
          >
            {description}
          </Typography>
          <Stack flex={1} direction="row" spacing={0.5} alignItems="flex-end">
            <Typography color="primary" lineHeight="1.2em">
              {views === 1 ? `${views} view` : `${views} views`}
            </Typography>
          </Stack>
        </TourCardContent>
      </TourCardClickable>
    </>
  );
}
