import React from 'react';

import {TruvuTermContainer} from '../components/containers/TruvuTermContainer';
import {Terms} from '../modules/terms/components/Terms';
import './termsPage.css';
import {useHistory} from 'react-router';

export function TermsPage() {
  const {goBack} = useHistory();
  return (
    <TruvuTermContainer onGoBack={goBack}>
      <Terms />
    </TruvuTermContainer>
  );
}
