/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberCard_invite = {
    readonly id: string;
    readonly email: string;
    readonly createdAt: unknown;
    readonly expiresAt: unknown;
    readonly accepted: boolean;
    readonly user: {
        readonly isAdmin: boolean | null;
        readonly user: {
            readonly id: string;
            readonly username: string;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ActiveMemberStats_invite">;
    readonly " $refType": "MemberCard_invite";
};
export type MemberCard_invite$data = MemberCard_invite;
export type MemberCard_invite$key = {
    readonly " $data"?: MemberCard_invite$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberCard_invite">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./MemberCardRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "MemberCard_invite",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamInviteUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActiveMemberStats_invite"
    }
  ],
  "type": "TeamInvite",
  "abstractKey": null
};
})();
(node as any).hash = 'c56bc9c63b8a8c9297e67e1502e6e9c1';
export default node;
