import {Terms} from '../components/TermsComponent';

export const contentOfTheWebsite: Terms = [
  {
    id: '2.1',
    term:
      'Service Provider reserves the right to make improvements, to change or to discontinue, without notice, any aspect or feature of the Website and any information or content on the Website.',
  },
  {
    id: '2.2',
    term:
      'Service Provider reserves the right to change and amend the products, prices and rates quoted on this Website from time to time without notice.',
  },
  {
    id: '2.3',
    term:
      'Service Provider may use the services of third parties to provide information on the Website. Service Provider has no control over this information and makes no representations or warranties of any nature as to its accuracy, appropriateness, or correctness. The user agrees that such information is provided "as is" and that Service Provider and its online partners shall not be liable for any losses or damages that may arise from the user\'s reliance on it, howsoever these may arise.',
  },
  {
    id: '2.4',
    term:
      'Service Provider makes no representations or warranties, whether express or implied, as to the accuracy, completeness or reliability of any information, data and/or content on the Website, including without limitation:',
    terms: [
      {
        id: '2.4.1',
        term:
          'Service Provider does not warrant that the Website or information or downloads shall be error-free or that they shall meet any particular criteria of performance or quality. Service Provider expressly disclaims all implied warranties, including without limitation, warranties of merchantability, fit-ness for a particular purpose, non-infringement, compatibility, security and accuracy;',
      },
      {
        id: '2.4.2',
        term:
          "whilst Service Provider has taken reasonable measures to ensure the integrity of the Website and its contents, no warranty, whether express or implied, is given that any files, downloads or applications available via this Website are free of viruses, or any other data or code which has the ability to corrupt, damage or affect the operation of the user's system; and",
      },
      {
        id: '2.4.3',
        term:
          'Service Provider disclaims any responsibility for the verification of any claims. Information published on this Website may be done so in the format in which Service Provider receives it and statements from external parties are accepted as fact.',
      },
    ],
  },
];
