/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FileGetPreSignedUrlInput = {
    text: string;
};
export type UploadTourVideoGetPreSignedUrlMutationVariables = {
    input: FileGetPreSignedUrlInput;
};
export type UploadTourVideoGetPreSignedUrlMutationResponse = {
    readonly fileGetPreSignedUrl: {
        readonly uploadURL: string;
        readonly key: string;
        readonly headers: ReadonlyArray<{
            readonly key: string;
            readonly value: string;
        }>;
        readonly error: string;
        readonly message: string;
    };
};
export type UploadTourVideoGetPreSignedUrlMutation = {
    readonly response: UploadTourVideoGetPreSignedUrlMutationResponse;
    readonly variables: UploadTourVideoGetPreSignedUrlMutationVariables;
};



/*
mutation UploadTourVideoGetPreSignedUrlMutation(
  $input: FileGetPreSignedUrlInput!
) {
  fileGetPreSignedUrl(input: $input) {
    uploadURL
    key
    headers {
      key
      value
    }
    error
    message
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FileGetPreSignedUrlPayload",
    "kind": "LinkedField",
    "name": "fileGetPreSignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadURL",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Header",
        "kind": "LinkedField",
        "name": "headers",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadTourVideoGetPreSignedUrlMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadTourVideoGetPreSignedUrlMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "411ce72c3cf12a626beb8ee1b05e4100",
    "id": null,
    "metadata": {},
    "name": "UploadTourVideoGetPreSignedUrlMutation",
    "operationKind": "mutation",
    "text": "mutation UploadTourVideoGetPreSignedUrlMutation(\n  $input: FileGetPreSignedUrlInput!\n) {\n  fileGetPreSignedUrl(input: $input) {\n    uploadURL\n    key\n    headers {\n      key\n      value\n    }\n    error\n    message\n  }\n}\n"
  }
};
})();
(node as any).hash = '43dcd341a2d6eec03449233928f5920f';
export default node;
